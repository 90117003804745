import React from "react";
import { CenteredPageDiv } from "../../styles/global.styles";
import { porcentagem } from "../../utils/conversions";
import {
  BoxDiv,
  HrDashedLine,
  MeasurementsPBlack,
  MeasurementsPLink,
  MeasurementsPN,
  MeasurementsPP,
  MeasurementsProgressBar,
  MeasurementsPX,
  DivRectangleMeasurements,
  TextH2,
} from "./styles";

interface PurchaseComponentProps {
  total: number;
  restante: number;
}

const PurchaseComponent: React.FC<PurchaseComponentProps> = ({
  total,
  restante,
}) => {
  return (
    <CenteredPageDiv>
      <BoxDiv>
        <TextH2>Minha Compra</TextH2>
        <br />
        <DivRectangleMeasurements>
          <MeasurementsPX>{`Você possui ${total} medições por mês`}</MeasurementsPX>
          <HrDashedLine />
          <MeasurementsPN>{`Consumo`}</MeasurementsPN>
          <MeasurementsPP>{`Você tem ${
            total - restante < 0 ? 0 : total - restante
          } medições restantes`}</MeasurementsPP>
          <MeasurementsProgressBar porcentagem={porcentagem(restante, total)} />
        </DivRectangleMeasurements>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <MeasurementsPBlack>
          {`Para alterar a quantidade de medições contratadas por mês, entre em
          contato através do email: `}{" "}
          <MeasurementsPLink href="mailto:atendimento@prosperasaude.com.br">
            atendimento@prosperasaude.com.br
          </MeasurementsPLink>
        </MeasurementsPBlack>
      </BoxDiv>
    </CenteredPageDiv>
  );
};

export default PurchaseComponent;
