import React, { FormEvent, useEffect, useState } from "react";
import { CenteredPageDiv } from "../../styles/global.styles";
import {
  CustomerButton,
  CustomerContentButton,
  CustomerForm,
  CustomerFormControl,
  CustomerInputLabel,
  CustomerOutlinedInput,
  TextH2,
} from "./styles";
import qs from "qs";
import { useParams } from "react-router-dom";
import { getUser, updateUser } from "../../Services/Admin";
import { ICustomer, ICustomerServicesUser } from "../../Services/Interfaces";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ModalB, ModalP } from "../Modal/Customer/styles";
import { DateFormat, cnpjMask } from "../../utils/conversions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomerEditComponent: React.FC = () => {
  const { customerID } = useParams();

  const [customer, setCustomer] = useState<ICustomer>({} as ICustomer);

  const [profileFullName, setProfileFullName] = useState<string>("");
  const [profileCpf, setProfileCpf] = useState<string>("");
  const [healthPlaceId, setHealthPlaceId] = useState<string>("");
  const [customerServiceLimitPerMonth, setCustomerServiceLimitPerMonth] =
    useState<string>("");

  const [customerServices, setCustomerServices] = useState<
    ICustomerServicesUser[]
  >([]);

  const [valueAba, setValueAba] = useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValueAba(newValue);
    setHealthPlaceId(customerServices[newValue].service.healthPlace.id);
    setCustomerServiceLimitPerMonth(customerServices[newValue].limitPerMonth);
  };

  useEffect(() => {
    if (customerID) {
      getUser(customerID)
        .then((response) => {
          if (response) {
            setCustomer(response);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [customerID]);

  useEffect(() => {
    if (customer) {
      setProfileFullName(customer.profile?.fullName);
      setProfileCpf(customer.profile?.cpf);
      setCustomerServices(customer.customer?.customerServices);
      setHealthPlaceId(
        customer.customer?.customerServices[0].service.healthPlace.id
      );
      setCustomerServiceLimitPerMonth(
        customer.customer?.customerServices[0].limitPerMonth
      );
    }
  }, [customer]);

  const handleUpdate = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    updateUser(
      customer?.id,
      qs.stringify({
        "update[profileFullName]": profileFullName,
        "update[profileCpf]": profileCpf,
        "update[healthPlaceId]": healthPlaceId,
        "update[customerServiceLimitPerMonth]": customerServiceLimitPerMonth,
      })
    ).catch((error) => console.error(error));
  };

  return (
    <CenteredPageDiv>
      <TextH2>Editar Cliente</TextH2>
      <br />
      <CustomerForm onSubmit={handleUpdate}>
        <CustomerFormControl variant="outlined">
          <CustomerInputLabel htmlFor="profileFullName">
            Nome
          </CustomerInputLabel>
          <CustomerOutlinedInput
            value={profileFullName}
            onChange={(e) => setProfileFullName(e.target.value)}
            id="profileFullName"
            type="text"
            label="Nome"
          />
        </CustomerFormControl>
        <br />
        <br />
        <CustomerFormControl variant="outlined">
          <CustomerInputLabel htmlFor="profileCpf">CPF</CustomerInputLabel>
          <CustomerOutlinedInput
            value={profileCpf}
            onChange={(e) => setProfileCpf(e.target.value)}
            id="profileCpf"
            type="text"
            label="CPF"
          />
        </CustomerFormControl>
        <br />
        <br />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={valueAba} onChange={handleChange}>
              {customer.customer?.customerServices?.map(
                (customerService, index) => {
                  return (
                    <Tab
                      key={index + 1201}
                      label={customerService.service.healthPlace.name}
                    />
                  );
                }
              )}
            </Tabs>
          </Box>
          {customer &&
            customer?.customer?.customerServices?.map(
              (customerService, index) => (
                <TabPanel key={index + 5452} value={valueAba} index={index}>
                  <ModalP>
                    <ModalB>Empresa:&nbsp;</ModalB>
                    {customerService.service.healthPlace.corporateName || "-"}
                  </ModalP>
                  <ModalP>
                    <ModalB>CNPJ:&nbsp;</ModalB>
                    {cnpjMask(customerService.service.healthPlace.cnpj) || "-"}
                  </ModalP>
                  <ModalP>
                    <ModalB>Data de Inclusão:&nbsp;</ModalB>
                    {DateFormat(customer.createdAt)}
                  </ModalP>
                  <ModalP>
                    <ModalB>Data de última modificação:&nbsp;</ModalB>
                    {DateFormat(customer.updatedAt)}
                  </ModalP>
                  <ModalP>
                    <ModalB>Medições Histórico:&nbsp;</ModalB>
                    {customerService.usedCurrentMonth || "-"}
                  </ModalP>
                </TabPanel>
              )
            )}
          <CustomerFormControl variant="outlined">
            <CustomerInputLabel htmlFor="services">
              Medições Contratadas
            </CustomerInputLabel>
            <CustomerOutlinedInput
              value={customerServiceLimitPerMonth}
              onChange={(e) => setCustomerServiceLimitPerMonth(e.target.value)}
              id="services"
              type="number"
              label="Medições Contratadas"
            />
          </CustomerFormControl>
        </Box>
        <CustomerContentButton>
          <CustomerButton title="Salvar">Salvar</CustomerButton>
        </CustomerContentButton>
      </CustomerForm>
    </CenteredPageDiv>
  );
};

export default CustomerEditComponent;
