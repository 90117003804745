import { styled } from "@mui/material/styles";

type HealthPlaceProfileProps = {
  secondBackgroundColor: string;
  firstBackgroundColor: string;
};
type HealthPlaceProfileTextProps = {
  primary: string;
};

export const SidebarHeaderContainer = styled("div")<HealthPlaceProfileProps>`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to bottom,
    ${(props) => props.firstBackgroundColor} 0%,
    ${(props) => props.firstBackgroundColor} 80px,
    #fafafa 80px,
    #fafafa 100%
  );
  @media (max-width: 800px) {
    display: flex;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
      to bottom,
      ${(props) => props.firstBackgroundColor} 0%,
      ${(props) => props.firstBackgroundColor} 80px,
      #fafafa 80px,
      #fafafa 100%
    );
  }
`;

export const Sidebar = styled("div")<HealthPlaceProfileProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  min-width: 230px;
  min-height: 100%;

  background: linear-gradient(
    to bottom,
    ${(props) => props.firstBackgroundColor} 0%,
    ${(props) => props.firstBackgroundColor} 80px,
    ${(props) => props.secondBackgroundColor} 80px,
    ${(props) => props.secondBackgroundColor} 100%
  );

  position: relative;

  @media (max-width: 800px) {
    z-index: 2;
    position: absolute;
    left: -400px;
    background: ${(props) => props.secondBackgroundColor};
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);

    &.opened {
      left: 0;
      transition: ease 0.4s;
    }
  }
`;

export const MenuContainerSection = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const MenuContainer = styled("div")`
  padding: 38px 18px;
  @media (max-width: 800px) {
    padding: 0 20px;
  }
`;

export const MenuContainerHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0;
`;

export const HeaderContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const LogoMenu = styled("img")`
  height: 40px;
  margin-top: 20px;
  padding-left: 20px;
  @media (max-width: 800px) {
    margin-top: 0px;
    padding-left: 0px;
  }
  cursor: pointer;
`;

export const Header = styled("div")`
  align-self: end;
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    align-self: auto;
    border: solid;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-color: rgba(255, 255, 255, 0.22);
    border-bottom: 1px solid rgba(219, 219, 219, 0.86);
  }
  height: 80px;
  align-items: center;
  padding: 22px 30px;
  box-shadow: 0px 0px 2px rgba(92, 89, 89, 0.06);
`;

export const SidebarMenu = styled("div")``;

export const AlignCenter = styled("div")`
  display: none;
  @media (max-width: 800px) {
    display: inline-grid;
    text-align: center;
    padding: 20px;
    padding-bottom: 40px;
  }
`;

export const CopyrightContainer = styled("div")`
  padding: 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;

export const ComponentContent = styled("div")`
  /* background-color: white; */
  border-radius: 6px;
  height: 100%;
  @media (max-width: 800px) {
    margin: 0px 0px;
  }
  margin: 28px 20px;
  /* desativado scroll  */
  /* overflow: hidden; */
  padding: 8px;
`;

export const MenuMobileClose = styled("button")`
  display: none;
  cursor: pointer;
  background-color: transparent;
  border: none;

  @media (max-width: 800px) {
    display: flex;
  }
`;

export const MenuMobile = styled("div")`
  display: none;
  @media (max-width: 800px) {
    display: contents;
  }
`;

export const CloseSidebar = styled("div")`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 800px) {
    display: flex;
  }

  &:hover {
    color: #00e693;
  }
`;

export const LinkA = styled("a")<HealthPlaceProfileTextProps>`
  text-decoration: none;
  color: ${(props) => props.primary};
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TextoA = styled("a")<HealthPlaceProfileTextProps>`
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.primary};
  cursor: pointer;

  :hover {
    color: #6257b8;
  }

  @media (max-width: 800px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
  }
`;

export const AvatarContainer = styled("img")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 74px;
    border-radius: 50%;
  }
  cursor: pointer;
`;

export const AvatarTextoSpan = styled("span")`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #717171;
`;

export const MenuButton = styled("button")`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;

  border: none;
  padding: 15px 0px;

  background: #ffbb00;
  /* Themes/$avocado */

  border: 1px solid #ffbb00;
  border-radius: 6px;

  background-color: #ffbb00;
  color: #000000;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  margin: 20px 20px;
  cursor: pointer;

  &:hover {
    background-color: #b68500;
  }
`;

export const HideItemMobile = styled("div")`
  display: contents;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const HideItemDesk = styled("div")`
  display: none;
  @media (max-width: 800px) {
    display: contents;
  }
`;
