import { styled } from "@mui/material/styles";
import GaugeChart from "react-gauge-chart";

export const GraphicBoxLinhaDiv = styled("div")`
  margin: 10px;
  width: 100%;
`;

export const BoxLinhaDiv = styled("div")`
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const TextoP = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #717171;
  cursor: pointer;
`;

export const BoxDivAmareloEsquerdo = styled("div")`
  width: 100%;
  height: 30px;
  background: #ffbb00;
  border-radius: 5px 0px 0px 5px;
`;

export const BoxDivAmareloDireito = styled("div")`
  width: 100%;
  height: 30px;
  background: #ffbb00;
  border-radius: 0px 5px 5px 0px;
`;

export const BoxDivValorCircular = styled("div")`
  border-radius: 50%;
  width: 41px;
  height: 41px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  position: relative;
  top: -20px;
  padding-top: 6px;
`;

export const BoxDivVerde = styled("div")`
  width: 100%;
  height: 30px;
  background: #52b788;
`;

type BoxDivVerdeColorProps = {
  color: string;
};

export const BoxDivVerdeColor = styled("div")<BoxDivVerdeColorProps>`
  width: 100%;
  height: 30px;
  background: ${(props) => props.color};
`;

export const BoxValorCircularIdadeDiv = styled("div")`
  border-radius: 50%;
  padding-top: 30px;
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 10px solid #cccccc;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
`;

export const GaugeChartStyled = styled(GaugeChart)``;

export const GaugeChartStyledDiv = styled("div")`
  width: 90%;
  text-align-last: center;
  margin-left: 5%;
`;
