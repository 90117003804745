import styled from "@emotion/styled";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ModalUl = styled("ul")`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalLi = styled("li")`
  font-weight: 400;
  font-size: 1vw;
  color: #6b6c7e;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;

export const ModalH2 = styled("h2")`
  padding-bottom: 10px;
  color: #0d0d0d;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
`;

export const ModalCitation = styled("p")`
  color: #6b6c7e;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  font-style: italic;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ModalContentA = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const ModalBody = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const ModalFormularioDiv = styled("form")`
  align-items: center;
  padding: 8px;
  width: 500px;

  @media (max-width: 700px) {
    padding: 8px 20px;
    width: auto;
    height: 60vh;
  }
`;

export const ModalContentButton = styled("div")`
  display: flex;
  justify-content: center;
`;

export const ModalButton = styled("button")`
  border: none;
  margin-top: 30px;
  padding: 15px 60px;
  gap: 15px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;

  &:hover {
    background-color: #b68500;
  }
`;
