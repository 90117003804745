import { toast } from "react-toastify";

interface MensagemComponentsProps {
  mensagemErro: string | null;
}

const MensagemTela = (mensagemErro: string) => {
  toast.error(mensagemErro, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

const MensagemComponents: React.FC<MensagemComponentsProps> = ({
  mensagemErro,
}) => {
  const mensagemErroFormatada = mensagemErro
    ? mensagemErro.replace(/%20/g, " ")
    : "";

  return <>{MensagemTela(mensagemErroFormatada)}</>;
};

export default MensagemComponents;
