import { Navigate, useParams } from "react-router-dom";
import DetailedMeasurementsComponent from "../../Components/Aton/Historic/Detailed";
import PageHeaderMenu from "../Auth/Menu";

function PageMeasurementDetails() {
  const { criteriaSrId } = useParams();
  return (
    <>
      {criteriaSrId ? (
        <PageHeaderMenu
          InternalComponent={
            <DetailedMeasurementsComponent criteriaSrId={criteriaSrId} />
          }
        />
      ) : (
        <Navigate to="/home" />
      )}
    </>
  );
}

export default PageMeasurementDetails;
