import React, { FormEvent } from "react";
import {
  customStyles,
  ModalButton,
  ModalBody,
  ModalContentButton,
  ModalFormularioDiv,
  ModalH2,
  ModalLi,
  ModalUl,
  ModalCitation,
} from "./styles";
import Modal from "react-modal";

interface ModalProps {
  setStartMeasure: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const ModalComponentInfo: React.FC<ModalProps> = ({
  setStartMeasure,
  setIsOpen,
  isOpen,
}) => {
  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Dados pre medição"
    >
      <ModalH2>Indicativos para a medição</ModalH2>
      <ModalFormularioDiv
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          setIsOpen(false);
          setStartMeasure();
        }}
      >
        <ModalBody>
          <ModalUl>
            <ModalLi>
              Esteja em uma área bem iluminada. Certifique-se que a luz está
              atuando de forma uniforme em seu rosto. Evite estar contra a luz.
            </ModalLi>
            <ModalLi>
              Retire objetos que estejam ocultando parte do rosto, como óculos
              ou chapéu
            </ModalLi>
            <ModalLi>
              O seu rosto não pode estar muito distante da câmera. Certifique-se
              que o seu rosto esteja dentro dos limites estabelecidos pela
              aplicação.
            </ModalLi>
          </ModalUl>
          <br />
          <ModalCitation>
            “Sinais Vitais Prospera Saúde é uma verificação digital da sua
            condição de saúde que pode ser momentânea, sendo necessário
            verificação adicional médica ou laboratorial.”
          </ModalCitation>
        </ModalBody>
        <ModalContentButton>
          <ModalButton title="Medir" type="submit">
            Entendi
          </ModalButton>
        </ModalContentButton>
      </ModalFormularioDiv>
    </Modal>
  );
};

export default ModalComponentInfo;
