import React from "react";
import {
  customStyles,
  ModalA,
  ModalB,
  ModalButtonDiv,
  ModalContentA,
  ModalContentB,
  ModalContentC,
  ModalH2,
  ModalP,
} from "./styles";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { ICompany } from "../../../Services/Interfaces";
import { CEPMask, cnpjMask } from "../../../utils/conversions";

interface ModalCompanyProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  empresa: ICompany;
}

const ModalComponentCompany: React.FC<ModalCompanyProps> = ({
  setIsOpen,
  modalIsOpen,
  empresa,
}) => {
  Modal.setAppElement("#root");

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Detalhes Gestão Empresa"
    >
      <ModalContentA>
        <ModalH2>Detalhes</ModalH2>
        <ModalA onClick={closeModal}>
          <GrClose size={26} />
        </ModalA>
      </ModalContentA>
      <ModalContentB>
        <ModalP>
          <ModalB>Empresa:&nbsp;</ModalB>
          {empresa.name}
        </ModalP>
        <ModalP>
          <ModalB>CNPJ:&nbsp;</ModalB>
          {cnpjMask(empresa.cnpj)}
        </ModalP>
        <ModalP>
          <ModalB>Inscrição Municipal:&nbsp;</ModalB>
          {empresa.municipalRegistration}
        </ModalP>
        <ModalP>
          <ModalB>Inscrição Estadual:&nbsp;</ModalB>
          {empresa.stateRegistration}
        </ModalP>
        <ModalP>
          <ModalB>CEP:&nbsp;</ModalB>
          {CEPMask(empresa.address.zipCode)}
        </ModalP>
        <ModalP>
          <ModalB>Endereço:&nbsp;</ModalB>
          {empresa.address.street} <b>&nbsp;&nbsp;&nbsp;&nbsp;Número:&nbsp;</b>
          {empresa.address.number}
        </ModalP>
        <ModalP>
          <ModalB>Cidade:&nbsp;</ModalB>
          {empresa.address.city} <b>&nbsp;&nbsp;&nbsp;&nbsp;Estado:&nbsp;</b>
          {empresa.address.uf.name}
        </ModalP>
      </ModalContentB>
      <ModalContentC>
        <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
      </ModalContentC>
    </Modal>
  );
};

export default ModalComponentCompany;
