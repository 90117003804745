import { styled } from "@mui/material/styles";

export const HomeDiv = styled("div")`
  color: #1100ff;
`;

export const DivImageHome = styled("div")`
  display: flex;
  justify-content: center;
`;

export const ImageHome = styled("img")`
  width: 100%;
  max-width: 400px;
  height: auto;
`;

export const ButtonYellow = styled("button")`
  width: 100%;
  max-width: 700px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  border: none;
  padding: 15px 0px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  color: #000000;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #b68500;
  }
`;
