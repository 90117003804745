import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminHealthPlace } from "../../../../Services/Admin";
import { ICompany, ICompanyIDIOkRest } from "../../../../Services/Interfaces";
import PageHeaderMenu from "../../../Auth/Menu";
import PageCompanyAdminEdit from "./Content";

function PageAdminHome() {
  const navigate = useNavigate();
  const { codeID } = useParams();
  const [company, setCompany] = useState<ICompany | null>();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (codeID === "new") {
      setCompany(null);
    }
    if (codeID !== undefined && codeID !== "new") {
      adminHealthPlace(undefined, undefined, undefined, undefined, codeID)
        .then((response) => {
          const { data } = response as ICompanyIDIOkRest;
          setCompany(data.data);
        })
        .catch((error) => {
          navigate("/admin/gestao/empresas", { replace: true });
        });
    }
  }, [codeID, navigate, forceUpdate]);

  return (
    <>
      {company !== undefined && (
        <PageHeaderMenu
          InternalComponent={
            <PageCompanyAdminEdit
              setForceUpdate={setForceUpdate}
              company={company}
            />
          }
        />
      )}
    </>
  );
}

export default PageAdminHome;
