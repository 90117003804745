import CustomerRegistrationComponent from "../../../Components/CustomerRegistration";
import PageHeaderMenu from "../../Auth/Menu";

function PageCustomerRegistration() {
  return (
    <PageHeaderMenu InternalComponent={<CustomerRegistrationComponent />} />
  );
}

export default PageCustomerRegistration;
