import { useEffect, useState } from "react";
import HistoricalMeasurementComponent from "../../Components/Aton/Historic/List";
import { IUserServiceRescueIOkRest } from "../../Services/Interfaces";
import { userServiceRescue } from "../../Services/User";
import PageHeaderMenu from "../Auth/Menu";
import { DateFormat, RoundUp } from "../../utils/conversions";

interface MedicoesListProps {
  data: string;
  value: string;
  idDetalhes: string;
}

function HistoricalMeasurementPage() {
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [measurements, setMeasurements] = useState<MedicoesListProps[]>();

  useEffect(() => {
    userServiceRescue(offset, 5).then((response) => {
      const measurementsArray = new Array<MedicoesListProps>();
      const { data } = response as IUserServiceRescueIOkRest;
      data.data.map((measurement) => {
        const measurementsObj = {} as MedicoesListProps;
        measurementsObj.data = DateFormat(measurement.createdAt);
        measurementsObj.idDetalhes = measurement.id;
        measurement.vitalSignsMeasurements.map((measurements) => {
          if (measurements.type === "HR_BPM") {
            return (measurementsObj.value = RoundUp(
              measurements.value,
              0
            ).toString());
          }
          return null;
        });
        return measurementsArray.push(measurementsObj);
      });
      setMeasurements((prevMeasurements) => {
        if (offset !== 0 && prevMeasurements) {
          return prevMeasurements.concat(measurementsArray);
        } else {
          return measurementsArray;
        }
      });
      setTotal(data.pagination?.total || 0);
    });
  }, [offset]);

  const verMais = () => {
    if (total > offset) {
      setOffset(offset + 5);
    }
  };

  return (
    <>
      {measurements && (
        <PageHeaderMenu
          InternalComponent={
            <HistoricalMeasurementComponent
              measurements={measurements}
              verMais={verMais}
              offset={offset}
            />
          }
        />
      )}
    </>
  );
}

export default HistoricalMeasurementPage;
