import styled from "@emotion/styled";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

export const ModalH2 = styled("h2")`
  color: #0d0d0d;
  font-weight: 800;
  font-size: 1.8vw;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;
export const ModalH5 = styled("h5")`
  color: #0d0d0d;
  font-weight: 800;
  font-size: 0.7vw;
  text-align: justify;

  @media (max-width: 800px) {
    font-size: 3vw;
  }
`;

export const ModalContentA = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
`;

export const ModalContentB = styled("div")`
  width: 50vw;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 40vw;
  @media (max-width: 800px) {
    width: 90vw;
    max-height: 100vw;
  }
`;

export const ModalP = styled("p")`
  font-weight: 400;
  font-size: 1vw;
  display: flex;
  align-items: center;
  color: #6b6c7e;
  text-align: justify;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;
export const ModalContentC = styled("div")`
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const ModalButtonDiv = styled("div")`
  background: #ffbb00;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0d0d0d;
  width: 160px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #b68500;
    cursor: pointer;
  }
`;

export const ModalUl = styled("ul")`
  font-weight: 400;
  font-size: 1vw;
  color: #6b6c7e;
  padding: 15px;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;