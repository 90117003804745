import PolicyComponent from "../../Components/Policy/Counter";
import PageHeaderMenu from "../Auth/Menu";

function PolicyPage() {
  return (
    <PageHeaderMenu InternalComponent={<PolicyComponent />} />
  );
}

export default PolicyPage;
