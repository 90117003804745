import React, { useEffect, useState } from "react";
import GraphicLinha from "../Graphics/Line";
import GraphicLinhaMaior from "../Graphics/MajorLine";
import {
  ContainerBase,
  HrDashedLine,
  MedicoesAlinhaDiv,
  MedicoesCirculoIdadeTextoSpan,
  TextH1,
  TextH2,
  TextMeasurements,
} from "./styles";
import {
  BoxLinhaDiv,
  BoxValorCircularIdadeDiv,
  GraphicBoxLinhaDiv,
  TextoP,
} from "../Graphics/styles";
import { MedicoesStar, MedicoesStarSpan } from "../Measurement/styles";
import { userServiceRescue } from "../../../Services/User";
import {
  ICustomerService,
  IUserLogado,
  IUserServiceRescueIOkRest,
  VitalSignsMeasurementsArray,
} from "../../../Services/Interfaces";
import {
  RoundUp,
  intervalsRange,
  verificaTipoExternalId,
} from "../../../utils/conversions";
import { DownloadIconDiv } from "../../../Pages/Admin/Customers/styles";
import { Margin, usePDF } from "react-to-pdf";

interface MedicoesDetalhadoProps {
  criteriaSrId: string;
  print?: boolean;
  userLogado?: IUserLogado;
}

const DetailedMeasurementsComponent: React.FC<MedicoesDetalhadoProps> = ({
  criteriaSrId,
  print = false,
  userLogado,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [createdAt, setcreatedAt] = useState("");
  const [iDMedicao, setIDMedicao] = useState("");
  const [customerService, setCustomerService] = useState<ICustomerService>();
  const { toPDF, targetRef } = usePDF({
    filename: `Medição-${iDMedicao}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const [vitalSignsMeasurements, setVitalSignsMeasurements] =
    useState<VitalSignsMeasurementsArray>();

  useEffect(() => {
    userServiceRescue(undefined, undefined, criteriaSrId).then((response) => {
      const { data } = response as IUserServiceRescueIOkRest;
      if (data.data.length > 0) {
        if (data.data[0].vitalSignsMeasurementsArray) {
          setVitalSignsMeasurements(data.data[0].vitalSignsMeasurementsArray);
          setcreatedAt(data.data[0].createdAt);
          setIDMedicao(data.data[0].id);
          setCustomerService(data.data[0].customerService);
        }
      }
    });
  }, [criteriaSrId]);

  const locationHref = () => {
    setIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    if (print && iDMedicao && createdAt) {
      setTimeout(() => {
        toPDF();
      }, 5000);
    }
  }, [createdAt, iDMedicao, print, toPDF]);

  const AssembleChart = (
    vitalSignsMeasurements: VitalSignsMeasurementsArray,
    displayGroup: string
  ) => {
    return vitalSignsMeasurements[displayGroup].map((vitalSign) => {
      if (
        RoundUp(vitalSign.value, parseInt(vitalSign.presentation || "0")) === 0
      ) {
        return (
          <TextMeasurements>
            <br />
            <TextoP>{vitalSign.name}</TextoP>
            <br />
            <p>Este índice não pôde ser medido nessa captação.</p>
          </TextMeasurements>
        );
      } else if (intervalsRange(vitalSign.intervalsRange).colors.length === 3) {
        return (
          <GraphicLinha
            key={vitalSign.id}
            title={`${vitalSign.name} >`}
            value={RoundUp(
              vitalSign.value,
              parseInt(vitalSign.presentation || "0")
            )}
            ranges={intervalsRange(vitalSign.intervalsRange).ranges}
            unit={vitalSign.unit || ""}
            codeMeasurement={vitalSign.externalId}
          />
        );
      } else if (intervalsRange(vitalSign.intervalsRange).colors.length === 5) {
        return (
          <GraphicLinhaMaior
            key={vitalSign.id}
            colors={intervalsRange(vitalSign.intervalsRange).colors}
            title={`${vitalSign.name} >`}
            value={[
              RoundUp(vitalSign.value, parseInt(vitalSign.presentation || "0")),
            ]}
            ranges={intervalsRange(vitalSign.intervalsRange).ranges}
            unit={vitalSign.unit || ""}
            codeMeasurement={vitalSign.externalId}
          />
        );
      } else if (vitalSign.graphicFormat === "INTEIRO") {
        return (
          <MedicoesAlinhaDiv>
            <GraphicBoxLinhaDiv>
              <TextoP
                onClick={locationHref}
              >{`${vitalSign.name} > ${vitalSign.unit}`}</TextoP>
              <BoxLinhaDiv>
                <BoxValorCircularIdadeDiv>
                  <MedicoesCirculoIdadeTextoSpan>
                    {RoundUp(
                      vitalSign.value,
                      parseInt(vitalSign.presentation || "0")
                    )}
                  </MedicoesCirculoIdadeTextoSpan>
                </BoxValorCircularIdadeDiv>
              </BoxLinhaDiv>
            </GraphicBoxLinhaDiv>
          </MedicoesAlinhaDiv>
        );
      } else {
        return null; // <p>Gráfico sem Medição {vitalSign.externalId}</p>
      }
    });
  };

  return (
    <div ref={targetRef}>
      {vitalSignsMeasurements && (
        <>
          {print && userLogado !== undefined && (
            <>
              <TextH1>{userLogado?.profile.fullName}</TextH1>
              <br />
              <p>Medição:</p>
              <br />
              <p>
                {new Date(createdAt).toLocaleDateString()}
                {" - "}
                {new Date(createdAt).toLocaleTimeString()}
              </p>
              <br />
            </>
          )}
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TextH2>
              PROGRAMA {customerService?.program.name.toUpperCase()}
            </TextH2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <TextH2>RESULTADO</TextH2>
              {vitalSignsMeasurements["vazio"] && (
                <MedicoesStarSpan>
                  {`SNR: ${verificaTipoExternalId(
                    "SNR",
                    vitalSignsMeasurements["vazio"],
                    2
                  )} | `}
                  <MedicoesStar
                    name="read-only"
                    value={verificaTipoExternalId(
                      "SNR",
                      vitalSignsMeasurements["vazio"],
                      2
                    )}
                    readOnly
                    size="small"
                  />
                </MedicoesStarSpan>
              )}
            </div>
            {!print ? (
              <DownloadIconDiv
                onClick={() => {
                  window.open(
                    `/medicoes/print/${iDMedicao}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                Download PDF
              </DownloadIconDiv>
            ): (
              <DownloadIconDiv
              onClick={() => {
                window.print();
              }}
              >
              Imprimir
              </DownloadIconDiv>
            )}
          </div>
          <br />
          {Object.keys(vitalSignsMeasurements).map((key) => {
            if (key === "vazio") return null;
            return vitalSignsMeasurements[key].length > 0 ? (
              <ContainerBase>
                <TextH2>{vitalSignsMeasurements[key][0].displayGroup}</TextH2>
                <MedicoesAlinhaDiv>
                  {AssembleChart(vitalSignsMeasurements, key)}
                </MedicoesAlinhaDiv>
                <br />
                <HrDashedLine />
                <br />
              </ContainerBase>
            ) : null;
          })}

          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p>
              <b>Atenção!</b> Estes índices não deverão substituir a avaliação
              de um profissional de Saúde. São apenas indicadores de um programa
              de bem-estar.
            </p>
          </div>
        </>
      )}
      {print && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10px",
              backgroundColor: "#FFBB00",
            }}
          ></div>
      )}
    </div>
  );
};

export default DetailedMeasurementsComponent;
