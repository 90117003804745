import {
  IVitalSignsMeasurements,
  VitalSignsMeasurements,
} from "../Services/Interfaces";

export const RoundUp = (value: string, decimal: number) => {
  return parseFloat(
    (Math.ceil(parseFloat(value) * 100) / 100).toFixed(decimal)
  );
};

export const DateFormat = (date: string) => {
  const newDate = new Date(date);
  const day =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  const month =
    newDate.getMonth() + 1 < 10
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const porcentagem = (restante: number, total: number) => {
  return (restante * 100) / total;
};

export const verificaTipo = (
  tipo: string,
  arrayDados: IVitalSignsMeasurements[],
  decimal: number
) => {
  const arrayFiltrado = arrayDados.filter((item) => item.type === tipo);
  if (arrayFiltrado.length > 0) {
    return RoundUp(arrayFiltrado[0].value, decimal);
  }
  return 0;
};

export const verificaTipoExternalId = (
  tipo: string,
  arrayDados: VitalSignsMeasurements[],
  decimal: number
) => {
  const arrayFiltrado = arrayDados.filter((item) => item.externalId === tipo);
  if (arrayFiltrado.length > 0) {
    return RoundUp(arrayFiltrado[0].value, decimal);
  }
  return 0;
};

export const cnpjMask = (cnpj: string) => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const CEPMask = (CEP: string) => {
  return CEP.replace(/^(\d{5})(\d{3})/, "$1-$2");
};

export const cpfMask = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const cpfCnpjMask = (cpfCnpj: string) => {
  if (cpfCnpj.length <= 11) {
    return cpfMask(cpfCnpj);
  } else {
    return cnpjMask(cpfCnpj);
  }
};

export const getColorByBackground = (hexColor: string) => {
  // Converter a cor hexadecimal para RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calcular o brilho usando a fórmula W3C
  const brilho = (r * 299 + g * 587 + b * 114) / 1000;

  // Determinar o contraste ideal
  if (brilho >= 128) {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
};

export const intervalsRange = (intervalsRange: string | null) => {
  let intervalsRangeJson = { colors: [], ranges: [] };
  if (intervalsRange)
    intervalsRangeJson = JSON.parse(intervalsRange) as {
      colors: [];
      ranges: [];
    };

  return intervalsRangeJson;
};
