import { useEffect, useState } from "react";
import { adminProgram } from "../../../../Services/Admin";
import {
  IProgram,
  IProgramIOkRest,
  IUserLogado,
} from "../../../../Services/Interfaces";
import PageHeaderMenu from "../../../Auth/Menu";
import PageEmpresasAdminConteudo from "./Content";

function PageAdminProgramas() {
  const [userLogado, setUserLogado] = useState<IUserLogado>();
  const [program, setProgram] = useState<IProgram[]>();

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [likeSearch, setLikeSearch] = useState<string>("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
    if (userJSON && HealthPlaceJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);

      adminProgram(likeSearch, offset, limit, undefined).then((response) => {
        const { data } = response as IProgramIOkRest;
        setProgram(data.data);
        setTotal(data.pagination?.total || 0);
      });
    }
  }, [likeSearch, limit, offset]);

  return (
    <>
      {userLogado && program && (
        <PageHeaderMenu
          InternalComponent={
            <PageEmpresasAdminConteudo
              companies={program}
              pagination={{
                setLimit,
                setOffset,
                setRowsPerPage,
                rowsPerPage,
                total,
                setPage,
                page,
              }}
              setLikeSearch={setLikeSearch}
            />
          }
        />
      )}
    </>
  );
}

export default PageAdminProgramas;
