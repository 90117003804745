import { styled } from "@mui/material/styles";
import { FiEdit } from "react-icons/fi";
import { HiUser } from "react-icons/hi";

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

export const CenteredPageDiv = styled("div")`
  padding-top: 6%;
  padding-left: 20%;
  padding-right: 30%;
  @media (max-width: 700px) {
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
  }
`;

export const AllTableList = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: justify;
    background-color: #ffffff;
    border: 1px solid #e7e7ed;
  }
  tr th {
    height: 48px;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #1f1f1f;
    background-color: #ffffff;
    max-width: 200px;
  }
  th {
    height: 48px;
    background-color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #272833;
    padding-left: 20px;
    border-top: 1px solid #e7e7ed;
    border-bottom: 1px solid #e7e7ed;
  }
  td {
    height: 48px;
    background-color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #272833;
    padding-left: 20px;
    border-top: 1px solid #e7e7ed;
    border-bottom: 1px solid #e7e7ed;
  }
`;

export const IconSpace = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const IconFiEdit = styled(FiEdit)`
  margin-right: 10px;
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;
export const NewCadastroButtonDiv = styled("div")`
  background: #ffbb00;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0d0d0d;
  width: 160px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #b68500;
    cursor: pointer;
  }
`;

export const DownloadButtonDiv = styled("div")`
  background: #ffbb00;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0d0d0d;
  width: 120px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  &:hover {
    background: #b68500;
    cursor: pointer;
  }
`;

export const HeaderComponent = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconHiUser = styled(HiUser)`
  margin-right: 10px;
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;
