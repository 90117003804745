import { useEffect, useState } from "react";
import { adminListCustomers } from "../../../Services/Admin";
import {
  ICustomer,
  ICustomersIOkRest,
  IUserLogado,
} from "../../../Services/Interfaces";
import PageHeaderMenu from "../../Auth/Menu";
import PageEmpresasAdminConteudo from "./Content";

function PageCustomersHome() {
  const [userLogado, setUserLogado] = useState<IUserLogado>();
  const [customers, setCustomers] = useState<ICustomer[]>();

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [likeSearch, setLikeSearch] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<string>("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
    if (userJSON && HealthPlaceJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);
      adminListCustomers(likeSearch, offset, limit, statusSearch).then((response) => {
        const { data } = response as ICustomersIOkRest;
        setCustomers(data.data);
        setTotal(data.pagination?.total || 0);
      });
    }
  }, [likeSearch, limit, offset, statusSearch]);

  return (
    <>
      {userLogado && customers && (
        <PageHeaderMenu
          InternalComponent={
            <PageEmpresasAdminConteudo
              customers={customers}
              pagination={{
                setLimit,
                setOffset,
                setRowsPerPage,
                rowsPerPage,
                total,
                setPage,
                page,
              }}
              setLikeSearch={setLikeSearch}
              setStatusSearch={setStatusSearch}
            />
          }
        />
      )}
    </>
  );
}

export default PageCustomersHome;
