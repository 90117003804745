import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProgramDiv = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProgramGradeDiv = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
`;

export const DetalhesIconDiv = styled("div")`
  color: #ffbb00;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5px 4px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ffbb00;
  border-radius: 2px;
  cursor: pointer;
`;

export const ProgramForm = styled("form")`
  align-items: center;
`;

type ProgramFormControlProps = {
  percentage?: string;
};

export const ProgramFormControl = styled(FormControl)<ProgramFormControlProps>`
  width: ${(props) => (props.percentage ? props.percentage : "100%")};
  padding-right: 10px;
`;

export const ProgramInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
  cursor: pointer;
`;

export const ProgramInputLabelColor = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #717171;
`;

export const ProgramColorDivLabel = styled("div")`
  text-align: center;
`;

export const ProgramOutlinedInput = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const ProgramOutlinedInputFile = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
  display: none;
`;

export const ProgramColorInput = styled("input")`
  border-radius: 6px;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ProgramColorDiv = styled("div")<ProgramFormControlProps>`
  width: ${(props) => (props.percentage ? props.percentage : "100%")};
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  margin-right: 10px;
`;

export const ProgramSelect = styled(Select)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const ProgramMenuItem = styled(MenuItem)`
  background-color: #ffffff;
`;

export const ProgramContentButton = styled("div")`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width: 200px;
`;

export const ProgramButton = styled("button")`
  border: none;
  margin-top: 15px;
  width: 100%;
  background: #ffbb00;
  border-top: 15px solid #ffbb00;
  border-bottom: 15px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    background-color: #ffbb00;
    border-top: 15px solid #ffbb00;
    border-bottom: 15px solid #ffbb00;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #b68500;
    border-top: 15px solid #b68500;
    border-bottom: 15px solid #b68500;
  }
`;

type ProgramButtonGradeProps = {
  isSelected: boolean;
};

export const ProgramButtonGrade = styled("button")<ProgramButtonGradeProps>`
  border: none;
  margin-top: 15px;
  width: 100%;
  background: #ffffff;
  border: 15px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:hover {
    background-color: #0084ff;
    border: 15px solid #0084ff;
  }
  background-color: ${(props) => (props.isSelected ? "#7bff00" : "#ffffff")};
  border: 15px solid ${(props) => (props.isSelected ? "#7bff00" : "#ffffff")};
`;

export const ProgramSpaceDiv = styled("div")`
  display: flex;
`;

export const ProgramBaseboardEndDiv = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const ProgramAtiveButton = styled("button")`
  border: none;
  margin-top: 15px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #52ff30;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  margin-right: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    background-color: #52ff30;
    border-top: 15px solid #52ff30;
    border-bottom: 15px solid #52ff30;
  }
`;

export const ProgramInativeButton = styled("button")`
  border: none;
  margin-top: 15px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ff3b30;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  margin-right: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    background-color: #ff3b30;
    border-top: 15px solid #ff3b30;
    border-bottom: 15px solid #ff3b30;
  }
`;

export const ProgramColorTriDiv = styled("div")`
  display: flex;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
`;
