import { useLocation } from "react-router-dom";
import MensagemComponents from "../../Components/Aton/Menssage";

import PageHeaderMenu from "../Auth/Menu";

function Mensagem() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorMessage = searchParams.get("message");

  return (
    <PageHeaderMenu
      InternalComponent={<MensagemComponents mensagemErro={errorMessage} />}
    />
  );
}

export default Mensagem;
