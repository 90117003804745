import React, { useEffect, useState } from "react";
import {
  CompanyButton,
  CompanyContentButton,
  CompanyDate,
  CompanyFormControl,
  CompanyInputLabel,
  CompanyMenuItem,
  CompanySelect,
  customStyles,
  ModalH2,
} from "./styles";
import Modal from "react-modal";
import { exportUser, getHealthPlaceClienteAll } from "../../../Services/Admin";
import { AxiosResponse } from "axios";
import { IHealthPlaceListIOkRest } from "../../../Services/Interfaces";

interface ModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const ModalComponentExportReport: React.FC<ModalProps> = ({
  setIsOpen,
  isOpen,
}) => {
  Modal.setAppElement("#root");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDdateTo] = useState<string>("");
  const [healthPlace, setHealthPlace] = useState<string>("");
  const [isActive, setIsActive] = useState<string>("");
  const [healthPlaceClienteAll, setHealthPlaceClienteAll] =
    useState<{ id: string; name: string }[]>();

  const downloadUser = async () => {
    let isActiveStatus = "";
    switch (isActive) {
      case "Todos":
        break;
      case "Ativo":
        isActiveStatus = "true";
        break;
      case "Inativo":
        isActiveStatus = "false";
        break;
      default:
        break;
    }

    let healthPlaceFiltro = "";
    switch (healthPlace) {
      case "Todos":
        break;
      default:
        healthPlaceFiltro = healthPlace;
        break;
    }
    await exportUser(dateFrom, dateTo, healthPlaceFiltro, isActiveStatus).then(
      (response) => {
        const dow = response as AxiosResponse<Blob>;
        if (dow.status === 200) {
          const downloadUrl = window.URL.createObjectURL(dow.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute(
            "download",
            `exportUser-${new Date().getTime()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        setIsOpen(false);
      }
    );
  };

  useEffect(() => {
    isOpen &&
      getHealthPlaceClienteAll().then((response) => {
        const data = response as IHealthPlaceListIOkRest;
        setHealthPlaceClienteAll(data.data.data);
      });
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="EXPORTAR RELATÓRIO"
    >
      <ModalH2>EXPORTAR RELATÓRIO</ModalH2>
      <br />
      <br />
      <div style={{ width: "800px" }}>
        <div style={{ display: "flex" }}>
          <CompanyFormControl percentage={"25%"} variant="outlined">
            <CompanyDate
              id="dateFrom"
              label="Data inicial da medição"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CompanyFormControl>
          <CompanyFormControl percentage={"25%"} variant="outlined">
            <CompanyDate
              id="dateTo"
              label="Data final da medição"
              type="date"
              value={dateTo}
              onChange={(e) => setDdateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CompanyFormControl>
          <CompanyFormControl percentage={"25%"} variant="outlined">
            <CompanyInputLabel htmlFor="tipo">Empresa</CompanyInputLabel>
            <CompanySelect
              value={healthPlace}
              onChange={(e) => {
                const valor = e.target.value as string;
                setHealthPlace(valor);
              }}
              id="healthPlace"
              label="Empresa"
            >
              <CompanyMenuItem key={"Todos"} value={"Todos"}>
                Todos
              </CompanyMenuItem>
              {healthPlaceClienteAll?.map((item) => (
                <CompanyMenuItem key={item.id} value={item.id}>
                  {item.name}
                </CompanyMenuItem>
              ))}
            </CompanySelect>
            {/* <CompanyTexto
              id="healthPlace"
              label="Buscar por CPF"
              type="text"
              value={healthPlace}
              onChange={(e) => setHealthPlace(e.target.value.replace(/[./-]/g, ""))}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </CompanyFormControl>
          <CompanyFormControl percentage={"25%"} variant="outlined">
            <CompanyInputLabel htmlFor="tipo">Status</CompanyInputLabel>
            <CompanySelect
              value={isActive}
              onChange={(e) => {
                const valor = e.target.value as string;
                setIsActive(valor);
              }}
              id="isActive"
              label="Status"
            >
              <CompanyMenuItem key={"Todos"} value={"Todos"}>
                Todos
              </CompanyMenuItem>
              <CompanyMenuItem key={"Ativo"} value={"Ativo"}>
                Ativo
              </CompanyMenuItem>
              <CompanyMenuItem key={"Inativo"} value={"Inativo"}>
                Inativo
              </CompanyMenuItem>
            </CompanySelect>
          </CompanyFormControl>
        </div>
      </div>
      <CompanyContentButton>
        <div>
          <CompanyButton onClick={downloadUser}>
            Exportar Relatório
          </CompanyButton>
        </div>
      </CompanyContentButton>
    </Modal>
  );
};

export default ModalComponentExportReport;
