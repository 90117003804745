import { useEffect, useState } from "react";
import MyAccountComponent from "../../Components/Account";
import { IUserLogado } from "../../Services/Interfaces";
import PageHeaderMenu from "../Auth/Menu";

function MyAccountPage() {
  const [userLogado, setUserLogado] = useState<IUserLogado>();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);
    }
  }, []);

  return (
    <>
      {userLogado && (
        <PageHeaderMenu
          InternalComponent={<MyAccountComponent user={userLogado} />}
        />
      )}
    </>
  );
}

export default MyAccountPage;
