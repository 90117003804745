import { styled } from "@mui/material/styles";

export const PaginationContainerDiv = styled("div")`
  color: black;
  display: flex;
  justify-content: inherit;
  width: 100%;
  align-items: center;
  border-top: 1px solid #e9ecef;
`;
