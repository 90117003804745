import React, { ChangeEvent, FormEvent, useState } from "react";
import { ImPencil } from "react-icons/im";
import { FiPlus } from "react-icons/fi";
import { userChangePassword } from "../../Services/Auth";
import { IUserLogado } from "../../Services/Interfaces";
import { userProfilePicture } from "../../Services/User";
import { CenteredPageDiv } from "../../styles/global.styles";
import {
  // AccountA,
  AccountAvatarContainer,
  AccountButton,
  AccountColorDiv,
  // AccountContentA,
  AccountContentButton,
  AccountForm,
  AccountFormControl,
  AccountIconButton,
  AccountInputAdornment,
  AccountInputLabel,
  AccountOutlinedInput,
  AccountOutlinedInputFile,
  TextH2,
} from "./styles";

interface MyAccountProps {
  user: IUserLogado;
}

const MyAccountComponent: React.FC<MyAccountProps> = ({ user }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileReader, setFileReader] = useState<string>();
  const [updatePass, setUpdatePass] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");

  const [showCurrentPassword, setShowcurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

  const handleClickShowPasswordCurrent = () =>
    setShowcurrentPassword((show) => !show);

  const handleClickShowPasswordNew = () => setShowNewPassword((show) => !show);

  const handleClickShowPasswordRepeat = () =>
    setShowRepeatNewPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const files = event.target.files;

    if (files && files.length > 0) {
      setFile(files[0]);
      reader.readAsDataURL(files[0]);
    } else {
      setFile(undefined);
    }
    reader.onloadend = () => {
      const img = reader.result as string;
      setFileReader(img);
    };
  };

  const handleUpload = async (e: FormEvent) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append(
        "profile_picture[profilePictureFile][file]",
        file,
        file.name
      );
      await userProfilePicture(formData);
      window.location.reload();
    }
  };

  const handlenewPassword = async (e: FormEvent) => {
    e.preventDefault();
    await userChangePassword(currentPassword, newPassword, repeatNewPassword);
    setUpdatePass(false);
  };

  return (
    <>
      {!updatePass && (
        <CenteredPageDiv>
          <TextH2>Minha Conta</TextH2>
          <br />
          <AccountForm onSubmit={handleUpload}>
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="email">Nome</AccountInputLabel>
              <AccountOutlinedInput
                disabled
                value={user.profile.fullName}
                id="nome"
                type="text"
                label="Nome"
              />
            </AccountFormControl>
            <br />
            <br />
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="email">CPF</AccountInputLabel>
              <AccountOutlinedInput
                disabled
                value={user.profile.cpf}
                id="cpf"
                type="text"
                label="CPF"
              />
            </AccountFormControl>
            <br />
            <br />
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="email">E-mail</AccountInputLabel>
              <AccountOutlinedInput
                disabled
                value={user.email}
                id="email"
                type="email"
                label="E-mail"
              />
            </AccountFormControl>
            <br />
            <br />
            <AccountColorDiv percentage={"100%"}>
              <AccountInputLabel htmlFor="file">
                {(user.profile.profilePicture || fileReader) && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "self-end",
                    }}
                  >
                    <AccountAvatarContainer
                      alt={user.profile.fullName}
                      src={
                        fileReader
                          ? fileReader
                          : `${
                              process.env.REACT_APP_API_URL ||
                              "https://api.sinaisvitais.prosperasaude.com.br"
                            }${user.profile.profilePicture}`
                      }
                    />
                    <ImPencil />
                  </div>
                )}
                {!user.profile.profilePicture && !fileReader && (
                  <div style={{ textAlignLast: "center" }}>
                    <FiPlus size={14} />
                    <br />
                    Enviar Foto
                  </div>
                )}
              </AccountInputLabel>

              <AccountOutlinedInputFile
                onChange={handleFileChange}
                id="file"
                type="file"
                label="File"
              />
            </AccountColorDiv>
            <br />
            <br />
            {/* <AccountContentA>
              <AccountA
                onClick={() => {
                  setUpdatePass(!updatePass);
                }}
              >
                Alterar Senha
              </AccountA>
            </AccountContentA> */}
            <AccountContentButton>
              <AccountButton title="Salvar">Salvar</AccountButton>
            </AccountContentButton>
          </AccountForm>
        </CenteredPageDiv>
      )}
      {updatePass && (
        <CenteredPageDiv>
          <AccountForm onSubmit={handlenewPassword}>
            <TextH2>Alterar Senha</TextH2>
            <br />
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="currentPassword">
                Senha Atual
              </AccountInputLabel>
              <AccountOutlinedInput
                type={showCurrentPassword ? "text" : "password"}
                inputProps={{ minLength: 8, maxLength: 30 }}
                onChange={(e) => setCurrentPassword(e.target.value)}
                id="currentPassword"
                label="Senha Atual"
                endAdornment={
                  <AccountInputAdornment position="end">
                    <AccountIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordCurrent}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? "Ocultar" : "Mostrar"}
                    </AccountIconButton>
                  </AccountInputAdornment>
                }
              />
            </AccountFormControl>
            <br />
            <br />
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="password">
                Nova Senha
              </AccountInputLabel>
              <AccountOutlinedInput
                inputProps={{ minLength: 8, maxLength: 30 }}
                onChange={(e) => setNewPassword(e.target.value)}
                id="password"
                type={showNewPassword ? "text" : "password"}
                endAdornment={
                  <AccountInputAdornment position="end">
                    <AccountIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? "Ocultar" : "Mostrar"}
                    </AccountIconButton>
                  </AccountInputAdornment>
                }
                label="Senha"
              />
            </AccountFormControl>
            <br />
            <br />
            <AccountFormControl variant="outlined">
              <AccountInputLabel htmlFor="password">
                Repita a senha
              </AccountInputLabel>
              <AccountOutlinedInput
                inputProps={{ minLength: 8, maxLength: 30 }}
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                id="repeatPassword"
                type={showRepeatNewPassword ? "text" : "password"}
                endAdornment={
                  <AccountInputAdornment position="end">
                    <AccountIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordRepeat}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showRepeatNewPassword ? "Ocultar" : "Mostrar"}
                    </AccountIconButton>
                  </AccountInputAdornment>
                }
                label="Repita a senha"
              />
            </AccountFormControl>
            <AccountContentButton>
              <AccountButton title="Alterar Senha">Alterar Senha</AccountButton>
            </AccountContentButton>
          </AccountForm>
        </CenteredPageDiv>
      )}
    </>
  );
};

export default MyAccountComponent;
