import React, { useState } from "react";
import MeasurementDetails from "../../Modal/MeasurementDetails";
import { MedicoesCirculoTextoSpan } from "../Historic/styles";
import {
  BoxDivValorCircular,
  BoxDivVerdeColor,
  BoxLinhaDiv,
  GraphicBoxLinhaDiv,
  TextoP,
} from "./styles";

interface GraphicLinhaMaiorProps {
  title: string;
  value: number[];
  colors: string[];
  ranges: number[];
  unit: string;
  codeMeasurement: string;
}

const GraphicLinhaMaior: React.FC<GraphicLinhaMaiorProps> = ({
  title,
  value,
  colors,
  ranges,
  unit,
  codeMeasurement,
}) => {
  const rangeValor = () => {
    const valorRange = parseFloat(value[0].toString());
    if (valorRange < ranges[0]) {
      return 0;
    } else if (valorRange >= ranges[1] && valorRange < ranges[2]) {
      return 1;
    } else if (valorRange >= ranges[3] && valorRange < ranges[4]) {
      return 2;
    } else if (valorRange >= ranges[5] && valorRange < ranges[6]) {
      return 3;
    } else if (valorRange >= ranges[7]) {
      return 4;
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  const locationHref = () => {
    setIsOpen(!modalIsOpen);
  };

  return (
    <GraphicBoxLinhaDiv>
      <TextoP onClick={locationHref}>
        {title} {unit}
      </TextoP>
      <br />
      <BoxLinhaDiv>
        {colors.map((valorObjeto, index) => {
          return (
            <BoxDivVerdeColor key={index} color={colors[index]}>
              {index === rangeValor() && (
                <BoxDivValorCircular>
                  <MedicoesCirculoTextoSpan>
                    {value[1] ? (
                      <>
                        <sup>{value[0]}</sup>/<sub>{value[1]}</sub>
                      </>
                    ) : (
                      value[0]
                    )}
                  </MedicoesCirculoTextoSpan>
                </BoxDivValorCircular>
              )}
            </BoxDivVerdeColor>
          );
        })}
      </BoxLinhaDiv>
      <MeasurementDetails
        codeMeasurement={codeMeasurement}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      />
    </GraphicBoxLinhaDiv>
  );
};

export default GraphicLinhaMaior;
