import { toast } from "react-toastify";
import axios from "axios";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api, baseURL } from "./Api";
import {
  IErroRest,
  ICompanyIOkRest,
  ICustomersIOkRest,
  IOkRest,
  IUFIOkRest,
  ICustomer,
  ICustomerIOkRest,
  IHealthPlaceIOkRest,
  IProgramIOkRest,
  IVitalSignsIOkRest,
  IHealthPlaceListIOkRest,
} from "./Interfaces";

export const adminHealthPlace = async (
  likeSearch: string = "",
  offset: number = 0,
  limit: number = 10,
  healthPlaceType?: string,
  id?: string
) => {
  return toast.promise(
    new Promise((resolve, reject) => {
      api
        .get(
          `/admin/health_place${
            id ? `/${id}` : ""
          }?attributes[]=name&attributes[]=cnpj&attributes[]=corporateName&attributes[]=address&attributes[]=accessUrl&offset=${offset}&limit=${limit}&likeSearch=${likeSearch}${
            healthPlaceType ? `&healthPlaceType=${healthPlaceType}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response: ICompanyIOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      pending: "Consultando Empresas.",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          return data.response.data.message;
        },
      },
    }
  );
};

export const adminProgram = async (
  likeSearch: string = "",
  offset: number = 0,
  limit: number = 10,
  id?: string
) => {
  return toast.promise(
    new Promise((resolve, reject) => {
      api
        .get(
          `/admin/program${
            id ? `/${id}` : ""
          }?offset=${offset}&limit=${limit}&likeSearch=${likeSearch}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response: IProgramIOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      pending: "Consultando Empresas.",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          return data.response.data.message;
        },
      },
    }
  );
};

export const adminListCustomers = async (
  likeSearch: string = "",
  offset: number = 0,
  limit: number = 10,
  setStatusSearch?: string
) => {
  return toast.promise(
    new Promise((resolve, reject) => {
      api
        .get(
          `/admin/users?offset=${offset}&limit=${limit}&likeSearch=${likeSearch}${
            setStatusSearch ? `&isActive=${setStatusSearch}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response: ICustomersIOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Lista de Clientes.",
      pending: "Consultando Clientes.",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          return data.response.data.message;
        },
      },
    }
  );
};

export const exportUser = async (
  dateFrom?: string,
  dateTo?: string,
  healthPlace?: string,
  isActive?: string,
  offset?: number,
  limit?: number
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/export/general_data?offset=${offset ? offset : 0}&limit=${
              limit ? limit : 1048576
            }${dateFrom ? `&dateFrom=${dateFrom}` : ""}
            ${dateTo ? `&dateTo=${dateTo}` : ""}
            ${healthPlace ? `&healthPlace=${healthPlace}` : ""}
            ${isActive ? `&isActive=${isActive}` : ""}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: "blob",
            }
          )
          .then((response: IOkRest) => {
            return resolve(response);
          })
          .catch((error: IErroRest) => {
            return reject(error);
          });
      }),
      {
        success: "Download Efetuado.",
        pending: "Baixando arquivo CSV.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const exportEmpresas = async (offset?: number, limit?: number) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/health_place/export?offset=${offset ? offset : 0}&limit=${
              limit ? limit : 1048576
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: "blob",
            }
          )
          .then((response: IOkRest) => {
            return resolve(response);
          })
          .catch((error: IErroRest) => {
            return reject(error);
          });
      }),
      {
        success: "Download Efetuado.",
        pending: "Baixando arquivo CSV.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const exportDumpDB = async (offset?: number, limit?: number) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/dump/database`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          })
          .then((response: IOkRest) => {
            return resolve(response);
          })
          .catch((error: IErroRest) => {
            return reject(error);
          });
      }),
      {
        success: "Download Data Base efetuado.",
        pending: "Baixando arquivo Data.",
        error: "Erro ao baixar arquivo Data.",
      }
    );
  } catch (erro) {
    return false;
  }
};

export const exportCostumers = async (
  status: string,
  search: string,
  offset?: number,
  limit?: number
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/export/client?offset=${offset ? offset : 0}&limit=${
              limit ? limit : 1048576
            }${search ? `&likeSearch=${search}` : ""}${
              status === "Todos" ? "" : `&isActive=${status}`
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: "blob",
            }
          )
          .then((response: IOkRest) => {
            return resolve(response);
          })
          .catch((error: IErroRest) => {
            return reject(error);
          });
      }),
      {
        success: "Download Efetuado.",
        pending: "Baixando arquivo CSV.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const getProgram = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/admin/program?offset=0&limit=100`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: IUFIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const getVitalSigns = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/admin/vital_signs?offset=0&limit=9999`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: IVitalSignsIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const getUF = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/uf?offset=0&limit=100`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: IUFIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const getHealthPlaceCliente = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/admin/health_place?healthPlaceType=Agregadora&offset=0&limit=9999`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response: IHealthPlaceIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};
export const getHealthPlaceClienteAll = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/admin/health_place_all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: IHealthPlaceListIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const getProgramAll = async () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/admin/program_all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: IProgramIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const healthPlaceDeaActivate = async (id: string, activate: boolean) => {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/admin/health_place/${id}${activate ? "/activate" : "/deactivate"}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response: IOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const healthPlacePut = async (id: string, data: Object) => {
  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${id}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Empresa Atualizada",
      pending: "Atualizando Empresa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const programPut = async (id: string, data: Object) => {
  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/program/${id}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Programa Atualizado",
      pending: "Atualizando Programa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const healthPlacePost = async (data: Object) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };

  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Empresa Cadastrada",
      pending: "Criando Empresa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          if (data.response.data.data.children?.address?.children) {
            Object.keys(
              data.response.data.data.children.address.children
            ).forEach(function (item) {
              if (
                data.response.data.data.children.address.children[item].errors
                  .length > 0
              ) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children.address.children[item]
                      .errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const programPost = async (data: Object) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/program`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };

  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Programa Cadastrada",
      pending: "Criando Programa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const integrationUserPostPut = async (
  data: Object,
  healthPlaceID: string,
  method: string
) => {
  const config = {
    method: method,
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${healthPlaceID}/integration_user`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };

  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Integração Cadastrada",
      pending: "Criando integração",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  item +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const healthPlaceLogoPost = async (id: string, data: FormData) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${id}/logo`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((response: IOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const getUser = async (ulid: string) => {
  return new Promise<ICustomer>((resolve, reject) => {
    api
      .get(`/admin/users/${ulid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: ICustomerIOkRest) => {
        return resolve(response.data.data);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const updateUser = async (ulid: string, data: Object) => {
  return toast.promise(
    new Promise<ICustomerIOkRest>((resolve, reject) => {
      api
        .put(`/admin/user/${ulid}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response: ICustomerIOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Cliente Atualizado",
      pending: "Atualizando Cliente",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  getFieldName(item) +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

function getFieldName(item: string) {
  switch (item) {
    case "profileFullName":
      return "Nome";
    case "profileCpf":
      return "CPF";
    default:
      return "Genérico";
  }
}

export const programADDVitalSignsPost = async (
  programId: string,
  vitalSignId: string
) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/program/${programId}/vital_signs/${vitalSignId}/add`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Adicionado Sinal Vital",
      pending: "Adicionando Sinal Vital",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  getFieldName(item) +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const programRemoveVitalSignsPost = async (
  programId: string,
  vitalSignId: string
) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/program/${programId}/vital_signs/${vitalSignId}/remove`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Removido Sinal Vital",
      pending: "Removendo Sinal Vital",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  getFieldName(item) +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const programDelete = async (programId: string) => {
  const config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/program/${programId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Programa Removido",
      pending: "Removendo Programa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          return data.response.data.message;
        },
      },
    }
  );
};

export const programADDHealthPlacePost = async (
  healthPlaceId: string,
  programId: string
) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${healthPlaceId}/program/${programId}/add`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Programa Adicionado",
      pending: "Adicionando Programa",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  getFieldName(item) +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const programRemoveHealthPlacePost = async (
  healthPlaceId: string,
  programId: string
) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${healthPlaceId}/program/${programId}/remove`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Removido Sinal Vital",
      pending: "Removendo Sinal Vital",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          const mensagem = new Array<string>();
          if (data.response.data.data.children) {
            Object.keys(data.response.data.data.children).forEach(function (
              item
            ) {
              if (data.response.data.data.children[item].errors.length > 0) {
                mensagem.push(
                  getFieldName(item) +
                    " - " +
                    data.response.data.data.children[item].errors[0].message
                );
              }
            });
          }
          return mensagem;
        },
      },
    }
  );
};

export const uploadPolice = async (data: FormData, healthPlaceId: string) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/admin/health_place/${healthPlaceId}/term`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  };
  return toast.promise(
    new Promise((resolve, reject) => {
      axios(config)
        .then((response: IOkRest) => {
          return resolve(response);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Politica Adicionado",
      pending: "Adicionando Politica",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as any;
          return data.response.data.data.children.termFile.errors[0].message;
        },
      },
    }
  );
};
