import React, { useState } from "react";

import {
  AllTableList,
  HeaderComponent,
  IconFiEdit,
  IconSpace,
  NewCadastroButtonDiv,
  TextH2,
} from "../../../../styles/global.styles";
import { IProgram } from "../../../../Services/Interfaces";
import { ProgramasDiv } from "./styles";
import { FiPlus } from "react-icons/fi";
import PaginationMUI, {
  PaginationProps,
} from "../../../../Components/Pagination";
import { useNavigate } from "react-router-dom";
import {
  CustomerFormControl,
  CustomerInputLabel,
  CustomerOutlinedInput,
} from "../../../../Components/CustomerEdit/styles";
import { BuscarIconDiv } from "../../Customers/styles";

interface PageProgramasProps {
  companies: IProgram[];
  pagination: PaginationProps;
  setLikeSearch: React.Dispatch<React.SetStateAction<string>>;
}

const PageProgramasAdminConteudo: React.FC<PageProgramasProps> = ({
  companies,
  pagination,
  setLikeSearch,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  return (
    <ProgramasDiv>
      <HeaderComponent>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TextH2>Gestão Programas</TextH2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NewCadastroButtonDiv
            onClick={() => {
              Rota("/admin/gestao/programas/new");
            }}
          >
            <FiPlus
              style={{
                marginRight: "5px",
              }}
            />
            Novo Cadastro
          </NewCadastroButtonDiv>
        </div>
      </HeaderComponent>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomerFormControl variant="outlined">
          <CustomerInputLabel htmlFor="profileFullName">
            Texto da busca
          </CustomerInputLabel>
          <CustomerOutlinedInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            id="profileFullName"
            type="text"
            label="Texto da busca"
            onKeyDown={(e) => {
              if (e.key === "Enter") setLikeSearch(search);
            }}
          />
        </CustomerFormControl>
        <BuscarIconDiv
          onClick={() => {
            setLikeSearch(search);
          }}
        >
          Buscar
        </BuscarIconDiv>
      </div>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>Nome</th>
              <th></th>
            </tr>
            {companies?.map((company) => {
              return (
                <tr key={company.id}>
                  <td>{company.name}</td>
                  <td>
                    <IconSpace>
                      <IconFiEdit
                        onClick={() => {
                          Rota(`/admin/gestao/programas/${company.id}`);
                        }}
                        size={20}
                      />
                    </IconSpace>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AllTableList>
      <PaginationMUI
        setLimit={pagination.setLimit}
        setOffset={pagination.setOffset}
        setRowsPerPage={pagination.setRowsPerPage}
        rowsPerPage={pagination.rowsPerPage}
        total={pagination.total}
        setPage={pagination.setPage}
        page={pagination.page}
      />
    </ProgramasDiv>
  );
};

export default PageProgramasAdminConteudo;
