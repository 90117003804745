import React, { useEffect, useState } from "react";
import { CenteredPageDiv } from "../../styles/global.styles";
import { BoxDiv, IFrame, PolicyAccept, PolicyAcceptDiv } from "./styles";
import { usageTerms } from "../../Services/User";
import { IHealthPlaceURL, IUserLogado } from "../../Services/Interfaces";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../Services/Api";

const PolicyComponent: React.FC<any> = () => {
  const navigate = useNavigate();
  const [policy, setPolicy] = useState(false);
  const [term, setTerm] = useState<string>("");
  const pdfUrl = `${baseURL}/storage/pdfs/terms_health_place/${term}`;

  const onSubmitAceitePolicy = () => {
    usageTerms().then((response) => {
      if (response === true) {
        setPolicy(true);
        navigate("/home", { replace: true });
      }
    });
  };

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    const HealthPlaceProfile = localStorage.getItem("HealthPlaceProfile");
    if (userJSON !== null && HealthPlaceProfile !== null) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      const HealthPlaceProfileObjeto = JSON.parse(
        HealthPlaceProfile
      ) as IHealthPlaceURL;
      if (userObjeto.profile.usageTerms !== null) {
        setPolicy(userObjeto.profile.usageTerms);
      }
      if (HealthPlaceProfileObjeto.term !== null) {
        setTerm(HealthPlaceProfileObjeto.term);
      }
    }
  }, []);

  return (
    <CenteredPageDiv>
      <BoxDiv>
        <IFrame altura={window.innerHeight > 800 ? 600 : window.innerHeight - 200} src={pdfUrl} title="PDF Termos" />
        <PolicyAcceptDiv>
          <PolicyAccept
            onClick={onSubmitAceitePolicy}
            title="Aceite"
            disabled={policy}
          >
            Aceite
          </PolicyAccept>
        </PolicyAcceptDiv>
      </BoxDiv>
    </CenteredPageDiv>
  );
};

export default PolicyComponent;
