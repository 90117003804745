import styled from "@emotion/styled";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ModalH2 = styled("h2")`
  color: #0d0d0d;
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 9px;
`;

export const ModalContentA = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const ModalA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  text-decoration-line: underline;

  color: #717171;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #00da8c;
  }
`;

export const ModalClose = styled(AiFillCloseCircle)`
  color: red;
  font-size: 24px;
`;

export const ModalFormularioDiv = styled("form")`
  align-items: center;
  padding: 8px;
  width: 500px;

  @media (max-width: 700px) {
    padding: 8px 20px;
    width: auto;
    height: 60vh;
  }
`;

export const ModalFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 10px;
`;

export const ModalInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
`;

export const ModalOutlinedInput = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const ModalRadioGroup = styled(RadioGroup)``;
export const ModalFormControlLabel = styled(FormControlLabel)``;
export const ModalRadio = styled(Radio)``;
export const ModalFormLabel = styled(FormLabel)``;

export const ModalContentButton = styled("div")`
  display: flex;
  justify-content: center;
`;

export const ModalButton = styled("button")`
  border: none;
  margin-top: 30px;
  padding: 15px 60px;
  gap: 15px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;

  &:hover {
    background-color: #b68500;
  }
`;
