import { useLocation } from "react-router-dom";
import { GetHealthPlace, dadosUser } from "../../Services/Auth";
import { DominioURL } from "../../Components/Auth/isAuthenticated";

function External() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  localStorage.setItem("token", token || "");

  GetHealthPlace(DominioURL()).then(() => {
    dadosUser().then(() => {
      window.location.href = "/home";
    });
  });

  return <div>Processando login</div>;
}

export default External;
