import { FormHelperText } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import qs from "qs";
import {
  getVitalSigns,
  programADDVitalSignsPost,
  programDelete,
  programPost,
  programPut,
  programRemoveVitalSignsPost,
} from "../../../../Services/Admin";
import { IProgram } from "../../../../Services/Interfaces";

import { HeaderComponent, TextH2 } from "../../../../styles/global.styles";
import {
  ProgramButton,
  ProgramContentButton,
  ProgramForm,
  ProgramFormControl,
  ProgramInputLabel,
  ProgramOutlinedInput,
  ProgramDiv,
  ProgramSpaceDiv,
  ProgramBaseboardEndDiv,
  ProgramGradeDiv,
  ProgramButtonGrade,
  ProgramInativeButton,
} from "./styles";
import { useNavigate } from "react-router-dom";

interface PageProgramProps {
  Program: IProgram | null;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageProgramAdminEdit: React.FC<PageProgramProps> = ({
  Program,
  setForceUpdate,
}) => {
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [vitalSigns, setVitalSigns] = useState([]);

  const [nameErro, setNameErro] = useState(false);

  const errosCheck = (erros: any) => {
    if (erros.response.data.data.children) {
      Object.keys(erros.response.data.data.children).forEach(function (itemOn) {
        if (
          erros.response.data.data.children[itemOn].errors.length > 0 ||
          erros.response.data.data.children[itemOn].children
        ) {
          const mensagemErro =
            erros.response.data.data.children[itemOn].errors[0]?.message || "";

          switch (itemOn) {
            case "name":
              setNameErro(mensagemErro);
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const onSubmitProgramas = (e: FormEvent) => {
    e.preventDefault();

    if (Program?.id) {
      programPut(
        Program?.id,
        qs.stringify({
          "update[name]": nome,
        })
      )
        .then((result: any) => {
          if (result.status === 201) {
            return navigate(`/admin/gestao/programas/${result.data.data}`, {
              replace: true,
            });
          }
        })
        .catch((error) => {
          errosCheck(error);
        });
    } else {
      programPost(
        qs.stringify({
          "create[name]": nome,
        })
      )
        .then((result: any) => {
          if (result.status === 201) {
            return navigate(`/admin/gestao/programas/${result.data.data}`, {
              replace: true,
            });
          }
        })
        .catch((error) => {
          errosCheck(error);
        });
    }
  };

  useEffect(() => {
    if (Program !== undefined) {
      getVitalSigns().then((result: any) => {
        // ordenar array pelo nome
        result.data.data.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name, "pt-BR");
        });
        setVitalSigns(result.data.data);
      });
    }
    if (Program !== null && Program !== undefined) {
      setNome(Program.name);
    }
  }, [Program]);

  return (
    <ProgramDiv>
      <HeaderComponent>
        {Program ? (
          <TextH2>Atualização Programa</TextH2>
        ) : (
          <TextH2>Cadastrar Programas</TextH2>
        )}
      </HeaderComponent>
      <br />
      <ProgramForm onSubmit={onSubmitProgramas}>
        <ProgramSpaceDiv>
          <ProgramFormControl error={nameErro} variant="outlined">
            <ProgramInputLabel htmlFor="name">
              Nome do programa
            </ProgramInputLabel>
            <ProgramOutlinedInput
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              id="name"
              type="text"
              label="Nome Fantasia"
            />
            {nameErro && (
              <FormHelperText id="corporateName-error">
                {nameErro}
              </FormHelperText>
            )}
          </ProgramFormControl>
        </ProgramSpaceDiv>
        <ProgramBaseboardEndDiv>
          <ProgramContentButton>
            {Program && (
              <ProgramInativeButton
                onClick={() => {
                  programDelete(Program.id).then(() => {
                    navigate("/admin/gestao/programas", { replace: true });
                  });
                }}
                type="button"
                title="Apagar"
              >
                Apagar
              </ProgramInativeButton>
            )}
            <ProgramButton title="Salvar">Salvar</ProgramButton>
          </ProgramContentButton>
        </ProgramBaseboardEndDiv>
      </ProgramForm>
      <br />
      <ProgramSpaceDiv>
        {vitalSigns && Program && (
          <ProgramGradeDiv>
            {vitalSigns.map((vital: any) => (
              <ProgramButtonGrade
                key={vital.id}
                isSelected={Program.vitalSigns.some(
                  (item: any) => item.id === vital.id
                )}
                onClick={() => {
                  if (
                    Program.vitalSigns.some((item: any) => item.id === vital.id)
                  ) {
                    programRemoveVitalSignsPost(Program.id, vital.id).then(
                      () => {
                        setForceUpdate((prev: boolean): boolean => !prev);
                      }
                    );
                  } else {
                    programADDVitalSignsPost(Program.id, vital.id).then(() => {
                      setForceUpdate((prev: boolean): boolean => !prev);
                    });
                  }
                }}
              >
                {vital.name}
              </ProgramButtonGrade>
            ))}
          </ProgramGradeDiv>
        )}
      </ProgramSpaceDiv>
    </ProgramDiv>
  );
};

export default PageProgramAdminEdit;
