import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AdminRoutes,
  DominioURL,
  PrivateRoutes,
  TokenRoutes,
} from "./Components/Auth/isAuthenticated";
import PageCompanyList from "./Pages/Admin/Company/List";
import PageCompanyEdit from "./Pages/Admin/Company/Edit";
import LoginPage from "./Pages/Auth/Login";
import PageHome from "./Pages/Home";
import PageMeasurementDetails from "./Pages/Measurement/Details";
import HistoricalMeasurementPage from "./Pages/Measurement/Historic";
import MyAccountPage from "./Pages/MyAccount";
import MyPurchase from "./Pages/Purchase";
import Mensagem from "./Pages/Mensagem";
import External from "./Pages/External";
import PageCustomersHome from "./Pages/Admin/Customers";
import PolicyPage from "./Pages/Policy";
import PageCustomerRegistration from "./Pages/Admin/Customers/New";
import { GetHealthPlace } from "./Services/Auth";
import { useEffect, useState } from "react";
import { IHealthPlaceURL } from "./Services/Interfaces";
import PageCustomerEdit from "./Pages/Admin/Customers/Edit";
import PageMeasurementPrint from "./Pages/Measurement/Print";
import PageAdminProgramas from "./Pages/Admin/Programs/List";
import PageAdminProgramasEdit from "./Pages/Admin/Programs/Edit";
// import Manutencao from "./Pages/Auth/Login/manutencao";

function Router() {
  const [primary, setPrimary] = useState("#6257b8");
  const [secondary, setSecondary] = useState("#f1f1f1");
  const [fontColor, setFontColor] = useState("#000000");
  const [healthPlaceLogo, setHealthPlaceLogo] = useState<string>("");

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        text: {
          primary: fontColor,
        },
      },
    },
    ptBR
  );

  useEffect(() => {
    GetHealthPlace(DominioURL()).then(() => {
      const HealthPlaceProfile = localStorage.getItem("HealthPlaceProfile");
      if (HealthPlaceProfile) {
        const HealthPlaceProfileObj = JSON.parse(
          HealthPlaceProfile
        ) as IHealthPlaceURL;
        setPrimary(HealthPlaceProfileObj.firstBackgroundColor);
        setSecondary(HealthPlaceProfileObj.secondBackgroundColor);
        setFontColor(HealthPlaceProfileObj.fontColor);
        setHealthPlaceLogo(HealthPlaceProfileObj.logo);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="*" element={<Manutencao />} /> */}
          <Route
            path="/login"
            element={
              <LoginPage urlLogo={healthPlaceLogo} primaryColor={primary} />
            }
          />
          <Route
            path="/admin/login"
            element={
              <LoginPage urlLogo={healthPlaceLogo} primaryColor={primary} />
            }
          />
          <Route
            path="/remember_password/:codeURL"
            element={
              <LoginPage urlLogo={healthPlaceLogo} primaryColor={primary} />
            }
          />
          <Route path="/login/external" element={<External />} />
          <Route path="/error" element={<Mensagem />} />
          <Route element={<TokenRoutes />}>
            <Route
              path="*"
              element={
                <LoginPage urlLogo={healthPlaceLogo} primaryColor={primary} />
              }
            />
          </Route>
          <Route path="/admin" element={<AdminRoutes />}>
            <Route
              path="/admin/gestao/empresas"
              element={<PageCompanyList />}
            />
            <Route
              path="/admin/gestao/empresas/:codeID"
              element={<PageCompanyEdit />}
            />
            <Route
              path="/admin/gestao/programas"
              element={<PageAdminProgramas />}
            />
            <Route
              path="/admin/gestao/programas/:codeID"
              element={<PageAdminProgramasEdit />}
            />
            <Route
              path="/admin/gestao/clientes"
              element={<PageCustomersHome />}
            />
            <Route
              path="/admin/gestao/clientes/new"
              element={<PageCustomerRegistration />}
            />
            <Route
              path="/admin/gestao/clientes/:customerID"
              element={<PageCustomerEdit />}
            />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<PageHome />} />
            <Route path="/medicoes" element={<HistoricalMeasurementPage />} />
            <Route path="/minha-conta" element={<MyAccountPage />} />
            <Route path="/purchase" element={<MyPurchase />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route
              path="/medicoes/detalhes/:criteriaSrId"
              element={<PageMeasurementDetails />}
            />
            <Route
              path="/medicoes/print/:criteriaSrId"
              element={<PageMeasurementPrint />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default Router;
