import { useParams } from "react-router-dom";
import DetailedMeasurementsComponent from "../../Components/Aton/Historic/Detailed";
import { useEffect, useState } from "react";
import { IUserLogado } from "../../Services/Interfaces";

function PageMeasurementPrint() {
  const { criteriaSrId } = useParams();
  const [userLogado, setUserLogado] = useState<IUserLogado>();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);
    }
  }, []);
  return (
    <>
      {criteriaSrId && userLogado !== undefined ? (
        <DetailedMeasurementsComponent
          criteriaSrId={criteriaSrId}
          print={true}
          userLogado={userLogado}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default PageMeasurementPrint;
