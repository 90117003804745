import React from "react";
import {
  customStyles,
  ModalButtonDiv,
  ModalContentA,
  ModalContentB,
  ModalContentC,
  ModalH2,
  ModalH5,
  ModalP,
  ModalUl,
} from "./styles";
import Modal from "react-modal";

interface MeasurementDetailsProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  codeMeasurement: string;
}

const MeasurementDetails: React.FC<MeasurementDetailsProps> = ({
  setIsOpen,
  modalIsOpen,
  codeMeasurement,
}) => {
  Modal.setAppElement("#root");

  function closeModal() {
    setIsOpen(false);
  }

  switch (codeMeasurement) {
    case "MSI":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>ÍNDICE DE ESTRESSE MENTAL</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalH5>Estado: Extremamente relaxado</ModalH5>
            <br />
            <ModalP>
              Um estresse bastante abaixo do normal resulta num Índice de
              Estresse Mental (ISM) muito baixo, que é ideal para manter uma boa
              saúde mental e física.
            </ModalP>

            <ModalUl>
              <li>
                Uma leitura ISM assim tão baixa sugere a possibilidade de, neste
                momento, não ter nada com que se preocupar na sua vida ou estar
                num estado de relaxamento profundo.
              </li>
              <li>
                No entanto, também pode não estar a sentir estimulação mental
                suficiente para se manter alerta em certas tarefas. Um estresse
                mínimo é útil quando se trata de atividades ou tarefas diárias.
              </li>
            </ModalUl>
            <ModalP>
              Tal como a sua frequência cardíaca em repouso pode variar
              constantemente, também é normal que os níveis de estresse mudem
              constantemente. Isto porque a sua variabilidade da frequência
              cardíaca (HRV) também está em constante mudança. Como resultado,
              não é infrequente experienciar variações nos resultados do seu
              índice de Estresse Mental em até 1,0 quando se fazem medições
              consecutivas.
            </ModalP>
            <br />
            <ModalH5>Estado: Relaxado</ModalH5>

            <ModalUl>
              <li>
                A sua leitura de estresse abaixo do normal resulta num Índice de
                Estresse Mental mais baixo (ISM) que é benéfico para a saúde
                física e psicológica a longo prazo.
              </li>

              <li>
                A receção de leituras ISM nesta zona é mais adequada para o
                ambiente doméstico ou de lazer.
              </li>

              <li>
                A capacidade de ativar este nível inferior de estímulo mental
                pode facilitar a concentração em tarefas difíceis ou pouco
                familiares.
              </li>
            </ModalUl>
            <ModalH5>Estado: Produtivo</ModalH5>
            <br />
            <ModalP>
              Atualmente, o seu stress normal resulta num Índice de Estresse
              Mental (ISM) moderado que não é considerado prejudicial para a sua
              saúde psicológica e física.
            </ModalP>
            <ModalUl>
              <li>
                A maioria das pessoas funciona de forma ideal, tendo um ISM
                dentro desta zona de estresse.
              </li>

              <li>
                No entanto, se o seu estresse permanecer dentro desta zona por
                muitos momentos do seu dia, você poderá se beneficiar da
                aprendizagem de técnicas de relaxamento, como ioga e meditação.
              </li>
            </ModalUl>
            <ModalH5>Estado: Alerta</ModalH5>
            <br />
            <ModalP>
              Está sob um nível de stress superior ao normal, resultando num
              índice de Estresse Mental (ISM) mais elevado que lhe permite
              melhorar o seu desempenho a curto prazo, aumentando a sua
              motivação para realizar tarefas mais difíceis, particularmente
              aquelas que requerem resistência ou persistência.
            </ModalP>
            <ModalUl>
              <li>
                Durante a exposição a este nível de estresse, pode sentir
                cansaço mental ou físico.
              </li>

              <li>
                Contudo, as leituras ISM nesta zona não devem ser uma parte
                constante da sua vida. Caso contrário, podem afetar
                negativamente a sua saúde física e psicológica a longo prazo.
              </li>
            </ModalUl>
            <ModalH5>Estado: Sobrecarregado</ModalH5>
            <br />
            <ModalP>
              Poderá sentir-se incapaz de se acalmar. Pare o que está a fazer e
              respire devagar e profundamente. Também deve evitar beber bebidas
              com cafeína, como café
            </ModalP>

            <ModalUl>
              <li>
                Reduzir as causas do seu estresse e envolver-se em atividades
                relaxantes, como meditação e yoga, pode ajudar a restaurar o seu
                equilíbrio mental.
              </li>

              <li>
                Esteja ciente de que os períodos prolongados de tempo passados
                com tais leituras elevadas de ISM têm o potencial de causar
                complicações de saúde, tanto físicas quanto mentais.
              </li>
            </ModalUl>
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "HR_BPM":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>FREQUÊNCIA CARDÍACA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <h4>Abaixo do intervalo normal</h4>
            <br />
            <ModalP>
              Uma frequência cardíaca anormalmente alta ou baixa em repouso pode
              ser um indicador de um problema subjacente. Consulte um médico ou
              profissional de saúde se não for um atleta treinado e a sua
              frequência cardíaca em repouso for inferior a 60 batimentos por
              minuto (bpm), especialmente se tiver outros sinais ou sintomas
              tais como desmaios, tonturas ou falta de ar.
            </ModalP>
            <br />
            <ModalP>
              A frequência cardíaca em repouso pode ser influenciada por muitos
              fatores, incluindo, entre outros, os seguintes:
            </ModalP>
            <ModalUl>
              <li>Nível de aptidão física;</li>
              <li>Idade;</li>
              <li>Doença cardiovascular;</li>
              <li>Tabagismo;</li>
              <li>Medicamentos;</li>
              <li>Posição do corpo (ex. em pé, sentado ou deitado).</li>
            </ModalUl>
            <ModalP>
              Se estiver preocupado com a sua frequência cardíaca ou se tiver
              mais dúvidas, realize uma consulta médica para mais informações.
            </ModalP>
            <br />
            <ModalP>
              Para mais informações acerca da frequência cardíaca, consulte:
            </ModalP>
            <ModalUl>
              <li>“What's a normal resting heart rate?" da Mayo Clinic</li>
              <li>
                "Know Your Target Heart Rates for Exercise, Losing Weight and
                Health"
              </li>
              <li>
                "All About Heart Rate (Pulse)» da American Heart Association.
              </li>
            </ModalUl>
            <h4>Intervalo normal</h4>
            <br />
            <ModalP>
              Um intervalo de 60 a 100 batimentos por minuto (bpm) é considerado
              um intervalo normal para a frequência cardíaca em repouso de um
              adulto.
            </ModalP>
            <br />
            <ModalP>
              A frequência cardíaca em repouso pode ser influenciada por muitos
              fatores, incluindo, entre outros, os seguintes:
            </ModalP>
            <ModalUl>
              <li>Nível de aptidão física;</li>
              <li>Idade;</li>
              <li>Doença cardiovascular;</li>
              <li>Tabagismo;</li>
              <li>Medicamentos;</li>
              <li>Posição do corpo (ex. em pé, sentado ou deitado).</li>
            </ModalUl>
            <ModalP>
              No geral, uma frequência cardíaca em repouso mais baixa é
              indicativa de uma função cardíaca mais eficiente e de uma melhor
              aptidão cardiovascular. Os atletas podem ter uma frequência
              cardíaca em repouso normal abaixo dos 60 bpm.
            </ModalP>
            <br />
            <ModalP>
              Se estiver preocupado com a sua frequência cardíaca ou se tiver
              mais dúvidas, realize uma consulta médica para mais informações.
            </ModalP>
            <br />
            <ModalP>
              Para mais informações acerca da frequência cardíaca, consulte:
            </ModalP>
            <ModalUl>
              <li>“What's a normal resting heart rate?" da Mayo Clinic</li>
              <li>
                "Know Your Target Heart Rates for Exercise, Losing Weight and
                Health"
              </li>
              <li>
                "All About Heart Rate (Pulse)» da American Heart Association.
              </li>
            </ModalUl>
            <h4>Acima do intervalo normal</h4>
            <br />
            <ModalP>
              Uma frequência cardíaca anormalmente alta ou baixa em repouso pode
              ser um indicador de um problema subjacente. Consulte um médico ou
              profissional de saúde se a sua frequência cardíaca estiver
              consistentemente acima dos 100 batimentos por minuto (bpm),
              especialmente se tiver outros sinais ou sintomas tais como
              desmaios, tonturas ou falta de ar.
            </ModalP>
            <br />
            <ModalP>
              A frequência cardíaca em repouso pode ser influenciada por muitos
              fatores, incluindo, entre outros, os seguintes:
            </ModalP>
            <ModalUl>
              <li>Nível de aptidão física;</li>
              <li>Idade;</li>
              <li>Doença cardiovascular;</li>
              <li>Tabagismo;</li>
              <li>Medicamentos;</li>
              <li>Posição do corpo (ex. em pé, sentado ou deitado).</li>
            </ModalUl>
            <ModalP>
              Se estiver preocupado com a sua frequência cardíaca ou se tiver
              mais dúvidas, realize uma consulta médica para mais informações.
            </ModalP>
            <br />
            <ModalP>
              Para mais informações acerca da frequência cardíaca, consulte:
            </ModalP>
            <ModalUl>
              <li>“What's a normal resting heart rate?" da Mayo Clinic</li>
              <li>
                "Know Your Target Heart Rates for Exercise, Losing Weight and
                Health"
              </li>
              <li>
                "All About Heart Rate (Pulse)» da American Heart Association.
              </li>
            </ModalUl>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "BR_BPM":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>RESPIRAÇÃO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A frequência respiratória normal em repouso para adultos situa-se
              entre as 12 e as 25 respirações por minuto. Uma frequência
              respiratória mais lenta pode ser causada por determinados
              medicamentos ou pode sugerir um problema médico subjacente como,
              por exemplo, hipotireoidismo, doenças cardíacas, desequilíbrio
              eletrolítico, etc. Uma frequência respiratória mais acelerada pode
              ter várias causas, incluindo andar em passo acelerado ou fazer
              exercício, asma, ansiedade, infecção, ou problema cardiovascular.
            </ModalP>
            <br />
            <ModalP>
              Consulte o seu médico se a sua frequência respiratória se
              encontrar fora do intervalo normal.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "BMI_CALC":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>ÍNDICE DE MASSA CORPORAL</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Índice de Massa Corporal (IMC) é uma medição da massa do tecido
              de um indivíduo (músculo, gordura e osso) ajustada à sua altura. É
              um indicador comumente utilizado da gordura corporal total e serve
              como ferramenta para categorizar os indivíduos como sendo abaixo
              do peso, peso normal, excesso de peso, ou obesos com base nos
              riscos para a saúde.
            </ModalP>
            <br />
            <ModalP>
              O Índice de Massa Corporal é um bom indicador de doenças
              cardiovasculares, diabetes tipo 2, entre outros. É um dos melhores
              indicadores antropométricos de lipoproteínas de alta densidade
              (HDL) e triglicérides; estes são fatores importantes para as
              doenças cardiometabólicas. Também é um dos melhores indicadores
              antropométricos dos níveis de ácido úrico (níveis elevados de
              ácido úrico contribuem para o aparecimento de cálculos biliares).
            </ModalP>
            <br />
            <ModalP>
              Uma das limitações do Índice de Massa Corporal é que não é
              possível distinguir-se gordura de massa muscular e por isso pode
              ser impreciso em indivíduos com mais músculos.
            </ModalP>
            <br />
            <ModalP>
              Para calcular o Índice de Massa Corporal utilize a seguinte
              fórmula: IMC = peso (kg) / altura (m²)
            </ModalP>
            <br />
            <h4>De acordo com a Organização Mundial de Saúde:</h4>
            <br />
            <table>
              <tbody>
                <tr>
                  <td>IMC</td>
                  <td>Condição do peso</td>
                </tr>
                <tr>
                  <td>Inferior a 18,5</td>
                  <td>Abaixo do peso</td>
                </tr>
                <tr>
                  <td>18,5 – 24,9</td>
                  <td>Peso normal</td>
                </tr>
                <tr>
                  <td>25,0 – 29,9</td>
                  <td>Pré-obesidade</td>
                </tr>
                <tr>
                  <td>30,0 – 34,9</td>
                  <td>Obesidade de classe I</td>
                </tr>
                <tr>
                  <td>35,0 – 39,9</td>
                  <td>Obesidade de classe II</td>
                </tr>
                <tr>
                  <td>40 ou superior</td>

                  <td>Obesidade de classe III</td>
                </tr>
              </tbody>
            </table>
            <br />
            <ModalP>
              Para mais informações, consultar: Zhou, W., Wang, Y., Gu, X.,
              Feng, Z. P., Lee, K., Peng, Y., & Barszczyk, A. (2020) Importance
              of general adiposity, visceral adiposity and vital signs in
              predicting blood biomarkers using machine learning. International
              Journal of Clinical Practice, e13664.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "AGE":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>IDADE DA PELE DO ROSTO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A Idade da Pele é uma estimativa da sua idade com base no estado
              da superfície do seu rosto.
            </ModalP>
            <br />
            <ModalP>
              Esta estimativa pode ser utilizada como um indicador do
              envelhecimento da pele do seu rosto.
            </ModalP>
            <br />
            <ModalP>
              Esta estimativa pode ser influenciada por muitos fatores, tais
              como nível de fadiga, utilização de produtos para o cuidado de
              pele ou cosméticos.
            </ModalP>
            <br />
            <ModalP>
              Imagens de vídeo captadas em condições de má iluminação (ex.
              contraluz, iluminação sobre a cabeça) podem distorcer a estimativa
              da sua idade da pele do seu rosto.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "WAIST_TO_HEIGHT":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>RELAÇÃO CINTURA/ALTURA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A razão cintura-estatura é a circunferência da cintura de uma
              pessoa, expressa como porcentagem de sua altura (utilizando a
              mesma unidade de medida). Para uma pessoa média, o dispositivo
              Anura™ estimará que ela fique em até 4 pontos percentuais acima ou
              abaixo de seu valor real.
            </ModalP>
            <br />
            <ModalP>
              A relação cintura/altura é um indicador particularmente bom do
              risco de doenças cardiovasculares. Está associada a fatores de
              risco de doenças cardiovasculares como colesterol total elevado e
              lipoproteína de alta densidade mais baixa (HDL).
            </ModalP>
            <br />
            <ModalP>
              Em média, o Anura™ estimará sua relação cintura-altura em 4 pontos
              percentuais de seu valor real.
            </ModalP>
            <br />
            <ModalP>
              Para mais informações, consultar: Zhou, W., Wang, Y., Gu, X.,
              Feng, Z. P., Lee, K., Peng, Y., & Barszczyk, A. (2020) Importance
              of general adiposity, visceral adiposity and vital signs in
              predicting blood biomarkers using machine learning. International
              Journal of Clinical Practice, e13664.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "ABSI":
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>ÍNDICE DE PERFIL CORPORAL</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Índice de Perfil Corporal é uma medição do tamanho da região
              abdominal (conforme determinada pela circunferência da cintura) em
              relação ao tamanho total do corpo (conforme determinado pelo
              Índice de Massa Corporal e pela altura).
            </ModalP>
            <br />
            <ModalP>
              Um baixo Índice de Perfil Corporal corresponde a um corpo com uma
              forma de malagueta. À medida que o IPC aumenta, a forma do corpo
              parece-se com uma pera, depois como um abacaxi, depois como uma
              maçã. Uma região abdominal maior sugere níveis elevados de gordura
              abdominal profunda, que está associada a muitos riscos de saúde.
            </ModalP>
            <br />
            <ModalP>
              O Índice de Perfil Corporal é um indicador particularmente bom de
              mortalidade (de todas as causas), assim como um bom preditor de
              diabetes e de doenças cardiovasculares. Está associado a níveis
              elevados de triglicerídeos, que podem contribuir parcialmente para
              o desenvolvimento de doenças cardiovasculares.
            </ModalP>
            <br />
            <ModalP>
              Para mais informações, consultar: Zhou, W., Wang, Y., Gu, X.,
              Feng, Z. P., Lee, K., Peng, Y., & Barszczyk, A. (2020) Importance
              of general adiposity, visceral adiposity and vital signs in
              predicting blood biomarkers using machine learning. International
              Journal of Clinical Practice, e13664.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    case "BP_SYSTOLIC": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>PRESSÃO SISTÓLICA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pressão arterial é uma medida relevante da velocidade com que o
              sangue é conduzido pelas artérias do nosso organismo. É composta
              por duas variáveis: a pressão arterial sistólica e a pressão
              arterial diastólica.
            </ModalP>
            <br />
            <ModalP>
              A pressão arterial sistólica é o primeiro valor da medida e
              representa a pressão nas artérias quando o coração esta no
              processo de contração e bombeamento do sangue para o corpo. Essa
              pressão indica o quanto de pressão está sendo exercido nas paredes
              das artérias durante a contração do coração.
            </ModalP>
            <br />
            <ModalP>
              Já a pressão arterial diastólica é o segundo valor da medida e
              representa a pressão nas artérias quando o coração está relaxado,
              entre as batidas. Essa pressão indica o quanto de pressão está
              presente nas artérias durante esse período de repouso do coração.
            </ModalP>
            <br />
            <ModalP>
              Quando a pressão arterial sistólica e/ou diastólica estão fora dos
              níveis considerados saudáveis, podem ocorrer problemas de saúde.
            </ModalP>
            <br />
            <ModalP>
              A hipertensão arterial é uma doença em que a pressão arterial está
              constantemente elevada, elevando o risco de doenças cardíacas,
              acidente vascular cerebral (AVC), doenças renais, oftalmológicas e
              outros problemas de saúde.
            </ModalP>
            <br />
            <ModalP>
              Se estiver preocupado com a sua frequência cardíaca ou se tiver
              mais dúvidas, realize uma consulta médica para mais informações.
            </ModalP>
            <br />
            <ModalP>Bibliografia:</ModalP>
            <ModalP>
              <a href="https://www.heart.org">https://www.heart.org</a>
            </ModalP>
            <ModalP>
              <a href="https://www.who.int">https://www.who.int</a>
            </ModalP>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_DIASTOLIC": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>PRESSÃO DIASTÓLICA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pressão arterial é uma medida relevante da velocidade com que o
              sangue é conduzido pelas artérias do nosso organismo. É composta
              por duas variáveis: a pressão arterial sistólica e a pressão
              arterial diastólica.
            </ModalP>
            <br />
            <ModalP>
              A pressão arterial sistólica é o primeiro valor da medida e
              representa a pressão nas artérias quando o coração esta no
              processo de contração e bombeamento do sangue para o corpo. Essa
              pressão indica o quanto de pressão está sendo exercido nas paredes
              das artérias durante a contração do coração.
            </ModalP>
            <br />
            <ModalP>
              Já a pressão arterial diastólica é o segundo valor da medida e
              representa a pressão nas artérias quando o coração está relaxado,
              entre as batidas. Essa pressão indica o quanto de pressão está
              presente nas artérias durante esse período de repouso do coração.
            </ModalP>
            <br />
            <ModalP>
              Quando a pressão arterial sistólica e/ou diastólica estão fora dos
              níveis considerados saudáveis, podem ocorrer problemas de saúde.
            </ModalP>
            <br />
            <ModalP>
              A hipertensão arterial é uma doença em que a pressão arterial está
              constantemente elevada, elevando o risco de doenças cardíacas,
              acidente vascular cerebral (AVC), doenças renais, oftalmológicas e
              outros problemas de saúde.
            </ModalP>
            <br />
            <ModalP>
              Se estiver preocupado com a sua frequência cardíaca ou se tiver
              mais dúvidas, realize uma consulta médica para mais informações.
            </ModalP>
            <br />
            <ModalP>Bibliografia:</ModalP>
            <ModalP>
              <a href="https://www.heart.org">https://www.heart.org</a>
            </ModalP>
            <ModalP>
              <a href="https://www.who.int">https://www.who.int</a>
            </ModalP>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_STROKE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>RISCO DE AVC</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco de AVC é a probabilidade de o sujeito sofrer o primeiro
              AVC nos próximos 10 anos, expresso em percentagem. BP_STROKE é a
              estimativa da DeepAffex Cloud do risco de acidente vascular
              cerebral do sujeito . Esta pontuação de risco considera o sexo
              biológico do sujeito ao nascer, idade, Índice de Massa Corporal ,
              pressão arterial sistólica e status de medicação
              anti-hipertensiva, bem como seu status de tabagismo e diabetes,
              quando disponíveis. Foi desenvolvido utilizando um conceito
              derivado do método Framingham. Baseia-se em dados de estudos
              prospectivos que acompanharam a saúde cardiovascular dos
              participantes durante mais de 10 anos.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_CVD": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>RISCO DOENÇA VASCULAR</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco de doença cardiovascular é a probabilidade de o sujeito
              sofrer o primeiro ataque cardíaco ou acidente vascular cerebral
              nos próximos 10 anos, expresso como uma porcentagem. BP_CVD é a
              estimativa da DeepAffex Cloud do risco de doença cardiovascular do
              sujeito. Esta pontuação de risco considera o sexo biológico do
              sujeito ao nascer, idade, Índice de Massa Corporal , pressão
              arterial sistólica e status de medicação anti-hipertensiva, bem
              como seu status de tabagismo e diabetes, quando disponíveis. Foi
              desenvolvido utilizando um conceito derivado do método Framingham.
              Baseia-se em dados de estudos prospectivos que acompanharam a
              saúde cardiovascular dos participantes durante mais de 10 anos
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_TAU": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>CAPACIDADE VASCULAR</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              BP_TAU é a estimativa da DeepAffex Cloud da capacidade vascular do
              sujeito . Capacidade Vascular ou Tau , é uma medida da
              elasticidade dos vasos sanguíneos do sujeito. Quando medido em
              repouso, este índice pode ser utilizado como um indicador de saúde
              cardiovascular, pois está fortemente correlacionado com a rigidez
              vascular. Uma pessoa com Tau alto tem melhor saúde vascular em
              comparação com uma pessoa com Tau baixo. Além disso, certas
              atividades transitórias e eventos fisiológicos podem levar a
              alterações imediatas na Tau (por exemplo, consumo de álcool,
              fumo).
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_HEART_ATTACK": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>RISCO ATAQUE CARDÍACO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco de ataque cardíaco é a probabilidade do sujeito sofrer seu
              primeiro ataque cardíaco nos próximos 10 anos, expresso como uma
              porcentagem. BP_HEART_ATTACK é a estimativa da DeepAffex Cloud do
              risco de ataque cardíaco do sujeito . Esta pontuação de risco
              considera o sexo biológico do sujeito ao nascer, idade, Índice de
              Massa Corporal , pressão arterial sistólica e status de medicação
              anti-hipertensiva, bem como seu status de tabagismo e diabetes,
              quando disponíveis. Foi desenvolvido utilizando um conceito
              derivado do método Framingham. Baseia-se em dados de estudos
              prospectivos que acompanharam a saúde cardiovascular dos
              participantes durante mais de 10 anos.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "BP_RPP": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>CARGA DE TRABALHO CARDÍACA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              BP_RPP é a estimativa da DeepAffex Cloud da carga de trabalho
              cardíaca do sujeito . É calculado usando a fórmula: Frequência de
              pulso × Pressão arterial sistólica. A carga de trabalho cardíaca ,
              ou mais precisamente a carga de trabalho miocárdica, é uma medida
              do estresse exercido sobre o músculo cardíaco. Quando medido em
              repouso, esse índice pode ser utilizado como indicador de saúde
              cardiovascular. Por exemplo, uma pessoa que se exercita
              regularmente pode ter uma Carga de Trabalho Cardíaca menor do que
              outra pessoa que vive um estilo de vida sedentário. Durante um
              exercício físico intenso, a Carga de Trabalho Cardíaca do sujeito
              pode aumentar, mas deve diminuir após o exercício.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "MENTAL_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>SCORE MENTAL</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pontuação geral de bem-estar, retornada no ponto DFX,
              HEALTH_SCORE é uma medida da saúde geral do sujeito. As seguintes
              pontuações de componentes adicionais também são retornadas:
              Pontuação Mental NuraLogix™: Medida de saúde mental. Pontuação
              Física NuraLogix™: Medida de saúde física. Pontuação Fisiológica
              NuraLogix™: Medida da saúde fisiológica. Pontuação de riscos
              NuraLogix™: medida de riscos à saúde. NuraLogix™ Vitals Score:
              medida de saúde com base em sinais vitais (pulsação, pressão
              arterial, etc.) Quanto maior a pontuação geral de bem-estar do
              NuraLogix™ do sujeito , melhor será a saúde do sujeito. As outras
              pontuações dos componentes individuais contribuem para o cálculo
              de HEALTH_SCORE.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "PHYSICAL_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>SCORE FÍSICO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              Score Fisico – Eh uma medida de massa tecidual ( musculo, gordura
              corpórea , ossos )ajustado para altura , e serve para categorizar
              a pessoa com peso abaixo do normal , sobrepeso , obesidade com
              base no risco para saúde.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "PHYSIO_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>SCORE PSICOLÓGICO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pontuação geral de bem-estar, retornada no ponto DFX,
              HEALTH_SCORE é uma medida da saúde geral do sujeito. As seguintes
              pontuações de componentes adicionais também são retornadas:
              Pontuação Mental NuraLogix™: Medida de saúde mental. Pontuação
              Física NuraLogix™: Medida de saúde física. Pontuação Fisiológica
              NuraLogix™: Medida da saúde fisiológica. Pontuação de riscos
              NuraLogix™: medida de riscos à saúde. NuraLogix™ Vitals Score:
              medida de saúde com base em sinais vitais (pulsação, pressão
              arterial, etc.) Quanto maior a pontuação geral de bem-estar do
              NuraLogix™ do sujeito , melhor será a saúde do sujeito. As outras
              pontuações dos componentes individuais contribuem para o cálculo
              de HEALTH_SCORE.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "VITAL_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>SCORE VITAL</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pontuação geral de bem-estar, retornada no ponto DFX,
              HEALTH_SCORE é uma medida da saúde geral do sujeito. As seguintes
              pontuações de componentes adicionais também são retornadas:
              Pontuação Mental NuraLogix™: Medida de saúde mental. Pontuação
              Física NuraLogix™: Medida de saúde física. Pontuação Fisiológica
              NuraLogix™: Medida da saúde fisiológica. Pontuação de riscos
              NuraLogix™: medida de riscos à saúde. NuraLogix™ Vitals Score:
              medida de saúde com base em sinais vitais (pulsação, pressão
              arterial, etc.) Quanto maior a pontuação geral de bem-estar do
              NuraLogix™ do sujeito , melhor será a saúde do sujeito. As outras
              pontuações dos componentes individuais contribuem para o cálculo
              de HEALTH_SCORE.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "RISKS_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>SCORE DE RISCO</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pontuação geral de bem-estar, retornada no ponto DFX,
              HEALTH_SCORE é uma medida da saúde geral do sujeito. As seguintes
              pontuações de componentes adicionais também são retornadas:
              Pontuação Mental NuraLogix™: Medida de saúde mental. Pontuação
              Física NuraLogix™: Medida de saúde física. Pontuação Fisiológica
              NuraLogix™: Medida da saúde fisiológica. Pontuação de riscos
              NuraLogix™: medida de riscos à saúde. NuraLogix™ Vitals Score:
              medida de saúde com base em sinais vitais (pulsação, pressão
              arterial, etc.) Quanto maior a pontuação geral de bem-estar do
              NuraLogix™ do sujeito , melhor será a saúde do sujeito. As outras
              pontuações dos componentes individuais contribuem para o cálculo
              de HEALTH_SCORE.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HEALTH_SCORE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Bem-estar Geral</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              A pontuação geral de bem-estar, retornada no ponto DFX,
              HEALTH_SCORE é uma medida da saúde geral do sujeito. As seguintes
              pontuações de componentes adicionais também são retornadas:
              Pontuação Mental NuraLogix™: Medida de saúde mental. Pontuação
              Física NuraLogix™: Medida de saúde física. Pontuação Fisiológica
              NuraLogix™: Medida da saúde fisiológica. Pontuação de riscos
              NuraLogix™: medida de riscos à saúde. NuraLogix™ Vitals Score:
              medida de saúde com base em sinais vitais (pulsação, pressão
              arterial, etc.) Quanto maior a pontuação geral de bem-estar do
              NuraLogix™ do sujeito , melhor será a saúde do sujeito. As outras
              pontuações dos componentes individuais contribuem para o cálculo
              de HEALTH_SCORE.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HEIGTH": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>ALTURA</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              HEIGHT é a estimativa do DeepAffex Cloud da altura do sujeito em
              cm. Não há tabela de interpretação para HEIGHT.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HRV_SDNN": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Variabilidade da Frequência Cardíaca</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              HRV_SDNN é a estimativa da DeepAffex Cloud da variabilidade da
              frequência cardíaca do sujeito . A variabilidade da frequência
              cardíaca refere-se à variabilidade no tempo entre um batimento
              cardíaco e o seguinte. DeepAffex™ mede isso com SDNN, que é o
              desvio padrão do intervalo entre batimentos cardíacos normais (em
              milissegundos). Um SDNN mais alto corresponde a mais variabilidade
              entre batimentos. O aumento da variabilidade da frequência
              cardíaca sugere um aumento na atividade parassimpática e/ou uma
              diminuição na atividade simpática do sistema nervoso autônomo. A
              alta variabilidade da frequência cardíaca em repouso é considerada
              saudável e significa que o coração é mais flexível às mudanças nas
              necessidades do corpo.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "WAIST_CIRCUM": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Circunferência da Cintura</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              WAIST_CIRCUM é a estimativa do DeepAffex Cloud da circunferência
              da cintura do sujeito em cm Não há tabela de interpretação para
              WAIST_CIRCUM. Informações do perfil do sujeito (idade, sexo
              biológico ao nascer, altura, peso)
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "DBT_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco de diabetes tipo 2</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Risco de Diabetes Tipo 2 corresponde à percentagem de pessoas
              com o perfil de risco do sujeito que são diagnosticadas com
              Diabetes Tipo 2 pelo seu médico. DBT_RISK_PROB é a estimativa da
              DeepAffex Cloud do risco de diabetes tipo 2 do sujeito. Este
              perfil de risco é baseado no fluxo sanguíneo facial (TOI) e nas
              informações do perfil do sujeito. Não reflete necessariamente o
              nível atual de açúcar no sangue do usuário. O diabetes tipo 2 não
              controlado pode causar problemas como doenças vasculares, doenças
              cardíacas, danos renais e nervosos. Portanto, aqueles com alto
              risco de diabetes tipo 2 devem considerar fazer um exame médico.
              Aviso: Flutuações de momento a momento na fisiologia do sujeito
              significam que suas estimativas de risco podem variar até certo
              ponto de uma medição para outra. A melhor estimativa do risco
              global do sujeito é, portanto, obtida através da média de várias
              medições ao longo do dia e ao longo de vários dias para ter em
              conta adequadamente esta variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "FLD_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>
              Probabilidade de risco de doença hepática gordurosa
            </ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Risco de Doença Hepática Gordurosa corresponde ao percentual de
              pessoas com perfil de risco do sujeito que são diagnosticadas com
              doença hepática gordurosa. Quando o acúmulo de gordura no fígado
              ultrapassa 5% de sua massa total, ele é classificado como fígado
              gorduroso. FLD_RISK_PROB é a estimativa da DeepAffex Cloud do
              risco de doença hepática gordurosa do sujeito. A doença hepática
              gordurosa é caracterizada pelo acúmulo de triglicerídeos lipídicos
              nas células do fígado, levando a um aumento anormal no tamanho do
              fígado. Seu desenvolvimento envolve múltiplos fatores
              contribuintes. Estes incluem o consumo excessivo de álcool, que
              prejudica a função hepática e promove a retenção de ácidos graxos;
              síndrome metabólica, caracterizada por obesidade e resistência à
              insulina, que leva ao acúmulo de ácidos graxos no fígado; e
              fatores dietéticos, como a alta ingestão de frutose, que contribui
              para o armazenamento excessivo de gordura nas células do fígado.
              Esses fatores desempenham coletivamente um papel no
              desenvolvimento e progressão da doença hepática gordurosa.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HBA1C_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco de hemoglobina A1C</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Risco de Hemoglobina A1C corresponde ao percentual de pessoas
              com perfil de risco dos sujeitos que apresentaram níveis de HbA1c
              acima de 5,7% quando testados, indicando risco elevado de
              pré-diabetes. HBA1C_RISK_PROBé a estimativa da DeepAffex Cloud do
              risco de hemoglobina A1C do sujeito. Este perfil de risco é
              baseado no fluxo sanguíneo facial (TOI) e nas informações do
              perfil do sujeito. A HbA1c ou hemoglobina glicada é formada quando
              a glicose da corrente sanguínea se combina com a hemoglobina nos
              glóbulos vermelhos. O teste de HbA1c é um exame de sangue
              laboratorial solicitado pelo médico do sujeito. Ele estima o nível
              médio de glicose no sangue durante os últimos 2–3 meses, medindo a
              quantidade de hemoglobina glicada A1c no sangue de uma pessoa.
              Quanto maior o percentual de A1c, maiores serão os níveis de
              glicose no sangue, auxiliando assim no diagnóstico do Diabetes
              Tipo 2. Existem outras condições e estados fisiológicos além do
              diabetes que podem alterar os valores de HbA1c. Portanto, é
              importante que o sujeito discuta o significado de suas leituras
              com seu médico e determine se é necessária uma avaliação
              adicional. Aviso: Flutuações de momento a momento na fisiologia do
              sujeito significam que suas estimativas de risco podem variar até
              certo ponto de uma medição para outra. A melhor estimativa do
              risco global do sujeito é, portanto, obtida através da média de
              várias medições ao longo do dia e ao longo de vários dias para ter
              em conta adequadamente esta variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HDLTC_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Risco de hipercolesterolemia</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco de hipercolesterolemia corresponde à porcentagem de
              pessoas com o perfil de risco dos indivíduos que apresentam níveis
              de colesterol anormalmente elevados (definidos como uma proporção
              de colesterol total (CT) para colesterol de lipoproteína de alta
              densidade (HDL) ("colesterol bom") de 4,1 ou superior) .
              HDLTC_RISK_PROB é a estimativa da DeepAffex Cloud do risco de
              hipercolesterolemia do sujeito Este perfil de risco é baseado no
              fluxo sanguíneo facial (TOI) e nas informações do perfil do
              sujeito. A hipercolesterolemia é um fator de risco tratável para
              doenças cardiovasculares. Portanto, aqueles com alto risco de
              hipercolesterolemia devem conversar com seu médico sobre a
              verificação dos níveis de HDL e colesterol total com um exame de
              sangue. Aviso: Flutuações de momento a momento na fisiologia do
              sujeito significam que suas estimativas de risco podem variar até
              certo ponto de uma medição para outra. A melhor estimativa do
              risco global do sujeito é, portanto, obtida através da média de
              várias medições ao longo do dia e ao longo de vários dias para ter
              em conta adequadamente esta variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "HPT_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco de hipertensão</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Risco de Hipertensão corresponde ao percentual de pessoas com
              perfil de risco do sujeito que são diagnosticadas com hipertensão
              (pressão alta) pelo seu médico. HPT_RISK_PROBé a estimativa da
              DeepAffex Cloud do risco de hipertensão do sujeito. Este perfil de
              risco é baseado no fluxo sanguíneo facial (TOI) e nas informações
              do perfil do sujeito. A hipertensão é um importante fator de risco
              tratável para doenças cardiovasculares. Portanto, aqueles com alto
              risco de hipertensão devem considerar fazer um exame de
              hipertensão pelo seu médico. Aviso: Flutuações de momento a
              momento na fisiologia do sujeito significam que suas estimativas
              de risco podem variar até certo ponto de uma medição para outra. A
              melhor estimativa do risco global do sujeito é, portanto, obtida
              através da média de várias medições ao longo do dia e ao longo de
              vários dias para ter em conta adequadamente esta variação
              fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "MFBG_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco de glicemia de jejum</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco de glicemia em jejum corresponde à porcentagem de pessoas
              com perfil de risco dos sujeitos que apresentaram níveis de
              glicemia acima de 5,5 mmol/L quando testados após 8 a 10 horas de
              jejum, indicando alto risco de pré-diabetes. MFBG_RISK_PROB é a
              estimativa da DeepAffex Cloud do risco de glicemia em jejum do
              sujeito. Este perfil de risco é baseado no fluxo sanguíneo facial
              (TOI) do sujeito e nas informações de seu perfil. O teste de
              glicemia em jejum (FBG) é um exame de sangue laboratorial
              solicitado por um profissional de saúde, realizado após 8 a 10
              horas de jejum. Este exame de sangue é a maneira mais precisa de
              determinar o resultado da glicemia. A FBG também pode ser
              determinada usando glicosímetros de venda livre. Esses
              glicosímetros podem produzir resultados de FBG que variam em
              precisão em até +-15%. Existem outras condições e estados
              fisiológicos além do diabetes que podem alterar os resultados da
              FBG. Portanto, é importante que o sujeito discuta o significado de
              suas leituras com seu médico e determine se é necessária uma
              avaliação adicional. Aviso: Flutuações de momento a momento na
              fisiologia do sujeito significam que suas estimativas de risco
              podem variar até certo ponto de uma medição para outra. A melhor
              estimativa do risco global do sujeito é, portanto, obtida através
              da média de várias medições ao longo do dia e ao longo de vários
              dias para ter em conta adequadamente esta variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "OVERALL_METABOLIC_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco metabólico geral</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O risco metabólico geral para a saúde é a estimativa da DeepAffex
              Cloud do risco metabólico geral para a saúde do sujeito. Oferece
              uma avaliação abrangente da suscetibilidade de um indivíduo a um
              espectro de condições metabólicas. Ele utiliza informações de
              medições de fluxo sanguíneo facial (TOI) e dados sobre o perfil do
              indivíduo para medir e estimar a probabilidade de ocorrência de
              certos problemas de saúde. A combinação dos riscos descritos –
              risco de hipertensão, risco de diabetes tipo 2, risco de
              hipertrigliceridemia, risco de hipercolesterolemia e risco de
              doença hepática gordurosa – forma uma imagem abrangente da saúde
              metabólica de um indivíduo. Avaliados através de medições do fluxo
              sanguíneo facial (TOI) e dos dados do perfil do sujeito, estes
              riscos destacam a complexa sobreposição entre o bem-estar
              metabólico e a saúde cardiovascular. O impacto combinado destes
              riscos enfatiza a necessidade de tomar medidas proativas na gestão
              da saúde. O potencial surgimento de hipertensão, diabetes tipo 2,
              níveis elevados de triglicerídeos e colesterol ou doença hepática
              gordurosa enfatiza a importância de adotar hábitos mais saudáveis
              e buscar orientação de profissionais. O impacto combinado destes
              riscos tem uma influência mais ampla que atinge a área de
              potenciais problemas e distúrbios relacionados com o coração que
              afetam os vasos sanguíneos, que podem incluir uma série de doenças
              cardiovasculares. Aviso: Flutuações de momento a momento na
              fisiologia do sujeito significam que suas estimativas de risco
              podem variar até certo ponto de uma medição para outra. Portanto,
              a melhor estimativa do risco global do sujeito é obtida através da
              média de várias medições ao longo do dia e ao longo de vários dias
              para contabilizar adequadamente esta variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "SURVEY_DEPRESSION_MODERATE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Avaliacao rápida de depressão moderada</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O SURVEY_DEPRESSION_MODERATEcorresponde à probabilidade percentual
              de o usuário ser classificado como tendo depressão moderada de
              acordo com o questionário DASS-42 versão longa. Um pequeno
              questionário permite uma avaliação rápida da depressão em adultos.
              É derivado da versão longa do questionário DASS-42 (Depression
              Anxiety Stress Scale-42) que é usado para determinar a presença de
              depressão de nível moderado na clínica. Os usuários que obtiverem
              uma probabilidade superior a 50% podem desejar consultar um
              profissional de saúde para uma avaliação clínica de seu estado de
              depressão.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "SURVEY_ANXIETY_MODERATE": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Avaliação rápida de ansiedade moderada</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O SURVEY_ANXIETY_MODERATE corresponde à probabilidade percentual
              de o usuário ser classificado como tendo ansiedade moderada de
              acordo com o questionário DASS-42 versão longa. Um pequeno
              questionário permite uma avaliação rápida da ansiedade em adultos.
              É derivado da versão longa do questionário DASS-42 (Depression
              Anxiety Stress Scale-42) que é usado para determinar a presença de
              ansiedade de nível moderado na clínica. Os usuários que obtiverem
              uma probabilidade superior a 50% podem desejar consultar um
              profissional de saúde para uma avaliação clínica de seu estado de
              ansiedade.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    case "TG_RISK_PROB": {
      return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Detalhes da Medição"
        >
          <ModalContentA>
            <ModalH2>Probabilidade de risco de Hipertrigliceridemia</ModalH2>
          </ModalContentA>
          <ModalContentB>
            <br />
            <ModalP>
              O Risco de Hipertrigliceridemia corresponde à porcentagem de
              pessoas com perfil de risco dos sujeitos que apresentam níveis de
              triglicerídeos anormalmente elevados (acima de 1,7 mmol/L ou 150
              mg/dL). TG_RISK_PROBé a estimativa da DeepAffex Cloud do risco de
              hipertrigliceridemia do sujeito . Este perfil de risco é baseado
              no fluxo sanguíneo facial (TOI) e nas informações do perfil do
              sujeito. A hipertrigliceridemia em combinação com outros fatores é
              um fator de risco tratável para doenças cardiovasculares.
              Portanto, aqueles com alto risco de hipertrigliceridemia devem
              conversar com seu médico sobre a verificação dos níveis de
              triglicerídeos com um exame de sangue. Aviso: Flutuações de
              momento a momento na fisiologia do sujeito significam que suas
              estimativas de risco podem variar até certo ponto de uma medição
              para outra. A melhor estimativa do risco global do sujeito é,
              portanto, obtida através da média de várias medições ao longo do
              dia e ao longo de vários dias para ter em conta adequadamente esta
              variação fisiológica.
            </ModalP>
            <br />
            <ModalH5>
              Apenas para uso investigativo. Este conteúdo é apenas para fins
              informativos e não é um substituto da avaliação clínica por parte
              de um profissional de saúde. É apenas destinada a melhorar a
              percepção bem-estar geral.
            </ModalH5>
            <br />
            <ModalP>
              Revisado e aprovado por: Dr. Michel Daud Filho / CRM-50217
            </ModalP>
            <ModalContentC>
              <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
            </ModalContentC>
          </ModalContentB>
        </Modal>
      );
    }
    default:
      return <div></div>;
  }
};

export default MeasurementDetails;
