import { CircularProgress } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DominioURL } from "../../../Components/Auth/isAuthenticated";
import PageHeader from "../../../Components/Header/Auth";
import {
  checkCode,
  loginCheck,
  rememberPassword,
  UserRememberPassword,
} from "../../../Services/Auth";
import {
  LoginA,
  LoginButton,
  LoginContent,
  LoginContentA,
  LoginContentButton,
  LoginDiv,
  LoginFormControl,
  DivFormLogin,
  LoginH2,
  LoginIconButton,
  LoginInputAdornment,
  LoginInputLabel,
  LoginInputMensagem,
  LoginLogoContent,
  LoginOutlinedInput,
  LoginPBlack,
} from "../stylesAuth";
import { cpfMask } from "../../../utils/conversions";

interface LoginProps {
  urlLogo: string;
  primaryColor: string;
}

const Login: React.FC<LoginProps> = ({ urlLogo, primaryColor }) => {
  const { codeURL } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [forgot, setForgot] = useState<boolean>(false);
  const [forgotOrPassword, setForgotOrPassword] = useState<boolean>(
    codeURL ? true : false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [showPasswordRepeatForgot, setShowPasswordForgot] = useState(false);
  const [codeCheck, SetCodeCheck] = useState(codeURL ? true : false);
  const [code, setCode] = useState<string>(codeURL ? codeURL : "");
  const [healthPlaceLogo, setHealthPlaceLogo] = useState<string>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordRepeat = () =>
    setShowPasswordRepeat((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const login = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    loginCheck(username.replace(/\D/g, ''), password, DominioURL())
      .then((res) => {
        const localUser = localStorage.getItem("user");
        if (localUser) {
          if (window.location.pathname.indexOf("/admin/") !== -1) {
            return navigate("/admin/gestao/empresas", { replace: true });
          } else {
            return navigate("/home", { replace: true });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const forgotLogin = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    UserRememberPassword(username.replace(/\D/g, ''))
      .then((res) => {
        setIsLoading(false);
        setForgot(!forgot);
        // setForgotOrPassword(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const forgotNewPassword = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    rememberPassword(code, password, passwordRepeat)
      .then((res) => {
        setIsLoading(false);
        return navigate("/", { replace: true });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const loginForgot = () => {
    setForgot(!forgot);
  };
  const loginForgotPassword = () => {
    setForgotOrPassword(!forgotOrPassword);
  };

  useEffect(() => {
    if (urlLogo) {
      setHealthPlaceLogo(
        `${
          process.env.REACT_APP_API_URL ||
          "https://api.sinaisvitais.prosperasaude.com.br"
        }${urlLogo}`
      );
    }
    if (codeURL !== undefined && codeURL.length < 23) {
      return navigate("/", { replace: true });
    }
    code.length === 23 &&
      checkCode(code)
        .then((res) => {
          setShowPasswordForgot(true);
          SetCodeCheck(true);
        })
        .catch((err) => {
          setShowPasswordForgot(false);
          SetCodeCheck(false);
          setForgotOrPassword(false);
          setCode("");
          return window.location.pathname.indexOf("/admin/") !== -1
            ? navigate("/admin/login", { replace: true })
            : navigate("/login", { replace: true });
        });
  }, [code, codeURL, navigate, urlLogo]);

  return (
    <LoginContent primary={primaryColor || "#FAFAFA"}>
      {healthPlaceLogo && (
        <LoginLogoContent>
          <img width={"230px"} src={healthPlaceLogo} alt="Login" />
        </LoginLogoContent>
      )}
      <PageHeader title="Login" />
      {/* {window.location.hostname !== "sinaisvitais.prosperasaude.com.br" ? ( */}
      {true ? (
        <LoginDiv>
          {!forgot && !forgotOrPassword && (
            <>
              <DivFormLogin onSubmit={login}>
                <LoginH2>Login</LoginH2>
                <br />
                <LoginFormControl variant="outlined">
                  <LoginInputLabel htmlFor="username">CPF</LoginInputLabel>
                  <LoginOutlinedInput
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(cpfMask(e.target.value.replace(/\D/g, '').substring(0, 11)))}
                    id="username"
                    type="text"
                    label="CPF"
                  />
                </LoginFormControl>
                <br />
                <br />
                <LoginFormControl variant="outlined">
                  <LoginInputLabel htmlFor="password">Senha</LoginInputLabel>
                  <LoginOutlinedInput
                    autoComplete="current-password"
                    defaultValue={password}
                    inputProps={{ minLength: 8, maxLength: 30 }}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <LoginInputAdornment position="end">
                        <LoginIconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? "Ocultar" : "Mostrar"}
                        </LoginIconButton>
                      </LoginInputAdornment>
                    }
                    label="Senha"
                  />
                </LoginFormControl>
                {/* <LoginContentA>
                  <LoginA onClick={loginForgot}>Esqueci minha senha</LoginA>
                </LoginContentA> */}
                <LoginContentButton>
                  <LoginButton
                    disabled={username !== "" && password !== "" ? false : true}
                    title="Entrar"
                  >
                    {isLoading ? (
                      <CircularProgress size={18} color={"inherit"} />
                    ) : (
                      "Entrar"
                    )}
                  </LoginButton>
                </LoginContentButton>
              </DivFormLogin>
            </>
          )}
          {forgotOrPassword && (
            <>
              <DivFormLogin onSubmit={forgotNewPassword}>
                <div>
                  <LoginH2>Digite nova senha</LoginH2>
                </div>
                <br />
                {!codeCheck && (
                  <>
                    <LoginFormControl variant="outlined">
                      <LoginInputLabel htmlFor="code">Código</LoginInputLabel>
                      <LoginOutlinedInput
                        inputProps={{ maxLength: 23 }}
                        onChange={(e) => setCode(e.target.value)}
                        defaultValue={code}
                        id="code"
                        type="text"
                        label="Código"
                      />
                      <LoginPBlack>Código enviado para seu email</LoginPBlack>
                    </LoginFormControl>
                    <br />
                    <br />
                  </>
                )}
                {showPasswordRepeatForgot && (
                  <>
                    <LoginFormControl variant="outlined">
                      <LoginInputLabel htmlFor="password">
                        Senha
                      </LoginInputLabel>
                      <LoginOutlinedInput
                        autoComplete="new-password"
                        inputProps={{ minLength: 8, maxLength: 30 }}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <LoginInputAdornment position="end">
                            <LoginIconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? "Ocultar" : "Mostrar"}
                            </LoginIconButton>
                          </LoginInputAdornment>
                        }
                        label="Senha"
                      />
                    </LoginFormControl>
                    <br />
                    <br />
                    <LoginFormControl variant="outlined">
                      <LoginInputLabel htmlFor="password">
                        Repita a senha
                      </LoginInputLabel>
                      <LoginOutlinedInput
                        autoComplete="new-password"
                        inputProps={{ minLength: 8, maxLength: 30 }}
                        onChange={(e) => setPasswordRepeat(e.target.value)}
                        id="repeatPassword"
                        type={showPasswordRepeat ? "text" : "password"}
                        endAdornment={
                          <LoginInputAdornment position="end">
                            <LoginIconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordRepeat}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordRepeat ? "Ocultar" : "Mostrar"}
                            </LoginIconButton>
                          </LoginInputAdornment>
                        }
                        label="Repita a senha"
                      />
                    </LoginFormControl>
                    <LoginContentButton>
                      <LoginButton
                        disabled={
                          passwordRepeat === password && passwordRepeat !== ""
                            ? false
                            : true
                        }
                        title="Recuperar senha"
                      >
                        {isLoading ? (
                          <CircularProgress size={18} color={"inherit"} />
                        ) : (
                          "Recuperar senha"
                        )}
                      </LoginButton>
                    </LoginContentButton>
                  </>
                )}
                <LoginContentA>
                  <LoginA onClick={loginForgotPassword}>Voltar</LoginA>
                </LoginContentA>
              </DivFormLogin>
            </>
          )}
          {forgot && (
            <>
              <DivFormLogin onSubmit={forgotLogin}>
                <div>
                  <LoginH2>Esqueci minha senha</LoginH2>
                </div>
                <br />
                <LoginFormControl variant="outlined">
                  <LoginInputLabel htmlFor="email">CPF</LoginInputLabel>
                  <LoginOutlinedInput
                    defaultValue={username}
                    onChange={(e) => setUsername(cpfMask(e.target.value.replace(/\D/g, '').substring(0, 11)))}
                    id="CPF"
                    type="text"
                    label="CPF"
                  />
                </LoginFormControl>
                <LoginInputMensagem>
                  Digite seu e-mail para recuperar a senha.
                </LoginInputMensagem>
                <LoginContentButton>
                  <LoginButton
                    disabled={username !== "" ? false : true}
                    title="Recuperar senha"
                  >
                    {isLoading ? (
                      <CircularProgress size={18} color={"inherit"} />
                    ) : (
                      "Recuperar senha"
                    )}
                  </LoginButton>
                </LoginContentButton>
                <LoginContentA>
                  <LoginA onClick={loginForgot}>Voltar</LoginA>
                </LoginContentA>
              </DivFormLogin>
            </>
          )}
        </LoginDiv>
      ) : (
        <meta
          http-equiv="refresh"
          content="2;url=https://portal.prosperasaude.com.br/portal"
        />
      )}
    </LoginContent>
  );
};

export default Login;
