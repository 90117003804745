import { styled } from "@mui/material/styles";

export const MeasurementsRectangleDetailsDiv = styled("div")`
  /* display: flex;
  flex-direction: row; */
  align-items: center;
  padding: 8px 14px;
  margin-bottom: 20px;
  gap: 31px;
  background: #756dbd;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  justify-content: space-between;
`;

export const TextH1 = styled("h1")`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const ButtonYellowHistorical = styled("a")`
  text-decoration: none;
  width: 76px;
  height: 29px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  text-align: center;
  padding-top: 4px;
  cursor: pointer;
  color: #1f1f1f;
  &:hover {
    background-color: #b68500;
    border: 1px solid #b68500;
  }
`;

export const TextP = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
`;

export const MeasurementsRectangleDataDiv = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ValueRectangleMeasurements = styled("span")`
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 32px;
  color: #ffffff;
`;

export const TextPCardiaca = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: #e1e1e1;
  text-align: center;
`;

export const MedicalHistoryMaisA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #007aff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
  cursor: pointer;
`;

export const ContainerBase = styled("div")``;

export const TextAndMeasurements = styled("div")`
  text-align: end;
`;

export const TextMeasurements = styled("div")`
  padding-left: 10px;
`;

export const MedicoesAlinhaDiv = styled("div")`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    padding-right: 25px;
  }
`;

export const HrDashedLine = styled("div")`
  border: 1px dashed #919191;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const MedicoesCirculoTextoSpan = styled("span")`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 28px;
  color: #000000;
  margin-top: 10px;
`;

export const MedicoesCirculoIdadeTextoSpan = styled("span")`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;
