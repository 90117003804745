import React, { useState } from "react";
import {
  customStyles,
  ModalA,
  ModalB,
  ModalButtonDiv,
  ModalContentA,
  ModalContentB,
  ModalContentC,
  ModalH2,
  ModalP,
} from "./styles";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { ICustomer } from "../../../Services/Interfaces";
import { cnpjMask, DateFormat } from "../../../utils/conversions";
import { Box, Tab, Tabs, Typography } from "@mui/material";

interface ModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  customer: ICustomer;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ModalComponentCustomer: React.FC<ModalProps> = ({
  setIsOpen,
  modalIsOpen,
  customer,
}) => {
  const [valueAba, setValueAba] = useState(0);

  Modal.setAppElement("#root");

  function closeModal() {
    setIsOpen(false);
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueAba(newValue);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Detalhes Gestão Empresa"
    >
      <ModalContentA>
        <ModalH2>Detalhes</ModalH2>
        <ModalA onClick={closeModal}>
          <GrClose size={26} />
        </ModalA>
      </ModalContentA>
      <ModalContentB>
        <ModalP>
          <ModalB>Nome:&nbsp;</ModalB>
          {customer.profile?.fullName || "-"}
        </ModalP>

        <ModalP>
          <ModalB>CPF:&nbsp;</ModalB>
          {customer.profile?.cpf || "-"}
        </ModalP>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={valueAba} onChange={handleChange}>
              {customer.customer?.customerServices?.map(
                (customerService, index) => {
                  return (
                    <Tab
                      key={index + 1201}
                      label={customerService.service.healthPlace.name}
                    />
                  );
                }
              )}
            </Tabs>
          </Box>
          {customer.customer?.customerServices?.map(
            (customerService, index) => {
              return (
                <TabPanel key={index + 5452} value={valueAba} index={index}>
                  <ModalP>
                    <ModalB>Empresa:&nbsp;</ModalB>
                    {customerService.service.healthPlace.corporateName || "-"}
                  </ModalP>
                  <ModalP>
                    <ModalB>CNPJ:&nbsp;</ModalB>
                    {cnpjMask(customerService.service.healthPlace.cnpj) || "-"}
                  </ModalP>
                  <ModalP>
                    <ModalB>Medições Contratadas:&nbsp;</ModalB>
                    {customerService.limitPerMonth || "-"}
                  </ModalP>
                  <ModalP>
                    <ModalB>Data de Inclusão:&nbsp;</ModalB>
                    {DateFormat(customer.createdAt)}
                  </ModalP>
                  <ModalP>
                    <ModalB>Data de última modificação:&nbsp;</ModalB>
                    {DateFormat(customer.updatedAt)}
                  </ModalP>
                  <ModalP>
                    <ModalB>Medições Histórico:&nbsp;</ModalB>
                    {customerService.usedCurrentMonth || "-"}
                  </ModalP>
                </TabPanel>
              );
            }
          )}
        </Box>
      </ModalContentB>
      <ModalContentC>
        <ModalButtonDiv onClick={closeModal}>Fechar</ModalButtonDiv>
      </ModalContentC>
    </Modal>
  );
};

export default ModalComponentCustomer;
