import { useEffect, useState } from "react";
import { adminHealthPlace } from "../../../../Services/Admin";
import {
  ICompany,
  ICompanyIOkRest,
  IUserLogado,
} from "../../../../Services/Interfaces";
import PageHeaderMenu from "../../../Auth/Menu";
import PageEmpresasAdminConteudo from "./Content";

function PageAdminHome() {
  const [userLogado, setUserLogado] = useState<IUserLogado>();
  const [companies, setCompanies] = useState<ICompany[]>();

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [likeSearch, setLikeSearch] = useState<string>("");
  const [healthPlaceType, setHealthPlaceType] = useState<string>("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
    if (userJSON && HealthPlaceJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);

      adminHealthPlace(likeSearch, offset, limit, healthPlaceType, undefined).then((response) => {
        const { data } = response as ICompanyIOkRest;
        setCompanies(data.data);
        setTotal(data.pagination?.total || 0);
      });
    }
  }, [likeSearch, limit, offset, healthPlaceType]);

  return (
    <>
      {userLogado && companies && (
        <PageHeaderMenu
          InternalComponent={
            <PageEmpresasAdminConteudo
              companies={companies}
              pagination={{
                setLimit,
                setOffset,
                setRowsPerPage,
                rowsPerPage,
                total,
                setPage,
                page,
              }}
              setLikeSearch={setLikeSearch}
              setHealthPlaceType={setHealthPlaceType}
            />
          }
        />
      )}
    </>
  );
}

export default PageAdminHome;
