import { useEffect, useState } from "react";
import PurchaseComponent from "../../Components/Purchase/Counter";
import { dadosUser } from "../../Services/Auth";
import { IHealthPlace } from "../../Services/Interfaces";
import PageHeaderMenu from "../Auth/Menu";

function MyPurchase() {
  const [healthPlace, setHealthPlace] = useState<IHealthPlace>();

  useEffect(() => {
    dadosUser().then(() => {
      const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
      if (HealthPlaceJSON) {
        const HealthPlaceObjeto = JSON.parse(HealthPlaceJSON) as IHealthPlace;
        setHealthPlace(HealthPlaceObjeto);
      }
    });
  }, []);

  return (
    <>
      {healthPlace && (
        <PageHeaderMenu
          InternalComponent={
            <PurchaseComponent
              total={healthPlace.limitPerMonth}
              restante={healthPlace.usedCurrentMonth}
            />
          }
        />
      )}
    </>
  );
}

export default MyPurchase;
