import { styled } from "@mui/material/styles";

export const EmpresasDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DetalhesIconDiv = styled("div")`
  color: #ffbb00;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5px 4px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ffbb00;
  border-radius: 2px;
  cursor: pointer;
`;
