import React, { useState } from "react";
import { RoundUp } from "../../../utils/conversions";
import MeasurementDetails from "../../Modal/MeasurementDetails";
import { MedicoesCirculoTextoSpan } from "../Historic/styles";
import {
  BoxDivAmareloDireito,
  BoxDivAmareloEsquerdo,
  BoxDivValorCircular,
  BoxDivVerde,
  BoxLinhaDiv,
  GraphicBoxLinhaDiv,
  TextoP,
} from "./styles";

interface GraphicLinhaProps {
  title: string;
  value: number;
  ranges: number[];
  unit: string;
  codeMeasurement: string;
}

const GraphicLinha: React.FC<GraphicLinhaProps> = ({
  title,
  value,
  ranges,
  unit,
  codeMeasurement,
}) => {
  const valorCirculo = RoundUp(value.toString(), 2);

  const [modalIsOpen, setIsOpen] = useState(false);

  const locationHref = () => {
    setIsOpen(!modalIsOpen);
  };

  return (
    <GraphicBoxLinhaDiv>
      <TextoP onClick={locationHref}>
        {title} &nbsp;{unit}
      </TextoP>
      <br />
      <BoxLinhaDiv>
        <BoxDivAmareloEsquerdo>
          {value < ranges[0] && (
            <BoxDivValorCircular>
              <MedicoesCirculoTextoSpan>
                {valorCirculo}
              </MedicoesCirculoTextoSpan>
            </BoxDivValorCircular>
          )}
        </BoxDivAmareloEsquerdo>
        <BoxDivVerde>
          {value >= ranges[1] && value < ranges[2] && (
            <BoxDivValorCircular>
              <MedicoesCirculoTextoSpan>
                {valorCirculo}
              </MedicoesCirculoTextoSpan>
            </BoxDivValorCircular>
          )}
        </BoxDivVerde>
        <BoxDivAmareloDireito>
          {value > ranges[3] && (
            <BoxDivValorCircular>
              <MedicoesCirculoTextoSpan>
                {valorCirculo}
              </MedicoesCirculoTextoSpan>
            </BoxDivValorCircular>
          )}
        </BoxDivAmareloDireito>
      </BoxLinhaDiv>
      <MeasurementDetails
        codeMeasurement={codeMeasurement}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      />
    </GraphicBoxLinhaDiv>
  );
};

export default GraphicLinha;
