import React, { useState } from "react";
import MeasurementsConsumptionComponent from "../../Components/Aton/Measurement/Counter";
import { MedicoesNovoDiv } from "../../Components/Aton/Measurement/styles";

import { ButtonYellow, DivImageHome, HomeDiv, ImageHome } from "./styles";
import { TextH2 } from "../../styles/global.styles";
import { IHealthPlace, IUserLogado } from "../../Services/Interfaces";
import ModalComponentMedicao from "../../Components/Modal/Medicao";
import ModalComponentInfo from "../../Components/Modal/Info";

interface PageHomeProps {
  user: IUserLogado;
  healthPlace: IHealthPlace;
}

const PageHomeConteudo: React.FC<PageHomeProps> = ({ user, healthPlace }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const locationHref = async () => {
    setIsOpen(!modalIsOpen);
  };

  return (
    <HomeDiv>
      <TextH2>Olá, {`${user.profile.fullName}`} 👋</TextH2>
      <br />
      <MeasurementsConsumptionComponent
        total={healthPlace.limitPerMonth}
        restante={healthPlace.usedCurrentMonth}
      />
      <br />
      <br />
      <TextH2>Informações para a Medição</TextH2>
      <br />
      <DivImageHome>
        <ImageHome
          src={"/InformacoesparaaMedicao.png"}
          alt="Informações para a Medição"
        />
      </DivImageHome>
      <br />
      <MedicoesNovoDiv>
        <ModalComponentMedicao
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
        <ModalComponentInfo
          setStartMeasure={locationHref}
          setIsOpen={setIsInfoModalOpen}
          isOpen={isInfoModalOpen}
        />
        <ButtonYellow
          onClick={() => {
            healthPlace &&
            healthPlace.limitPerMonth >= healthPlace.usedCurrentMonth
              ? setIsInfoModalOpen(true)
              : setIsInfoModalOpen(false);
          }}
        >
          {healthPlace &&
          healthPlace.limitPerMonth >= healthPlace.usedCurrentMonth
            ? "Iniciar nova medição"
            : "Você já realizou todas as medições disponíveis"}
        </ButtonYellow>
      </MedicoesNovoDiv>
    </HomeDiv>
  );
};

export default PageHomeConteudo;
