import { useEffect, useState } from "react";
import { dadosUser } from "../../Services/Auth";
import { IHealthPlace, IUserLogado } from "../../Services/Interfaces";
import PageHeaderMenu from "../Auth/Menu";
import PageHomeConteudo from "./Content";

function PageHome() {
  const [userLogado, setUserLogado] = useState<IUserLogado>();
  const [healthPlace, setHealthPlace] = useState<IHealthPlace>();

  useEffect(() => {
    dadosUser().then(() => {
      const userJSON = localStorage.getItem("user");
      const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
      if (userJSON && HealthPlaceJSON) {
        const userObjeto = JSON.parse(userJSON) as IUserLogado;
        const HealthPlaceObjeto = JSON.parse(HealthPlaceJSON) as IHealthPlace;
        setUserLogado(userObjeto);
        setHealthPlace(HealthPlaceObjeto);
      }
    });
  }, []);

  return (
    <>
      {userLogado && healthPlace && (
        <PageHeaderMenu
          InternalComponent={
            <PageHomeConteudo user={userLogado} healthPlace={healthPlace} />
          }
        />
      )}
    </>
  );
}

export default PageHome;
