import { toast } from "react-toastify";
import { DominioURL, Logout } from "../Components/Auth/isAuthenticated";
import { api, baseURL } from "./Api";
import {
  IErroRest,
  IOkRest,
  IUserLogado,
  IUserServiceRescueIOkRest,
} from "./Interfaces";
import axios from "axios";
import qs from "qs";

export const userProfilePicture = async (Data: FormData) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/user/profile_picture", Data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IOkRest) => {
            if (response.data.data.profilePicture) {
              const userString = localStorage.getItem("user");
              if (userString) {
                const user = JSON.parse(userString) as IUserLogado;
                user.profile.profilePicture = response.data.data.profilePicture;
                localStorage.setItem("user", JSON.stringify(user));
                return resolve(response.data.data);
              }
            }
          })
          .catch((error: IErroRest) => {
            return reject(error);
          });
      }),
      {
        success: "Imagem salva.",
        pending: "Salvando Imagem.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const userServiceRescue = async (
  offset?: number,
  limit?: number,
  criteriaSrId?: string
) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/user/service_rescue?attributes[]=id&attributes[]=createdAt&attributes[vitalSignsMeasurements][]=id&attributes[vitalSignsMeasurements][]=value&attributes[vitalSignsMeasurements][]=type${
          criteriaSrId ? "&criteria[sr][id]=" + criteriaSrId : ""
        }&criteria[hp][accessUrl]=${DominioURL()}&offset=${
          offset ? offset : 0
        }&limit=${limit ? limit : 10}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response: IUserServiceRescueIOkRest) => {
        return resolve(response);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const customerImport = async (Data: FormData) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/admin/customer/import", Data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IOkRest) => {
            resolve(response);
          })
          .catch((error: IErroRest) => {
            if (error.response.data.code === 401) {
              Logout(true);
            }
            return reject(error);
          });
      }),
      {
        success: "Arquivo Enviado",
        pending: "Enviando arquivo",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const usageTerms = async () => {
  let data = qs.stringify({
    "update[usageTerms]": "true",
  });
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/usage_terms/`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then(() => {
        resolve(true);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};
