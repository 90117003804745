import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminProgram } from "../../../../Services/Admin";
import { IProgram, IProgramIDIOkRest } from "../../../../Services/Interfaces";
import PageHeaderMenu from "../../../Auth/Menu";
import PageProgramAdminEdit from "./Content";

function PageAdminProgramasEdit() {
  const navigate = useNavigate();
  const { codeID } = useParams();
  const [Program, setProgram] = useState<IProgram | null>(null);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (codeID === "new") {
      setProgram(null);
    }
    if (codeID !== undefined && codeID !== "new") {
      adminProgram(undefined, undefined, undefined, codeID)
        .then((response) => {
          const { data } = response as IProgramIDIOkRest;
          setProgram(data.data);
        })
        .catch(() => {
          navigate("/admin/gestao/programas", { replace: true });
        });
    }
  }, [codeID, navigate, forceUpdate]);

  return (
    <>
      {Program !== undefined && (
        <PageHeaderMenu
          InternalComponent={
            <PageProgramAdminEdit
              setForceUpdate={setForceUpdate}
              Program={Program}
            />
          }
        />
      )}
    </>
  );
}

export default PageAdminProgramasEdit;
