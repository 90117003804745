import { Avatar, FormHelperText } from "@mui/material";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import qs from "qs";
import {
  getHealthPlaceCliente,
  getProgramAll,
  getUF,
  healthPlaceDeaActivate,
  healthPlaceLogoPost,
  healthPlacePost,
  healthPlacePut,
  integrationUserPostPut,
  programADDHealthPlacePost,
  programRemoveHealthPlacePost,
  uploadPolice,
} from "../../../../Services/Admin";
import {
  ICompany,
  IHealthPlaceIOkRest,
  IProgram,
  IProgramIOkRest,
  IUFIOkRest,
} from "../../../../Services/Interfaces";

import { HeaderComponent, TextH2 } from "../../../../styles/global.styles";
import {
  CompanyButton,
  CompanyContentButton,
  CompanyForm,
  CompanyFormControl,
  CompanyInputLabel,
  CompanyOutlinedInput,
  CompanyDiv,
  CompanySpaceDiv,
  CompanySelect,
  CompanyMenuItem,
  CompanyBaseboardEndDiv,
  CompanyInativeButton,
  CompanyAtiveButton,
  CompanyColorInput,
  CompanyColorDiv,
  CompanyInputLabelColor,
  CompanyColorDivLabel,
  CompanyOutlinedInputFile,
  ProgramCompanyGradeDiv,
  ProgramButtonGrade,
  UploadTermoButton,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { CEPMask, cnpjMask } from "../../../../utils/conversions";

interface PageCompanyProps {
  company?: ICompany | null;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageCompanyAdminEdit: React.FC<PageCompanyProps> = ({
  company,
  setForceUpdate,
}) => {
  const navigate = useNavigate();

  const [fontColor, setFontColor] = useState(company?.fontColor || "#6257B8");
  const [secondBackgroundColor, setSecondBackgroundColor] = useState(
    company?.secondBackgroundColor || "#FFBB00"
  );
  const [firstBackgroundColor, setFirstBackgroundColor] = useState(
    company?.firstBackgroundColor || "#1F1F1F"
  );

  const [name, setName] = useState(company?.name || "");
  const [corporateName, setCorporateName] = useState(
    company?.corporateName || ""
  );
  const [accessUrl, setAccessUrl] = useState(company?.accessUrl || "");
  const [CNPJ, setCNPJ] = useState(company?.cnpj || "");
  const [municipalRegistration, setMunicipalRegistration] = useState(
    company?.municipalRegistration || ""
  );
  const [stateRegistration, setStateRegistration] = useState(
    company?.stateRegistration || ""
  );
  const [street, setStreet] = useState(company?.address?.street || "");
  const [zipCode, setZipCode] = useState(company?.address?.zipCode || "");
  const [number, setNumber] = useState(company?.address?.number || "");
  const [complement, setComplement] = useState(
    company?.address?.complement || ""
  );
  const [uf, setUF] = useState<{ id: string; name: string }>({
    id: company?.address?.uf?.id || "",
    name: company?.address?.uf?.name || "",
  });
  const [healthPlace, setHealthPlace] = useState<{ id: string; name: string }>({
    id: company?.healthPlace?.id || "",
    name: company?.healthPlace?.name || "",
  });
  const [tipo, setTipo] = useState<{ id: string; name: string }>({
    id: company?.healthPlace?.id ? "Cliente" : "Agregadora",
    name: company?.healthPlace?.name ? "Cliente" : "Agregadora",
  });
  const [ufList, setUFList] = useState<{ id: string; name: string }[]>([]);
  const [healthPlaceList, setHealthPlaceList] = useState<
    { id: string; name: string }[]
  >([]);
  const [tipoList] = useState<{ id: string; name: string }[]>([
    { id: "Cliente", name: "Cliente" },
    { id: "Agregadora", name: "Agregadora" },
  ]);
  const [city, setCity] = useState(company?.address?.city || "");
  const [file, setFile] = useState<File>();
  const [fileReader, setFileReader] = useState<string>();
  const [password, setPassword] = useState<string>();

  // Validação de erros
  const [accessUrlErro, setAccessUrlErro] = useState(false);
  const [nameErro, setNameErro] = useState(false);
  const [corporateNameErro, setCorporateNameErro] = useState(false);
  const [municipalRegistrationErro, setMunicipalRegistrationErro] =
    useState(false);
  const [stateRegistrationErro, setStateRegistrationErro] = useState(false);
  const [CNPJErro, setCNPJErro] = useState(false);
  const [zipCodeErro, setZipCodeErro] = useState(false);
  const [streetErro, setStreetErro] = useState(false);
  const [numberErro, setNumberErro] = useState(false);
  const [cityErro, setCityErro] = useState(false);
  const [passwordErro, setPasswordErro] = useState(false);
  const [fileNamePDF, setFileNamePDF] = useState("Upload de PDF");
  const [fileData, setFileData] = useState<FormData>(new FormData());
  const [uploadErroPdfMensagem, setUploadErroPdfMensagem] =
    useState("Upload de PDF");
  const [uploadErroPdf, setUploadErroPDF] = useState<any>();
  const [programs, setPrograms] = useState<IProgram[]>();

  const handleFileChangeUploadPDF = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files ? event?.target?.files[0] : null;
    if (file) {
      setFileNamePDF(file.name);
      const formData = new FormData();
      formData.append("term[termFile][file]", file);
      setFileData(formData);
    } else {
      setUploadErroPDF("Arquivo Invalido");
    }
  };

  const handleClickUploadPDFSave = () => {
    if (fileData && company) {
      uploadPolice(fileData, company?.id);
    } else {
      setUploadErroPDF("Arquivo Invalido");
    }
  };

  const handleClickUploadPDF = () => {
    const element = document.getElementById("pdfUpload");
    if (element) {
      element.click();
    }
  };
  useEffect(() => {
    getUF().then((response) => {
      if (response) {
        const { data } = response as IUFIOkRest;
        setUFList(data.data);
      }
    });
    getHealthPlaceCliente().then((response) => {
      const { data } = response as IHealthPlaceIOkRest;
      setHealthPlaceList(data.data);
    });
    getProgramAll().then((response) => {
      const { data } = response as IProgramIOkRest;
      // ordenar array pelo nome
      data.data.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name, "pt-BR");
      });
      setPrograms(data.data);
    });
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const files = event.target.files;

    if (files && files.length > 0) {
      setFile(files[0]);
      reader.readAsDataURL(files[0]);
    } else {
      setFile(undefined);
    }
    reader.onloadend = () => {
      const img = reader.result as string;
      setFileReader(img);
    };
  };

  const errosCheck = (erros: any) => {
    if (erros.response.data.data.children) {
      Object.keys(erros.response.data.data.children).forEach(function (itemOn) {
        if (
          erros.response.data.data.children[itemOn].errors.length > 0 ||
          erros.response.data.data.children[itemOn].children
        ) {
          const mensagemErro =
            erros.response.data.data.children[itemOn].errors[0]?.message || "";

          switch (itemOn) {
            case "name":
              setNameErro(mensagemErro);
              break;
            case "corporateName":
              setCorporateNameErro(mensagemErro);
              break;
            case "accessUrl":
              setAccessUrlErro(mensagemErro);
              break;
            case "cnpj":
              setCNPJErro(mensagemErro);
              break;
            case "municipalRegistration":
              setMunicipalRegistrationErro(mensagemErro);
              break;
            case "stateRegistration":
              setStateRegistrationErro(mensagemErro);
              break;
            case "password":
              setPasswordErro(mensagemErro);
              break;
            case "uploadPDF":
              setUploadErroPdfMensagem(mensagemErro);
              break;
            case "address":
              Object.keys(
                erros.response.data.data.children[itemOn].children
              ).forEach(function (itemTo) {
                if (
                  erros.response.data.data.children[itemOn].children[itemTo]
                    .errors.length > 0
                ) {
                  const mensagemErro =
                    erros.response.data.data.children[itemOn].children[itemTo]
                      .errors[0].message || "";
                  switch (itemTo) {
                    case "city":
                      setCityErro(mensagemErro);
                      break;
                    case "complement":
                      // console.error(complement);
                      break;
                    case "number":
                      setNumberErro(mensagemErro);
                      break;
                    case "street":
                      setStreetErro(mensagemErro);
                      break;
                    case "uf":
                      // console.error(mensagemErro);
                      break;
                    case "zipCode":
                      setZipCodeErro(mensagemErro);
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const onSubmitEmpresas = (e: FormEvent) => {
    e.preventDefault();

    if (company?.id) {
      healthPlacePut(
        company?.id,
        qs.stringify({
          "create[corporateName]": corporateName,
          "create[name]": name,
          "create[cnpj]": CNPJ.replace(/\D/g, ""),
          "create[municipalRegistration]": municipalRegistration,
          "create[stateRegistration]": stateRegistration,
          "create[fontColor]": fontColor,
          "create[firstBackgroundColor]": firstBackgroundColor,
          "create[secondBackgroundColor]": secondBackgroundColor,
          "create[address][street]": street,
          "create[address][number]": number,
          "create[address][complement]": complement,
          "create[address][zipCode]": zipCode.replace(/\D/g, ""),
          "create[address][city]": city,
          "create[address][uf]": uf.id,
          "create[isActive]": company?.isActive ? company?.isActive : 1,
          "create[accessUrl]": accessUrl,
          "create[healthPlaceId]": healthPlace.id,
        })
      )
        .then((result: any) => {
          if (result.status === 201) {
            if (file && result.data.data) {
              const formData = new FormData();
              formData.append("logo[logoFile][file]", file, file.name);
              healthPlaceLogoPost(result.data.data, formData).then(() => {
                if (company) {
                  setTimeout(() => {
                    return window.location.reload();
                  }, 5000);
                } else {
                  return navigate(
                    `/admin/gestao/empresas/${result.data.data}`,
                    { replace: true }
                  );
                }
              });
            } else {
              setTimeout(() => {
                return window.location.reload();
              }, 5000);
            }
          }
        })
        .catch((error) => {
          errosCheck(error);
        });
    } else {
      healthPlacePost(
        qs.stringify({
          "create[corporateName]": corporateName,
          "create[name]": name,
          "create[cnpj]": CNPJ.replace(/\D/g, ""),
          "create[municipalRegistration]": municipalRegistration,
          "create[stateRegistration]": stateRegistration,
          "create[fontColor]": fontColor,
          "create[firstBackgroundColor]": firstBackgroundColor,
          "create[secondBackgroundColor]": secondBackgroundColor,
          "create[address][street]": street,
          "create[address][number]": number,
          "create[address][complement]": complement,
          "create[address][zipCode]": zipCode.replace(/\D/g, ""),
          "create[address][city]": city,
          "create[address][uf]": uf.id,
          "create[isActive]": company?.isActive ? company?.isActive : 1,
          "create[accessUrl]": accessUrl,
          "create[healthPlaceId]": healthPlace.id,
        })
      )
        .then((result: any) => {
          if (result.status === 201) {
            if (file && result.data.data) {
              const formData = new FormData();
              formData.append("logo[logoFile][file]", file, file.name);
              healthPlaceLogoPost(result.data.data, formData)
                .then(() => {
                  if (company) {
                    setTimeout(() => {
                      return window.location.reload();
                    }, 5000);
                  } else {
                    return navigate(
                      `/admin/gestao/empresas/${result.data.data}`,
                      { replace: true }
                    );
                  }
                })
                .catch((error) => {
                  errosCheck(error);
                });
            } else {
              setTimeout(() => {
                return window.location.reload();
              }, 5000);
            }
          }
        })
        .catch((error) => {
          errosCheck(error);
        });
    }
  };

  const onSubmitDeaActivate = (e: FormEvent) => {
    e.preventDefault();
    if (company?.id) {
      healthPlaceDeaActivate(company?.id, !company?.isActive).then(() => {
        return window.location.reload();
      });
    }
  };

  const onSubmitIntegrationUser = (e: FormEvent) => {
    e.preventDefault();
    if (company?.id) {
      if (!company?.integrationUser) {
        integrationUserPostPut(
          qs.stringify({
            "create[password]": password,
          }),
          company.id,
          "post"
        )
          .then((result: any) => {
            if (result.status === 201) {
              setTimeout(() => {
                return window.location.reload();
              }, 5000);
            }
          })
          .catch((error) => {
            errosCheck(error);
          });
      } else {
        integrationUserPostPut(
          qs.stringify({
            "update[password]": password,
          }),
          company.id,
          "put"
        )
          .then((result: any) => {
            if (result.status === 200) {
              setTimeout(() => {
                return window.location.reload();
              }, 5000);
            }
          })
          .catch((error) => {
            errosCheck(error);
          });
      }
    }
  };

  return (
    <CompanyDiv>
      <HeaderComponent>
        {company ? (
          <TextH2>Atualização Empresas</TextH2>
        ) : (
          <TextH2>Cadastrar Empresas</TextH2>
        )}
      </HeaderComponent>
      <br />
      <CompanyForm onSubmit={onSubmitEmpresas}>
        <CompanySpaceDiv>
          <CompanyFormControl error={corporateNameErro} variant="outlined">
            <CompanyInputLabel htmlFor="corporateName">
              Nome Empresa
            </CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={corporateName}
              onChange={(e) => setCorporateName(e.target.value)}
              id="corporateName"
              type="text"
              label="Nome Empresa"
            />
            {corporateNameErro && (
              <FormHelperText id="corporateName-error">
                {corporateNameErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
          <CompanyFormControl error={CNPJErro} variant="outlined">
            <CompanyInputLabel htmlFor="CNPJ">CNPJ</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={cnpjMask(CNPJ)}
              onChange={(e) => setCNPJ(e.target.value)}
              id="CNPJ"
              type="text"
              label="CNPJ"
              placeholder="00.000.000/0000-00"
            />
            {CNPJErro && (
              <FormHelperText id="corporateName-error">
                {CNPJErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyFormControl
            error={municipalRegistrationErro}
            variant="outlined"
          >
            <CompanyInputLabel htmlFor="municipalRegistration">
              Inscrição Municipal
            </CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={municipalRegistration}
              onChange={(e) => setMunicipalRegistration(e.target.value)}
              id="municipalRegistration"
              type="text"
              label="Inscrição Municipal"
            />
            {municipalRegistrationErro && (
              <FormHelperText id="corporateName-error">
                {municipalRegistrationErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
          <CompanyFormControl error={stateRegistrationErro} variant="outlined">
            <CompanyInputLabel htmlFor="stateRegistration">
              Inscrição Estadual
            </CompanyInputLabel>
            <CompanyOutlinedInput
              required={false}
              defaultValue={stateRegistration}
              onChange={(e) => setStateRegistration(e.target.value)}
              id="stateRegistration"
              type="text"
              label="Inscrição Estadual"
            />
            {stateRegistrationErro && (
              <FormHelperText id="corporateName-error">
                {stateRegistrationErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyFormControl error={nameErro} variant="outlined">
            <CompanyInputLabel htmlFor="name">Nome Fantasia</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              type="text"
              label="Nome Fantasia"
            />
            {nameErro && (
              <FormHelperText id="corporateName-error">
                {nameErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
          <CompanyFormControl error={accessUrlErro} variant="outlined">
            <CompanyInputLabel htmlFor="accessUrl">
              URL Acesso
            </CompanyInputLabel>
            <CompanyOutlinedInput
              // required
              defaultValue={accessUrl}
              onChange={(e) => setAccessUrl(e.target.value)}
              id="accessUrl"
              type="text"
              label="URL Acesso"
            />
            {accessUrlErro && (
              <FormHelperText id="corporateName-error">
                {accessUrlErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyFormControl
            error={zipCodeErro}
            percentage={"25%"}
            variant="outlined"
          >
            <CompanyInputLabel htmlFor="zipCode">CEP</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={CEPMask(zipCode)}
              onChange={(e) => setZipCode(e.target.value)}
              id="zipCode"
              type="text"
              label="CEP"
              placeholder="00000-000"
            />
            {zipCodeErro && (
              <FormHelperText id="corporateName-error">
                {zipCodeErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
          <CompanyFormControl error={streetErro} variant="outlined">
            <CompanyInputLabel htmlFor="street">Endereço</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={street}
              onChange={(e) => setStreet(e.target.value)}
              id="street"
              type="text"
              label="Endereço"
            />
            {streetErro && (
              <FormHelperText id="corporateName-error">
                {streetErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyFormControl
            error={numberErro}
            percentage={"25%"}
            variant="outlined"
          >
            <CompanyInputLabel htmlFor="number">Número</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={number}
              onChange={(e) => setNumber(e.target.value)}
              id="number"
              type="text"
              label="Número"
            />
            {numberErro && (
              <FormHelperText id="corporateName-error">
                {numberErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
          <CompanyFormControl percentage={"25%"} variant="outlined">
            <CompanyInputLabel htmlFor="complement">
              Complemento
            </CompanyInputLabel>
            <CompanyOutlinedInput
              defaultValue={complement}
              onChange={(e) => setComplement(e.target.value)}
              id="complement"
              type="text"
              label="Complemento"
            />
          </CompanyFormControl>
          <CompanyFormControl percentage={"20%"} variant="outlined">
            <CompanyInputLabel htmlFor="uf">Estado</CompanyInputLabel>
            {ufList.length > 0 ? (
              <CompanySelect
                value={uf.id}
                onChange={(e) => {
                  setUF({ id: e.target.value as string, name: "" });
                }}
                id="uf"
                label="Estado"
              >
                {ufList.map((item) => (
                  <CompanyMenuItem key={item.id} value={item.id}>
                    {item.name}
                  </CompanyMenuItem>
                ))}
              </CompanySelect>
            ) : (
              <></>
            )}
          </CompanyFormControl>
          <CompanyFormControl
            error={cityErro}
            percentage={"30%"}
            variant="outlined"
          >
            <CompanyInputLabel htmlFor="city">Cidade</CompanyInputLabel>
            <CompanyOutlinedInput
              required
              defaultValue={city}
              onChange={(e) => setCity(e.target.value)}
              id="city"
              type="text"
              label="Cidade"
            />
            {cityErro && (
              <FormHelperText id="corporateName-error">
                {cityErro}
              </FormHelperText>
            )}
          </CompanyFormControl>
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyFormControl
            percentage={tipo.id === "Agregadora" ? "100%" : "50%"}
            variant="outlined"
          >
            <CompanyInputLabel htmlFor="tipo">TIPO</CompanyInputLabel>
            {tipoList.length > 0 ? (
              <CompanySelect
                value={tipo.id}
                onChange={(e) => {
                  setHealthPlace({ id: "", name: "" });
                  setTipo({ id: e.target.value as string, name: "" });
                }}
                id="tipo"
                label="TIPO"
              >
                {tipoList.map((item) => (
                  <CompanyMenuItem key={item.id} value={item.id}>
                    {item.name}
                  </CompanyMenuItem>
                ))}
              </CompanySelect>
            ) : (
              <></>
            )}
          </CompanyFormControl>
          {(healthPlace?.id || tipo.id !== "Agregadora") && (
            <CompanyFormControl percentage={"50%"} variant="outlined">
              <CompanyInputLabel htmlFor="uf">VÍNCULO</CompanyInputLabel>
              <CompanySelect
                value={healthPlace.id}
                onChange={(e) => {
                  setHealthPlace({
                    id: e.target.value as string,
                    name: "",
                  });
                }}
                id="vinculo"
                label="VÍNCULO"
              >
                {healthPlaceList.map((item) => (
                  <CompanyMenuItem key={item.id} value={item.id}>
                    {item.name}
                  </CompanyMenuItem>
                ))}
              </CompanySelect>
            </CompanyFormControl>
          )}
        </CompanySpaceDiv>
        <br />
        <CompanySpaceDiv>
          <CompanyColorDiv percentage={"75%"}>
            <CompanyColorDivLabel>
              <CompanyInputLabelColor htmlFor="fontColor">
                Cor Primária
              </CompanyInputLabelColor>
              <CompanyColorInput
                value={firstBackgroundColor}
                onChange={(e) => setFirstBackgroundColor(e.target.value)}
                id="firstBackgroundColor"
                type="color"
              />
            </CompanyColorDivLabel>
            <CompanyColorDivLabel>
              <CompanyInputLabelColor htmlFor="secondBackgroundColor">
                Cor Secundaria
              </CompanyInputLabelColor>
              <CompanyColorInput
                value={secondBackgroundColor}
                onChange={(e) => setSecondBackgroundColor(e.target.value)}
                id="secondBackgroundColor"
                type="color"
              />
            </CompanyColorDivLabel>
            <CompanyColorDivLabel>
              <CompanyInputLabelColor htmlFor="firstBackgroundColor">
                Cor da Fonte
              </CompanyInputLabelColor>
              <CompanyColorInput
                value={fontColor}
                onChange={(e) => setFontColor(e.target.value)}
                id="fontColor"
                type="color"
              />
            </CompanyColorDivLabel>
          </CompanyColorDiv>

          <CompanyColorDiv percentage={"25%"}>
            <CompanyInputLabel htmlFor="file">
              {(company?.logo || fileReader) && (
                <Avatar
                  alt={company?.corporateName || "Logo da empresa"}
                  src={
                    fileReader
                      ? fileReader
                      : `${
                          company?.logo === null
                            ? ""
                            : `${
                                process.env.REACT_APP_API_URL ||
                                "https://api.sinaisvitais.prosperasaude.com.br"
                              }${company?.logo}`
                        }`
                  }
                />
              )}
              {!company?.logo && !fileReader && (
                <div style={{ textAlignLast: "center" }}>
                  <FiPlus size={14} />
                  <br />
                  Enviar Logo
                </div>
              )}
            </CompanyInputLabel>
            <CompanyOutlinedInputFile
              onChange={handleFileChange}
              id="file"
              type="file"
              label="File"
            />
          </CompanyColorDiv>
        </CompanySpaceDiv>
        <CompanyBaseboardEndDiv>
          <CompanyContentButton>
            {company?.isActive && (
              <CompanyInativeButton
                onClick={onSubmitDeaActivate}
                title="Desativar"
              >
                Desativar
              </CompanyInativeButton>
            )}
            {company && !company?.isActive && (
              <CompanyAtiveButton onClick={onSubmitDeaActivate} title="Ativar">
                Ativar
              </CompanyAtiveButton>
            )}
            <CompanyButton title="Salvar">Salvar</CompanyButton>
          </CompanyContentButton>
        </CompanyBaseboardEndDiv>
      </CompanyForm>

      <br />
      {company && (
        <>
          <TextH2>Adicionar ou Remover Programas</TextH2>
          <CompanySpaceDiv>
            <ProgramCompanyGradeDiv>
              {programs?.map((program: any) => (
                <ProgramButtonGrade
                  key={program.id}
                  isSelected={company.programs.some(
                    (item: any) => item.id === program.id
                  )}
                  onClick={() => {
                    if (
                      company.programs.some(
                        (item: any) => item.id === program.id
                      )
                    ) {
                      programRemoveHealthPlacePost(company.id, program.id).then(
                        () => {
                          setForceUpdate((prev: boolean): boolean => !prev);
                        }
                      );
                    } else {
                      programADDHealthPlacePost(company.id, program.id).then(
                        () => {
                          setForceUpdate((prev: boolean): boolean => !prev);
                        }
                      );
                    }
                  }}
                >
                  {program.name}
                </ProgramButtonGrade>
              ))}
            </ProgramCompanyGradeDiv>
          </CompanySpaceDiv>
        </>
      )}
      <br />
      <br />
      {company && (
        <CompanyForm onSubmit={onSubmitIntegrationUser}>
          <TextH2>Cadastrar integração</TextH2>
          <br />
          <CompanySpaceDiv>
            <CompanyFormControl
              percentage={"10%"}
              variant="outlined"
              error={passwordErro}
            >
              <CompanyInputLabel htmlFor="password">Senha</CompanyInputLabel>
              <CompanyOutlinedInput
                required
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type="password"
                label="Senha"
              />
              {passwordErro && (
                <FormHelperText id="corporateName-error">
                  {passwordErro}
                </FormHelperText>
              )}
              <CompanyButton title="Salvar">Salvar</CompanyButton>
            </CompanyFormControl>
          </CompanySpaceDiv>
        </CompanyForm>
      )}
      <br />
      <br />
      {company && (
        <>
          <TextH2>Upload Termo</TextH2>
          <br />
          <CompanySpaceDiv>
            <CompanyFormControl
              percentage={"15%"}
              variant="outlined"
              error={uploadErroPdf}
            >
              <div>
                <input
                  type="file"
                  id="pdfUpload"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChangeUploadPDF}
                />
                <UploadTermoButton onClick={handleClickUploadPDF}>
                  {fileNamePDF}
                </UploadTermoButton>
              </div>
              <br />
              {uploadErroPdf && (
                <FormHelperText id="pdfUpload-error">
                  {uploadErroPdfMensagem}
                </FormHelperText>
              )}
              <CompanyButton onClick={handleClickUploadPDFSave} title="Salvar">
                Salvar
              </CompanyButton>
            </CompanyFormControl>
          </CompanySpaceDiv>
        </>
      )}
    </CompanyDiv>
  );
};

export default PageCompanyAdminEdit;
