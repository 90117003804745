import { Rating } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DivRectangleMeasurements = styled("div")`
  height: 52px;
  padding: 8px;

  background: #6257b8;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
`;

export const MedicoesP = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

type DivMeasurementsProps = {
  porcentagem: number;
};

export const MeasurementsProgressBar = styled("div")<DivMeasurementsProps>`
  --progress: ${(props) => props.porcentagem};
  --colorProgress: ${(props) => 100 - props.porcentagem};
  height: 6px;
  margin-top: 4px;
  display: flex;
  background: #e1e1e1;
  border-radius: 4px;

  ::before {
    content: "";
    width: calc(var(--progress) * 1%);
    background-color: hsl(calc(var(--colorProgress) * 1.2), 80%, 50%);
    transition: all 0.2s ease;
  }
`;

export const MedicoesQuadradoDiv = styled("div")`
  filter: drop-shadow(0px 0.3px 0.9px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1.6px 3.6px rgba(0, 0, 0, 0.13));
  /* display: flex; */
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  @media (max-width: 800px) {
    display: inline-block;
    background: #ffffff;
  }
  align-items: center;
`;

export const MedicoesQuadradoDubDiv = styled("div")`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  @media (max-width: 800px) {
    display: inline-block;
    padding: 0px;
  }
`;

export const MedicoesQuadradoStarDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MedicoesQuadradoLinhaDiv = styled("div")`
  display: contents;
  padding: 10px;
`;

export const MedicoesQuadradoLinhaP = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
`;

export const MedicoesQuadradoLinhaSpan = styled("span")`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #717171;
`;
export const MedicoesDataSpan = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #717171;
`;

export const MedicoesStarSpan = styled("div")`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #717171;
`;

export const MedicoesStar = styled(Rating)``;

export const MedicoesTextoAnd = styled("div")`
  text-align: end;
`;

export const MedicoesNovoDiv = styled("div")`
  text-align: center;
`;
export const MedicoesDetalhesA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #007aff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
  cursor: pointer;
`;
