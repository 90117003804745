import styled from "@emotion/styled";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

export const ModalH2 = styled("h2")`
  color: #0d0d0d;
  font-weight: 800;
  font-size: 24px;
`;

export const ModalContentA = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const ModalContentB = styled("div")`
  max-width: 900px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const ModalA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  text-decoration-line: underline;

  color: #717171;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #00da8c;
  }
`;

export const ModalP = styled("span")`
  font-weight: 400;
  font-size: 16px;
  line-height: 214%;
  display: flex;
  align-items: center;
  color: #6b6c7e;
`;
export const ModalB = styled("b")`
  font-weight: 700;
  font-size: 16px;
  line-height: 214%;
  /* display: flex; */
  align-items: center;
  color: #6b6c7e;
`;

export const ModalContentC = styled("div")`
  max-width: 900px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
`;

export const ModalButtonDiv = styled("div")`
  background: #ffbb00;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0d0d0d;
  width: 160px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #b68500;
    cursor: pointer;
  }
`;
