import React from "react";
import { useNavigate } from "react-router-dom";
import { CenteredPageDiv } from "../../../styles/global.styles";
import {
  ButtonYellowHistorical,
  MedicalHistoryMaisA,
  MeasurementsRectangleDataDiv,
  MeasurementsRectangleDetailsDiv,
  ValueRectangleMeasurements,
  TextAndMeasurements,
  TextH2,
  TextP,
  TextPCardiaca,
} from "./styles";

interface MedicoesListProps {
  measurements: {
    data: string;
    value: string;
    idDetalhes: string;
  }[];
  verMais: () => void;
  offset: number;
}

const HistoricalMeasurementComponent: React.FC<MedicoesListProps> = ({
  measurements,
  verMais,
  offset,
}) => {
  const navigate = useNavigate();
  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };
  return (
    <CenteredPageDiv>
      <TextH2>Histórico</TextH2>
      <br />
      {measurements.map((medicao) => {
        return (
          <MeasurementsRectangleDetailsDiv key={medicao.idDetalhes}>
            <MeasurementsRectangleDataDiv>
              <TextP>{medicao.data}</TextP>
              <TextP>
                <ValueRectangleMeasurements>{medicao.value}</ValueRectangleMeasurements>
                {" bpm"}
              </TextP>
              <ButtonYellowHistorical
                onClick={() => Rota(`/medicoes/detalhes/${medicao.idDetalhes}`)}
              >
                Detalhes
              </ButtonYellowHistorical>
            </MeasurementsRectangleDataDiv>
            <TextPCardiaca>(frequência cardíaca)</TextPCardiaca>
          </MeasurementsRectangleDetailsDiv>
        );
      })}
      <TextAndMeasurements>
        {measurements.length >= offset && (
          <MedicalHistoryMaisA onClick={verMais}>
            Ver mais
          </MedicalHistoryMaisA>
        )}
      </TextAndMeasurements>
    </CenteredPageDiv>
  );
};

export default HistoricalMeasurementComponent;
