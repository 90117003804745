import React from "react";
import { porcentagem } from "../../../utils/conversions";
import {
  MedicoesP,
  MeasurementsProgressBar,
  DivRectangleMeasurements,
} from "./styles";

interface MeasurementsConsumptionComponentProps {
  total: number;
  restante: number;
}

const MeasurementsConsumptionComponent: React.FC<
  MeasurementsConsumptionComponentProps
> = ({ total, restante }) => {
  return (
    <DivRectangleMeasurements>
      <MedicoesP>{`Você tem ${
        total - restante < 0 ? 0 : total - restante
      } medições restantes de ${total} por mês`}</MedicoesP>
      <MeasurementsProgressBar porcentagem={porcentagem(restante, total)} />
    </DivRectangleMeasurements>
  );
};

export default MeasurementsConsumptionComponent;
