import { styled } from "@mui/material/styles";

export const BoxDiv = styled("div")`
  border: 0.5px dashed #ffffff;
  width: 100%;
  margin-bottom: 10px;
`;

export const DivRectangleMeasurements = styled("div")`
  padding: 8px;

  background: #6257b8;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
`;

export const MeasurementsPX = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
`;

export const MeasurementsPN = styled("p")`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
`;

export const MeasurementsPP = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

export const MeasurementsPBlack = styled("span")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #717171;
`;

export const MeasurementsPLink = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #007aff;
`;

type DivMeasurementsProps = {
  porcentagem: number;
};

export const MeasurementsProgressBar = styled("div")<DivMeasurementsProps>`
  --progress: ${(props) => props.porcentagem};
  --colorProgress: ${(props) => 100 - props.porcentagem};
  height: 6px;
  margin-top: 4px;
  display: flex;
  background: #e1e1e1;
  border-radius: 4px;

  ::before {
    content: "";
    width: calc(var(--progress) * 1%);
    background-color: hsl(calc(var(--colorProgress) * 1.2), 80%, 50%);
    transition: all 0.2s ease;
  }
`;

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const HrDashedLine = styled("div")`
  border: 0.5px dashed #ffffff;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;
