import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { IUserLogado } from "../../Services/Interfaces";

export const checkChaves = () => {
  const token =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== "" &&
    localStorage.getItem("token") !== null
      ? true
      : false;

  const tokenValido = () => {
    if (token) {
      const decoded = jwtDecode(localStorage.getItem("token") as string) as any;
      // verificar token expirado
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const user =
    localStorage.getItem("user") !== undefined &&
    localStorage.getItem("user") !== "" &&
    localStorage.getItem("user") !== null
      ? true
      : false;

  const HealthPlaceID =
    localStorage.getItem("HealthPlaceID") !== undefined &&
    localStorage.getItem("HealthPlaceID") !== "" &&
    localStorage.getItem("HealthPlaceID") !== null
      ? true
      : false;
  const HealthPlaceDados =
    localStorage.getItem("HealthPlaceDados") !== undefined &&
    localStorage.getItem("HealthPlaceDados") !== "" &&
    localStorage.getItem("HealthPlaceDados") !== null
      ? true
      : false;

  return token && user && HealthPlaceID && HealthPlaceDados && tokenValido();
};

export const checkPerfilAdmin = () => {
  const user =
    localStorage.getItem("user") !== undefined &&
    localStorage.getItem("user") !== "" &&
    localStorage.getItem("user") !== null
      ? true
      : false;

  if (user) {
    const userJSON = localStorage.getItem("user");
    const { roles } = JSON.parse(userJSON as string) as IUserLogado;
    if (roles.includes("ROLE_SUPER_ADMIN")) {
      return true;
    } else if (roles.includes("ROLE_ADMIN")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const PrivateRoutes = () => {
  if (!checkChaves()) {
    Logout();
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export const AdminRoutes = () => {
  if (checkChaves() && checkPerfilAdmin()) {
    return <Outlet />;
  } else if (checkChaves()) {
    return <Navigate to="/home" />;
  } else {
    Logout();
    return <Navigate to="/login" />;
  }
};

export const TokenRoutes = () => {
  if (checkChaves()) {
    return <Navigate to="/home" />;
  } else {
    Logout();
    return <Outlet />;
  }
};

export const Logout = (cancelMessage?: boolean) => {
  localStorage.clear();
  if (!cancelMessage) toast.info("Sessão encerrada com sucesso!");
  setTimeout(() => {
    if (window.location.pathname !== "/login") {
      return (window.location.href = "/login");
    } else {
      return;
    }
  }, 6000);
};

export const DominioURL = () => {
  return window.location.hostname === "localhost"
    ? "sinaisvitais.prosperasaude.cm2dev.com.br"
    : window.location.hostname;
};
