import React, { useEffect, useState } from "react";

import {
  AllTableList,
  DownloadButtonDiv,
  HeaderComponent,
  IconFiEdit,
  IconSpace,
  NewCadastroButtonDiv,
  TextH2,
} from "../../../../styles/global.styles";
import { ICompany } from "../../../../Services/Interfaces";
import { DetalhesIconDiv, EmpresasDiv } from "./styles";
import { FiDownload, FiPlus } from "react-icons/fi";
import PaginationMUI, {
  PaginationProps,
} from "../../../../Components/Pagination";
import ModalComponentEmpresas from "../../../../Components/Modal/Empresas";
import { useNavigate } from "react-router-dom";
import { cnpjMask } from "../../../../utils/conversions";
import {
  CustomerFormControl,
  CustomerInputLabel,
  CustomerOutlinedInput,
} from "../../../../Components/CustomerEdit/styles";
import { BuscarIconDiv } from "../../Customers/styles";
import { exportEmpresas } from "../../../../Services/Admin";
import { AxiosResponse } from "axios";
import {
  CompanyFormControl,
  CompanyInputLabel,
  CompanyMenuItem,
  CompanySelect,
} from "../Edit/styles";

interface PageEmpresasProps {
  companies: ICompany[];
  pagination: PaginationProps;
  setLikeSearch: React.Dispatch<React.SetStateAction<string>>;
  setHealthPlaceType: React.Dispatch<React.SetStateAction<string>>;
}

const PageEmpresasAdminConteudo: React.FC<PageEmpresasProps> = ({
  companies,
  pagination,
  setLikeSearch,
  setHealthPlaceType,
}) => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [company, setCompany] = useState<ICompany>();
  const [search, setSearch] = useState<string>("");
  const [tipo, setTipo] = useState<{ id: string; name: string }>({
    id: "Todos",
    name: "Todos",
  });
  const locationHref = async (company: ICompany) => {
    setIsOpen(!modalIsOpen);
    setCompany(company);
  };
  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const downloadExmpresa = async () => {
    await exportEmpresas().then((response) => {
      const dow = response as AxiosResponse<Blob>;
      if (dow.status === 200) {
        const downloadUrl = window.URL.createObjectURL(dow.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `exportEmpresas-${new Date().getTime()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  useEffect(() => {
    tipo.id === "Todos" ? setHealthPlaceType("") : setHealthPlaceType(tipo.id);
  }, [setHealthPlaceType, tipo]);

  return (
    <EmpresasDiv>
      <HeaderComponent>
        <div style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
          }}>
          <TextH2>Gestão Empresas</TextH2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DownloadButtonDiv onClick={downloadExmpresa}>
            <FiDownload
              style={{
                marginRight: "5px",
              }}
            />
            Exportar
          </DownloadButtonDiv>
          <NewCadastroButtonDiv
            onClick={() => {
              Rota("/admin/gestao/empresas/new");
            }}
          >
            <FiPlus
              style={{
                marginRight: "5px",
              }}
            />
            Novo Cadastro
          </NewCadastroButtonDiv>
        </div>
      </HeaderComponent>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomerFormControl variant="outlined">
          <CustomerInputLabel htmlFor="profileFullName">
            Texto da busca
          </CustomerInputLabel>
          <CustomerOutlinedInput
            value={search}
            onChange={(e) => setSearch(e.target.value.replace(/[./-]/g, ''))}
            id="profileFullName"
            type="text"
            label="Texto da busca"
            onKeyDown={(e) => {
              if (e.key === "Enter") setLikeSearch(search);
            }}
          />
        </CustomerFormControl>
        <CompanyFormControl percentage={"10%"} variant="outlined">
          <CompanyInputLabel htmlFor="tipo">TIPO</CompanyInputLabel>
          <CompanySelect
            value={tipo.id}
            onChange={(e) => {
              setTipo({
                id: e.target.value as string,
                name: e.target.value as string,
              });
            }}
            id="tipo"
            label="TIPO"
          >
            <CompanyMenuItem key={"Todos"} value={"Todos"}>
              Todos
            </CompanyMenuItem>
            <CompanyMenuItem key={"Agregadora"} value={"Agregadora"}>
              Agregadora
            </CompanyMenuItem>
            <CompanyMenuItem key={"Cliente"} value={"Cliente"}>
              Cliente
            </CompanyMenuItem>
          </CompanySelect>
        </CompanyFormControl>
        <BuscarIconDiv
          onClick={() => {
            setLikeSearch(search);
          }}
        >
          Buscar
        </BuscarIconDiv>
      </div>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>Empresa</th>
              <th>CNPJ</th>
              <th>Inscrição Estadual</th>
              <th>Integrador</th>
              <th>TIPO</th>
              <th>Cidade</th>
              <th>Detalhes</th>
              <th></th>
            </tr>
            {companies?.map((company) => {
              return (
                <tr key={company.id}>
                  <td>{company.name}</td>
                  <td>{cnpjMask(company.cnpj)}</td>
                  <td>{company.stateRegistration}</td>
                  <td>{company.integrationUser?.id? "Ativo" : "Inativo"}</td>
                  <td>{company.healthPlace?.id ? "Cliente" : "Agregadora"}</td>
                  <td>
                    {company.address
                      ? `${company.address.city} - ${company.address.uf.name}`
                      : ""}
                  </td>
                  <td>
                    <DetalhesIconDiv
                      onClick={() => {
                        locationHref(company);
                      }}
                    >
                      Detalhes
                    </DetalhesIconDiv>
                  </td>
                  <td>
                    <IconSpace>
                      <IconFiEdit
                        onClick={() => {
                          Rota(`/admin/gestao/empresas/${company.id}`);
                        }}
                        size={20}
                      />
                    </IconSpace>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AllTableList>
      <PaginationMUI
        setLimit={pagination.setLimit}
        setOffset={pagination.setOffset}
        setRowsPerPage={pagination.setRowsPerPage}
        rowsPerPage={pagination.rowsPerPage}
        total={pagination.total}
        setPage={pagination.setPage}
        page={pagination.page}
      />
      {company && (
        <ModalComponentEmpresas
          empresa={company}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
      )}
    </EmpresasDiv>
  );
};

export default PageEmpresasAdminConteudo;
