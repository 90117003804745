import { styled } from "@mui/material/styles";

export const CustomerDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DetalhesIconDiv = styled("div")`
  color: #ffbb00;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5px 4px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ffbb00;
  border-radius: 2px;
  cursor: pointer;
`;

export const DownloadIconDiv = styled("div")`
  color: #ffbb00;
  width: 120px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5px 4px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ffbb00;
  border-radius: 2px;
  cursor: pointer;
`;

export const BuscarIconDiv = styled("div")`
  color: #000000;
  width: 80px;
  height: 60px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background: #b68500;
    border: 1px solid #b68500;
  }
`;

