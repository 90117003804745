import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { PaginationContainerDiv } from "./styles";

export interface PaginationProps {
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const PaginationMUI: React.FC<PaginationProps> = ({
  setLimit,
  setOffset,
  setRowsPerPage,
  rowsPerPage,
  total,
  setPage,
  page,
}) => {
  const handleChangePage = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.ChangeEvent<unknown>
      | null,
    newPage: number
  ) => {
    if (newPage > page) {
      setOffset(rowsPerPage * newPage);
    } else if (newPage < page) {
      setOffset(rowsPerPage / newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  return (
    <PaginationContainerDiv>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Stack>
        <Pagination
          onChange={(e, page) => handleChangePage(null, page - 1)}
          count={Math.ceil(total / rowsPerPage)}
          page={page + 1}
          shape="rounded"
        />
      </Stack>
    </PaginationContainerDiv>
  );
};

export default PaginationMUI;
