import styled from "@emotion/styled";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000, // Adicionado para garantir que o modal fique acima de outros componentes
  },
};

export const ModalH2 = styled("h2")`
  padding-bottom: 10px;
  color: #0d0d0d;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
`;

export const ModalContentA = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

type CompanyFormControlProps = {
  percentage?: string;
};

export const CompanyFormControl = styled(FormControl)<CompanyFormControlProps>`
  width: ${(props) => (props.percentage ? props.percentage : "100%")};
  padding-right: 10px;
`;

export const CompanyInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
  cursor: pointer;
`;

export const CompanySelect = styled(Select)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const CompanyDate = styled(TextField)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const CompanyTexto = styled(TextField)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const CompanyMenuItem = styled(MenuItem)`
  background-color: #ffffff;
`;

export const CompanyContentButton = styled("div")`
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-right: 10px;
`;

export const CompanyButton = styled("button")`
  border: 8px solid #ffbb00;
  margin-top: 15px;
  width: 100%;
  background: #ffbb00;
  border-top: 15px solid #ffbb00;
  border-bottom: 15px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    border: 8px solid #ffbb00;
    background-color: #ffbb00;
    border-top: 15px solid #ffbb00;
    border-bottom: 15px solid #ffbb00;
    cursor: not-allowed;
  }
  &:hover {
    border: 8px solid #b68500;
    background-color: #b68500;
    border-top: 15px solid #b68500;
    border-bottom: 15px solid #b68500;
  }
`;
