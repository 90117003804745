import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  customStyles,
  ModalA,
  ModalButton,
  ModalClose,
  ModalContentA,
  ModalContentButton,
  ModalFormControl,
  ModalFormControlLabel,
  ModalFormLabel,
  ModalFormularioDiv,
  ModalH2,
  ModalInputLabel,
  ModalOutlinedInput,
  ModalRadio,
  ModalRadioGroup,
} from "./styles";
import Modal from "react-modal";
import { linkDeepaffex } from "../../../Services/Auth";
import { IHealthPlace } from "../../../Services/Interfaces";
import { FormHelperText } from "@mui/material";

interface ModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
}

const ModalComponentMedicao: React.FC<ModalProps> = ({
  setIsOpen,
  modalIsOpen,
}) => {
  Modal.setAppElement("#root");
  const [customer, setCustomer] = useState("");
  const [service, setService] = useState("");
  const [age, setAge] = useState<number>(0);
  const [height, setHeight] = useState(0);
  const [showErrorHeight, setShowErrorHeight] = useState(false);
  const [weight, setWeight] = useState<number>(0);
  const [showErrorWeight, setShowErrorWeight] = useState(false);

  const [gender, setGender] = useState("");
  const [smoking, setSmoking] = useState("");
  const [bloodpressuremedication, setBloodpressuremedication] = useState("");
  const [diabetes, setDiabetes] = useState("");

  useEffect(() => {
    const HealthPlaceJSON = localStorage.getItem("HealthPlaceDados");
    if (HealthPlaceJSON) {
      const HealthPlaceDados = JSON.parse(HealthPlaceJSON) as IHealthPlace;
      setCustomer(HealthPlaceDados.customer.deepAffexCallbackId);
      setService(HealthPlaceDados.service.deepAffexCallbackId);
    }
  }, []);

  function closeModal() {
    setCustomer("");
    setService("");
    setAge(0);
    setHeight(0);
    setWeight(0);
    setGender("");
    setSmoking("");
    setBloodpressuremedication("");
    setDiabetes("");
    setIsOpen(false);
  }

  const envioDadosMedicao = async (e: FormEvent) => {
    e.preventDefault();
    const path = await linkDeepaffex(
      JSON.stringify({
        identifier: `${customer}--${service}`,
        age,
        height,
        weight,
        gender,
        smoking,
        bloodpressuremedication,
        diabetes,
      })
    );
    if (path) {
      window.location.href = path.toString();
    }
  };

  function handleAgeChange(event: ChangeEvent<HTMLInputElement>) {
    const newAge = parseInt(event.target.value || "0");
    if (newAge <= 115) {
      setAge(newAge);
    }
  }

  function handleHeightChange(event: ChangeEvent<HTMLInputElement>) {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    if (rawValue === "") {
      setHeight(0);
      setShowErrorHeight(false);
    } else {
      const heightInt = parseInt(rawValue);
      if (heightInt === height) {
        const lastChar = parseInt(heightInt.toString().slice(0, -1));
        setHeight(lastChar > 0 ? lastChar : 0);
      } else if (heightInt > 251) {
        setShowErrorHeight(true);
      } else {
        setHeight(heightInt);
        setShowErrorHeight(false);
      }
    }
  }

  function handleWeightChange(event: ChangeEvent<HTMLInputElement>) {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    if (rawValue === "") {
      setWeight(0);
      setShowErrorWeight(false);
    } else {
      const weightInt = parseInt(rawValue);
      if (weightInt === weight) {
        const lastChar = parseInt(weightInt.toString().slice(0, -1));
        setWeight(lastChar > 0 ? lastChar : 0);
      } else if (weightInt > 250) {
        setShowErrorWeight(true);
      } else {
        setWeight(weightInt);
        setShowErrorWeight(false);
      }
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Dados pre medição"
    >
      <ModalContentA>
        <ModalA onClick={closeModal}>
          <ModalClose />
        </ModalA>
      </ModalContentA>
      <ModalH2>Dados pre medição</ModalH2>
      <ModalFormularioDiv onSubmit={envioDadosMedicao}>
        <ModalFormControl variant="outlined">
          <ModalInputLabel htmlFor="age">Idade</ModalInputLabel>
          <ModalOutlinedInput
            id="age"
            type="number"
            label="Idade"
            required
            value={age === 0 ? "" : age}
            onChange={handleAgeChange}
          />
        </ModalFormControl>
        <ModalFormControl variant="outlined" error={showErrorHeight}>
          <ModalInputLabel htmlFor="height">Altura</ModalInputLabel>
          <ModalOutlinedInput
            id="height"
            type="string"
            label="Altura"
            required
            value={height === 0 ? "" : `${height} cm`}
            onChange={handleHeightChange}
          />
          {showErrorHeight && (
            <FormHelperText>Valor não pode ser maior que 251 cm</FormHelperText>
          )}
        </ModalFormControl>
        <ModalFormControl variant="outlined" error={showErrorWeight}>
          <ModalInputLabel htmlFor="weight">Peso</ModalInputLabel>
          <ModalOutlinedInput
            id="weight"
            type="string"
            label="Peso"
            required
            value={weight === 0 ? "" : `${weight} kg`}
            onChange={handleWeightChange}
          />
          {showErrorWeight && (
            <FormHelperText>Valor não pode ser maior que 250 Kg</FormHelperText>
          )}
        </ModalFormControl>
        <ModalFormControl>
          <ModalFormLabel id="gender" htmlFor="gender">
            Gênero
          </ModalFormLabel>
          <ModalRadioGroup
            row
            onChange={(e) => {
              setGender(e.target.value);
            }}
          >
            <ModalFormControlLabel
              value="male"
              control={<ModalRadio required />}
              label="Masculino"
            />
            <ModalFormControlLabel
              value="female"
              control={<ModalRadio required />}
              label="Feminino"
            />
          </ModalRadioGroup>
        </ModalFormControl>
        <ModalFormControl>
          <ModalFormLabel id="smoking" htmlFor="smoking">
            Fumante?
          </ModalFormLabel>
          <ModalRadioGroup
            row
            onChange={(e) => {
              setSmoking(e.target.value);
            }}
          >
            <ModalFormControlLabel
              value="1"
              control={<ModalRadio required />}
              label="Sim"
            />
            <ModalFormControlLabel
              value="0"
              control={<ModalRadio required />}
              label="Não"
            />
          </ModalRadioGroup>
        </ModalFormControl>
        <ModalFormControl>
          <ModalFormLabel
            id="bloodpressuremedication"
            htmlFor="bloodpressuremedication"
          >
            Utiliza medicamento para pressão?
          </ModalFormLabel>
          <ModalRadioGroup
            row
            onChange={(e) => {
              setBloodpressuremedication(e.target.value);
            }}
          >
            <ModalFormControlLabel
              value="1"
              control={<ModalRadio required />}
              label="Sim"
            />
            <ModalFormControlLabel
              value="0"
              control={<ModalRadio required />}
              label="Não"
            />
          </ModalRadioGroup>
        </ModalFormControl>
        <ModalFormControl>
          <ModalFormLabel id="diabetes" htmlFor="diabetes">
            Diabetes?
          </ModalFormLabel>
          <ModalRadioGroup
            row
            onChange={(e) => {
              setDiabetes(e.target.value);
            }}
          >
            <ModalFormControlLabel
              value="type1"
              control={<ModalRadio required />}
              label="Tipo 1"
            />
            <ModalFormControlLabel
              value="type2"
              control={<ModalRadio required />}
              label="Tipo 2"
            />
            <ModalFormControlLabel
              value="0"
              control={<ModalRadio required />}
              label="Não"
            />
          </ModalRadioGroup>
        </ModalFormControl>
        <ModalContentButton>
          <ModalButton title="Medir">Medir</ModalButton>
        </ModalContentButton>
      </ModalFormularioDiv>
    </Modal>
  );
};

export default ModalComponentMedicao;
