import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const AccountFormControl = styled(FormControl)`
  width: 100%;
`;

type AccountFormControlProps = {
  percentage?: string;
};

export const AccountColorDiv = styled("div")<AccountFormControlProps>`
  width: ${(props) => (props.percentage ? props.percentage : "100%")};
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  margin-right: 10px;
`;

export const AccountOutlinedInputFile = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
  display: none;
`;

export const AccountAvatarContainer = styled("img")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  cursor: pointer;
`;

export const AccountInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
`;

export const AccountOutlinedInput = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
`;

export const AccountContentA = styled("div")`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const AccountA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  text-decoration-line: underline;

  color: #717171;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #00da8c;
  }
`;

export const AccountForm = styled("form")`
  align-items: center;
  padding: 37px;

  @media (max-width: 700px) {
    padding: 37px 20px;
  }
`;

export const AccountContentButton = styled("div")`
  display: flex;
  justify-content: center;
  /* padding-top: 20px; */
`;

export const AccountButton = styled("button")`
  border: none;
  margin-top: 30px;
  padding: 15px 60px;
  gap: 15px;
  background: #ffbb00;
  border: 1px solid #ffbb00;
  border-radius: 6px;
  background-color: #ffbb00;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;

  &:hover {
    background-color: #b68500;
  }
`;

export const AccountInputAdornment = styled(InputAdornment)``;

export const AccountIconButton = styled(IconButton)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #717171;
`;
