import { styled } from "@mui/material/styles";

export const BoxDiv = styled("div")`
  border: 0.5px dashed #ffffff;
  width: 100%;
  margin-bottom: 10px;
  text-align: justify;
`;

export const IFrame = styled("iframe")<{ altura: number }>`
  width: 100%;
  height: ${props => props.altura ? `${props.altura}px` : '600px'};
`;

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
`;

export const TextP = styled("div")`
  text-indent: 8px;
  margin: 20px;
`;

export const TextBlockquote = styled("div")`
  text-indent: 24px;
  margin: 20px;
`;
export const TextBlockquoteSub = styled("div")`
  text-indent: 40px;
  margin: 20px;
`;

export const PolicyAccept = styled("button")`
  border: none;
  margin-top: 15px;
  height: 100%;
  width: 30%;
  border: 10px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  border-top: 15px solid #ffffff;
  border-bottom: 15px solid #ffffff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    cursor: not-allowed;
    background-color: #30ff6e;
    border-top: 15px solid #30ff6e;
    border-bottom: 15px solid #30ff6e;
    border: 15px solid #30ff6e;
  }
  &:hover {
    background-color: #30ff6e;
    border-top: 15px solid #30ff6e;
    border-bottom: 15px solid #30ff6e;
    border: 15px solid #30ff6e;
  }
`;

export const PolicyAcceptDiv = styled("div")`
  display: flex;
  justify-content: flex-end;
`;
