import { toast } from "react-toastify";
import { DominioURL, Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import {
  IErroRest,
  IOkRest,
  ITokenDeepaffex,
  IHealthPlace,
  IHealthPlaceURL,
} from "./Interfaces";

export const GetHealthPlace = async (dominio: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        api
          .get(`/health_place/access_url/${dominio}`, { headers })
          .then((response: { data: { data: IHealthPlaceURL } }) => {
            localStorage.setItem("HealthPlaceID", response.data.data.id);
            localStorage.setItem(
              "HealthPlaceProfile",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem("HealthPlaceLogo", response.data.data.logo);
            return resolve(response.data.data.id);
          })
          .catch((error) => {
            if (error.response.data.code === 401) {
              Logout(true);
            }
            return reject(error);
          });
      }),
      {
        error: "Erro na consulta HealthPlace",
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const UserHealthPlace = async (healthPlaceId?: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/user/health_place/${
              healthPlaceId
                ? healthPlaceId
                : localStorage.getItem("HealthPlaceID")
            }/customer_service?attributes[]=limitPerMonth&attributes[]=usedCurrentMonth&attributes[customer][]=deepAffexCallbackId&attributes[service][]=deepAffexCallbackId&offset=0&limit=10`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: { data: { data: IHealthPlace[] } }) => {
            localStorage.setItem(
              "HealthPlaceDados",
              JSON.stringify(response.data.data[0])
            );
            return resolve(response);
          })
          .catch((error) => {
            if (error.response.data.code === 401) {
              Logout(true);
            }
            return reject(error);
          });
      }),
      {
        error: "Erro na consulta healthPlaceId",
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const loginCheck = async (
  username: string,
  password: string,
  dominio: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/login_check", {
            username: username,
            password: password,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            return resolve(
              new Promise(async (resolve, reject) => {
                await dadosUser(response.data.token, dominio)
                  .then(() => {
                    return resolve(true);
                  })
                  .catch(() => {
                    return reject(false);
                  });
              })
            );
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Efetuando Login",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            Logout();
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const dadosUser = async (token?: string, dominio?: string) => {
  const userToken = localStorage.getItem("token");
  const URL = DominioURL();
  try {
    return new Promise((resolve, reject) => {
      api
        .get(`/user`, {
          headers: {
            Authorization: `Bearer ${token ? token : userToken}`,
          },
        })
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          resolve(
            new Promise((resolve, reject) => {
              GetHealthPlace(dominio ? dominio : URL)
                .then(() => {
                  resolve(
                    new Promise((resolve, reject) => {
                      const healthPlaceId =
                        localStorage.getItem("HealthPlaceID");
                      if (healthPlaceId) {
                        UserHealthPlace(healthPlaceId)
                          .then(() => {
                            resolve(true);
                          })
                          .catch(() => {
                            reject(false);
                          });
                      }
                    })
                  );
                })
                .catch(() => {
                  reject(false);
                });
            })
          );
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          reject(false);
        });
    });
  } catch (erro) {
    return false;
  }
};

export const UserRememberPassword = async (username: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/remember_password", {
            email: username,
            healthPlace: DominioURL(),
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        success: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IOkRest;
            return data.data.message;
          },
        },
        pending: "Enviando email 📧",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    return false;
  }
};

export const checkCode = async (code: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/remember_password/${code}`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const rememberPassword = async (
  code: string,
  password: string,
  repeatPassword: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(`/remember_password/${code}`, {
            password,
            repeatPassword,
            healthPlace: DominioURL(),
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        success: "Senha alterada com sucesso!",
        pending: "Alterando senha",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const tokenDeepaffex = async () => {
  return new Promise((resolve, reject) => {
    api
      .post(
        "/organizations/licenses",
        {
          name: "GENERIC",
          identifier: "GENERIC",
          tokenExpiresIn: 10,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response: IOkRest) => {
        return resolve(response.data.data as unknown as ITokenDeepaffex);
      })
      .catch((error: IErroRest) => {
        if (error.response.data.code === 401) {
          Logout(true);
        }
        return reject(error);
      });
  });
};

export const linkDeepaffex = async (perfil: string) => {
  return toast.promise(
    new Promise((resolve, reject) => {
      api
        .get(
          `/deepaffex/measurement/url?profile=${perfil}&healthPlace=${DominioURL()}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response: IOkRest) => {
          return resolve(response.data.data as unknown as string);
        })
        .catch((error: IErroRest) => {
          if (error.response.data.code === 401) {
            Logout(true);
          }
          return reject(error);
        });
    }),
    {
      success: "Link de consulta gerado",
      pending: "Gerando Link",
      error: {
        render(ToastContentProps) {
          const data = ToastContentProps.data as unknown as IErroRest;
          return data.response.data.message;
        },
      },
    }
  );
};

export const userChangePassword = async (
  currentPassword: string,
  newPassword: string,
  repeatNewPassword: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/user/change_password`,
            {
              currentPassword,
              newPassword,
              repeatNewPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            if (error.response.data.code === 401) {
              Logout(true);
            }
            return reject(error);
          });
      }),
      {
        success: "Senha alterada com sucesso!",
        pending: "Alterando senha",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};
